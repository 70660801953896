import React from 'react'
import './styles-menu.css'

export function MenuTemplate({ step, templateId }) {
  return (
    <aside className="CompaniesMenuModal__Container">
      <header>
        <span className="CompaniesMenuModal__Title">
          {templateId !== '' ? 'Edição' : 'Cadastro'} de Modelo
        </span>
        <span className="CompaniesMenuModal__Subtitle">
          Siga o passo a passo para{' '}
          {templateId !== '' ? 'editar um' : 'cadastrar um novo'} Modelo de
          mensagem.
        </span>
      </header>

      <div className="CompaniesMenuModal__TimeLine">
        <ul>
          <li
            className={
              step === 1
                ? `CompaniesMenuModal__TimeLine--selected`
                : 'CompaniesMenuModal__TimeLine--passed'
            }
          >
            Tipo de Modelo
          </li>
          <li
            className={
              step === 2 ? `CompaniesMenuModal__TimeLine--selected` : ''
            }
          >
            Conteúdo do Modelo
          </li>
        </ul>
      </div>
    </aside>
  )
}
