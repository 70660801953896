import React from 'react'
import Endor from '../../pages/Home/HomeRoutes/Oportunidades/Data Table/assets/Endor'
import { FaGoogleDrive } from 'react-icons/fa'
import './styles.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import { BiSolidMessageDetail } from 'react-icons/bi'
import { IoLogoWhatsapp } from 'react-icons/io'
import { MdEmail } from 'react-icons/md'

function CardSelectTemplate({ cardContent, tipoId, template, setTemplate }) {
  const { icon = '', title = '', content = '' } = cardContent
  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  function feedback(msg, type) {
    if (type === 'success') {
      toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else if (type === 'error') {
      toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else if (type === 'warn') {
      toast.warn(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      toast.info('Internal server error, X-121_06', {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  function showIcon() {
    switch (icon) {
      case 'sms':
        return <BiSolidMessageDetail size={45} />
      case 'email':
        return <MdEmail size={45} />
      case 'whatsapp':
        return <IoLogoWhatsapp size={45} />
    }
  }

  async function selectCategory() {
    setTemplate((prev) => ({
      ...prev,
      TIPO_NAME: title,
      TIPO_ID: tipoId,
      MENSAGEM_TITULO: '',
      MENSAGEM_DESCRICAO: '',
      FORMATACAO: '',
      LOGO_URL: '',
    }))
  }

  return (
    <div
      className={`CardSelect ${
        template.TIPO_NAME === title ? 'CardSelect__Selected' : ''
      }`}
      onClick={selectCategory}
    >
      <ToastContainer />
      <div className="CardSelect__Container">
        <header>
          {showIcon()}
          <div className="CardSelect__HeaderText">
            <span className="CardSelect__Title">{title}</span>
          </div>
        </header>
        <div className="CardSelect__Divider" />
        <span className="CardSelect__Content">{content}</span>
      </div>
      <div className="CardSelect__Checkbox">
        <input
          type="radio"
          checked={template.TIPO_NAME === title}
          onChange={selectCategory}
        />
      </div>
    </div>
  )
}

export default CardSelectTemplate
