import { apiGeral, apiPDF } from '../api'
import { ENDPOINTS } from '../endPoints'

export const pdfRepository = {
  renderizarCotacao: async (DADOS) => {
    try {
      const response = await apiPDF.post(ENDPOINTS.PDF.RENDER_COTACAO, DADOS, {
        responseType: 'blob',
      })

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}
