import {
  Avatar,
  BackgroundModal,
  CloseButton,
  Container,
  ContentModal,
  FormCol,
  FormField,
  FormRow,
  FormStep,
  Header,
  Label,
  Subtitle,
  Title,
  ListboxWrapper,
} from './styles'
import ImgClose from '../../../../Home/assets/icon-close.svg'
import ImgIconUser from '../../../../Home/assets/icon-usr-register-modal.svg'
import { Listbox } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { vendedorRepository } from '../../../../../api/repositories/vendedorRepository'
import { userRepository } from '../../../../../api/repositories/userRepository'
import { Alerts } from '../../../../../utils/alert'
import { ErrorSpan } from '../ModalUser/styles'

const PERFIL_ACESSO = [
  { perfilId: 0, value: 'Vendedor' },
  { perfilId: 1, value: 'Admin' },
]

const INITIAL_VALUES = {
  nomeUser: '',
  loginUser: '',
  senhaUser: '',
  perId: null,
  usrMudarSenha: false,
  venId: null,
}

export function EditarUsuario({
  setOpenModal,
  openModal,
  userData,
  setAlteracaoFeita,
  setLoading,
}) {
  const [dataUser, setDataUser] = useState({
    nomeUser: '',
    loginUser: '',
    senhaUser: '',
    venEmail: '',
    perId: null,
    venId: null,
  })

  const [selectedPerfil, setSelectedPerfil] = useState('')
  const [selectedVen, setSelectedVen] = useState('')
  const [ven, setVen] = useState([])

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: userData ? dataUser : null,
  })

  useEffect(() => {
    if (userData) {
      setLoading(true)
      buscarUsuarioWithId(userData.USR_ID)
    }
  }, [userData])

  useEffect(() => {
    if (userData) {
      reset(dataUser)
    } else {
      reset(INITIAL_VALUES)
    }
  }, [dataUser, reset])

  useEffect(() => {
    if (selectedPerfil !== 'Vendedor') {
      setValue('venId', null)
    }
  }, [selectedPerfil, setValue])

  async function onSubmit(dataSubmiting) {
    const validantionData = {
      USR_ID: userData.USR_ID,
      USR_NOME: dataSubmiting.nomeUser,
      USR_LOGIN: dataSubmiting.loginUser,
      USR_SENHA: dataSubmiting.senhaUser,
      PER_ID: dataSubmiting.perId,
      ALTERAR_SENHA: dataSubmiting.senhaUser ? true : false,
      VEN_ID: dataSubmiting.venId?.toString(),
    }

    await atualizarUsuario(validantionData)

    setOpenModal(false)
    setAlteracaoFeita(true)
  }

  function handleSelectPerfil(perfil) {
    setSelectedPerfil(perfil.value)
    setValue('perId', perfil.perfilId)
  }

  function handleSelectVen(ven) {
    setSelectedVen(ven.label)
    setValue('venId', ven.value)
  }

  async function buscarUsuarioWithId(USR_ID) {
    try {
      const data = await userRepository.buscarUsuarioId(USR_ID)

      setDataUser((state) => {
        return {
          ...state,
          nomeUser: data.USR_NOME,
          loginUser: data.USR_LOGIN,
          senhaUser: data.USR_SENHA,
          venEmail: data.VEN_EMAIL,
          perId: data.PER_ID,
          usrMudarSenha: data.USR_MUDAR_SENHA,
          venId: data.VEN_ID,
        }
      })

      if (data.PER_ID !== null) {
        const perfilSelecionado = PERFIL_ACESSO.find(
          (perfil) => perfil.perfilId === data.PER_ID,
        )
        if (perfilSelecionado) {
          setSelectedPerfil(perfilSelecionado.value)
          setValue('perId', perfilSelecionado.perfilId)
        }
      } else {
        setValue('perId', null)
      }

      if (data.VEN_ID) {
        await buscarVendedores(data.VEN_ID)
      }

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  async function buscarVendedores(venId) {
    try {
      const data = await vendedorRepository.buscarVendedor()

      const dataSelect = data.map((el) => {
        return {
          value: el.id,
          label: el.nome,
        }
      })

      setVen(dataSelect)
      if (venId !== null) {
        const vendedorSelecionado = dataSelect.find((vendedor) => {
          return vendedor.value === venId
        })

        if (vendedorSelecionado) {
          setSelectedVen(vendedorSelecionado.label)
          setValue('venId', vendedorSelecionado.value)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function atualizarUsuario(DADOS) {
    try {
      const data = await userRepository.updateUsuarioV2(DADOS)

      if (data.error) {
        Alerts.ERROR(data.message || 'Erro inesperado ao editar usuário!')
        return
      }

      Alerts.SUCCESS('Usuário editado com sucesso!')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <BackgroundModal open={openModal} onClose={() => setOpenModal(false)}>
      <ContentModal>
        <Container>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Header>
              <CloseButton onClick={() => setOpenModal(false)}>
                <img src={ImgClose} alt="Fechar modal" />
              </CloseButton>
              <Avatar
                className={
                  dataUser?.nomeUser ? 'SettingsModal__BlackColor' : ''
                }
                src={ImgIconUser}
                alt="Avatar"
              />
              <div>
                <Title
                  className={
                    dataUser?.nomeUser ? 'SettingsModal__BlackColor' : ''
                  }
                >
                  {dataUser?.nomeUser || 'NOME DO USUÁRIO'}
                </Title>
                <Subtitle
                  className={
                    dataUser?.venEmail ? 'SettingsModal__BlackColor' : ''
                  }
                >
                  {dataUser?.venEmail || 'E-mail'}
                </Subtitle>
              </div>
            </Header>
            <main>
              <FormStep>
                <FormRow>
                  <FormField>
                    <Label htmlFor="userName">Nome do Usuário</Label>
                    <input
                      type="text"
                      id="userName"
                      placeholder="EX.: Artur Bezerra"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^a-zA-Z0-9\s]/g,
                          '',
                        )
                      }}
                      {...register('nomeUser')}
                    />
                  </FormField>
                </FormRow>
                <FormCol>
                  <FormField>
                    <Label htmlFor="userLogin">Login</Label>
                    <input
                      type="text"
                      id="userLogin"
                      placeholder="Ex.: ARTUR"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^a-zA-Z0-9\s]/g,
                          '',
                        )
                      }}
                      {...register('loginUser', { required: true })}
                    />
                    {errors.loginUser && <ErrorSpan>Obrigatório</ErrorSpan>}
                  </FormField>
                  <FormField>
                    <Label>Perfil de acesso</Label>
                    <input
                      type="hidden"
                      {...register('perId', { required: true })}
                      value={selectedPerfil}
                    />
                    <ListboxWrapper>
                      <Listbox
                        value={selectedPerfil}
                        onChange={handleSelectPerfil}
                      >
                        {({ open }) => (
                          <div style={{ height: '100%' }}>
                            <Listbox.Button
                              className="ListboxButton"
                              style={{ height: '100%', textAlign: 'left' }}
                            >
                              {selectedPerfil === ''
                                ? 'Selecione o perfil'
                                : selectedPerfil}
                            </Listbox.Button>
                            <Listbox.Options className="ListboxOptions">
                              {PERFIL_ACESSO.map((perfil) => (
                                <Listbox.Option
                                  key={perfil.perfilId}
                                  value={perfil}
                                  className="ListboxOption"
                                >
                                  {perfil.value}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </div>
                        )}
                      </Listbox>
                    </ListboxWrapper>
                    {errors.perId && <ErrorSpan>Obrigatório</ErrorSpan>}
                  </FormField>
                </FormCol>
                <FormCol>
                  <FormField>
                    <Label htmlFor="password">Senha</Label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Informe a senha"
                      {...register('senhaUser')}
                    />
                  </FormField>
                  <FormField>
                    <Label htmlFor="password">Repita a Senha</Label>
                    <input
                      type="password"
                      id="passwordConfirm"
                      placeholder="Repita a Senha"
                    />
                  </FormField>
                </FormCol>
              </FormStep>
              {selectedPerfil === 'Vendedor' ? (
                <FormStep>
                  <ListboxWrapper>
                    <Label htmlFor="">Vendedores Disponíveis</Label>
                    <input
                      type="hidden"
                      {...register('venId', {
                        required: selectedPerfil === 'Vendedor',
                      })}
                      value={selectedVen}
                    />
                    <Listbox value={selectedVen} onChange={handleSelectVen}>
                      <Listbox.Button className="ListboxButton">
                        {selectedVen === ''
                          ? 'Selecione o vendedor'
                          : selectedVen}
                      </Listbox.Button>
                      <Listbox.Options className="ListboxOptions">
                        {ven.map((item) => (
                          <Listbox.Option
                            key={item.value}
                            value={item}
                            className="ListboxOption"
                          >
                            {item.label}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Listbox>
                    {errors.venId && <ErrorSpan>Obrigatório</ErrorSpan>}
                  </ListboxWrapper>
                </FormStep>
              ) : null}
              <div className="save_button">
                <button type="submit" disabled={isSubmitting}>
                  Salvar
                </button>
              </div>
            </main>
          </form>
        </Container>
      </ContentModal>
    </BackgroundModal>
  )
}
