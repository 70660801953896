import { apiGeral } from '../api'
import { ENDPOINTS } from '../endPoints'

export const planosRepository = {
  buscarPlanos: async (segmento, valor, VEN_ID) => {
    try {
      const response = await apiGeral.get(
        `${ENDPOINTS.PLANOS.BUSCAR_PLANOS_SEGMENTO}?segmento=${segmento}&preco=${valor}`,
        {
          headers: {
            VEN_ID: VEN_ID,
          },
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  buscarAdicionais: async (PLAN_ID, VEI_SEGMENTO) => {
    try {
      const response = await apiGeral.get(
        `${ENDPOINTS.PLANOS.BUSCAR_PLANOS_ADICIONAIS}?plan_id=${PLAN_ID}&tipo_veiculo=${VEI_SEGMENTO}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}
