export const ParametrosSvg = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.977 7.758a3.424 3.424 0 0 0 3.43-3.43 3.423 3.423 0 0 0-3.43-3.422 3.428 3.428 0 0 0-3.43 3.422 3.43 3.43 0 0 0 3.43 3.43zm-8.555 0c1.89 0 3.43-1.531 3.43-3.422a3.43 3.43 0 0 0-3.43-3.43 3.424 3.424 0 0 0-3.43 3.43 3.423 3.423 0 0 0 3.43 3.422zm8.555-1.414a2.009 2.009 0 0 1-2.008-2.016c0-1.11.898-2.008 2.008-2.008 1.117 0 2.007.899 2.007 2.008 0 1.117-.89 2.016-2.007 2.016zm-8.555 0A2.01 2.01 0 1 1 5.43 4.336c0 1.11-.899 2.008-2.008 2.008zm8.555 9.468a3.424 3.424 0 0 0 3.43-3.43 3.43 3.43 0 1 0-3.43 3.43zm-8.555 0a3.43 3.43 0 1 0-3.43-3.43 3.424 3.424 0 0 0 3.43 3.43zm8.555-1.421a2.007 2.007 0 1 1 0-4.016c1.117 0 2.007.898 2.007 2.008 0 1.11-.89 2.008-2.007 2.008zm-8.555.007a2.01 2.01 0 1 1 0-4.023c1.11 0 2.008.898 2.008 2.008a2.009 2.009 0 0 1-2.008 2.015z"
      fill="#353535"
      fillRule="nonzero"
    />
  </svg>
)

export const ParametrosSvgSelected = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.242 7.79a3.466 3.466 0 1 0 0-6.93 3.464 3.464 0 1 0 0 6.93zm-8.484.007a3.47 3.47 0 0 0 3.469-3.469A3.47 3.47 0 0 0 3.757.86 3.47 3.47 0 0 0 .29 4.33a3.47 3.47 0 0 0 3.469 3.468zm8.484 8.062a3.47 3.47 0 0 0 3.469-3.468 3.47 3.47 0 0 0-3.469-3.47 3.47 3.47 0 0 0-3.469 3.47 3.47 3.47 0 0 0 3.47 3.468zm-8.484 0a3.47 3.47 0 0 0 3.469-3.468 3.47 3.47 0 0 0-3.47-3.47 3.47 3.47 0 0 0-3.468 3.47 3.47 3.47 0 0 0 3.469 3.468z"
      fill="#0049C4"
      fillRule="nonzero"
    />
  </svg>
)
