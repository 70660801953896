import { useEffect, useState } from 'react'
import ImgAdd from '../../../Home/assets/icon-add.svg'
import menuAjustesTable from '../../../Home/assets/menu-ajustes-table.svg'
import menuAjustesTableFill from '../../../Home/assets/menu-ajustes-table-fill.svg'
import { parametrosRepository } from '../../../../api/repositories/parametrosRepository'
import { OrigensContainer } from './styles'
import { ModalOrigemLead } from './ModalOrigemLead/ModalOrigemLead'
import { Dropdown } from 'react-bootstrap'
import {
  ButtonInsideWrapper,
  TableButton,
} from '../../../Home/HomeRoutes/Oportunidades/Data Table/styles/styledDataTable'
import editPencil from '../../../Home/assets/icon-edit-pencil.svg'
import archiveIcon from '../../../Home/assets/icon-arquivar.svg'
import { EditarOrigem } from './EditarOrigem/EditarOrigem'
import { ModalArchive } from './ModalArchive/ModalArchive'

export function OrigemLead({ setLoading }) {
  const [filterResult, setFilterResult] = useState([])
  const [origensCriadas, setOrigensCriadas] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [editOrigemModal, setEditOrigemModal] = useState(false)
  const [archiveOrigemModal, setArchiveOrigemModal] = useState(false)
  const [origemData, setOrigemData] = useState({})
  const [alteracaoFeita, setAlteracaoFeita] = useState(false)

  useEffect(() => {
    setLoading(true)
    listarOrigens()
    setAlteracaoFeita(false)
  }, [alteracaoFeita])

  async function listarOrigens() {
    try {
      const { origens } = await parametrosRepository.listarOrigens()
      console.log(origens)
      setOrigensCriadas(origens)
      setFilterResult(origens)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  async function handleChangeFilter(e) {
    const { value } = e.target
    setSearchInput(value)
    const filteredData = origensCriadas.filter((item) => {
      return Object.values(item)
        .join('')
        .toLowerCase()
        .includes(value.toLowerCase())
    })

    setFilterResult(filteredData)
  }

  function ButtonDropDownMenu({ origem }) {
    return (
      <Dropdown>
        <Dropdown.Toggle>
          <img src={menuAjustesTable} alt="Menu ajustes" />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ minWidth: '5rem' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.9375rem',
            }}
          >
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              disabled={!origem.ORG_ATIVO}
              onClick={() => {
                setEditOrigemModal(true)
                setOrigemData(origem)
              }}
            >
              <ButtonInsideWrapper>
                <img src={editPencil} alt="" />
                <span>Editar</span>
              </ButtonInsideWrapper>
            </TableButton>
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              onClick={() => {
                setArchiveOrigemModal(true)
                setOrigemData(origem)
              }}
            >
              <ButtonInsideWrapper>
                <img src={archiveIcon} alt="" />
                <span>{origem.ORG_ATIVO ? 'Arquivar' : 'Desarquivar'}</span>
              </ButtonInsideWrapper>
            </TableButton>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <OrigensContainer>
      <>
        {openModal && (
          <ModalOrigemLead
            openModal={openModal}
            setOpenModal={setOpenModal}
            alteracaoFeita={setAlteracaoFeita}
          />
        )}

        {editOrigemModal && (
          <EditarOrigem
            openModal={editOrigemModal}
            setOpenModal={setEditOrigemModal}
            origemData={origemData}
            alteracaoFeita={setAlteracaoFeita}
          />
        )}

        {archiveOrigemModal && (
          <ModalArchive
            openModal={archiveOrigemModal}
            setOpenModal={setArchiveOrigemModal}
            origemData={origemData}
            alteracaoFeita={setAlteracaoFeita}
          />
        )}

        <section>
          <header>
            <div>
              <div className="SettingsUsers_Header_Title">
                <span className="SettingsUsers_Header_Title">
                  Origem do Lead
                </span>
                <img src={ImgAdd} onClick={() => setOpenModal(true)} />
              </div>
              <div>
                <span className="SettingsUsers_Header_SubTitle">
                  Crie novas origens ou gerencie as já existentes.
                </span>
              </div>
            </div>
            <div>
              <form onSubmit={(event) => event.preventDefault()}>
                <input
                  type="text"
                  name="searchValue"
                  id="searchValue"
                  value={searchInput}
                  onChange={handleChangeFilter}
                  placeholder="Pesquisar"
                  maxLength="25"
                />
              </form>
            </div>
          </header>
          <main>
            <table>
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {filterResult.map((origem) => (
                  <tr key={origem.ORG_ID}>
                    <td>{origem.ORG_DESCRICAO}</td>
                    <td>
                      <div
                        className={`SettingsUsers__Status ${
                          origem.ORG_ATIVO
                            ? 'SettingsUsers__Status--active'
                            : 'SettingsUsers__Status--inactive'
                        }`}
                      >
                        {origem.ORG_ATIVO ? 'ATIVO' : 'ARQUIVADO'}
                      </div>
                    </td>
                    <td>
                      <div>
                        <ButtonDropDownMenu origem={origem} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </main>
        </section>
      </>
    </OrigensContainer>
  )
}
