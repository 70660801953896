import styled from 'styled-components'
// import { Link } from "react-router-dom";

export const ContainerOnboard = styled.div`
  position: relative;

  /* --- ANIMAÇÕES --- */

  .animation_fadedown {
    opacity: 0;
    transform: translateY(-25px);
    animation: animarPage 1s forwards;
  }

  .animation_fadetop {
    opacity: 1;
    transform: translateY(250px);
    animation: animarPage 1s forwards;
  }

  .animation_fadeleft {
    opacity: 0;
    transform: translateX(-100px);
    animation: animarPage 1.5s forwards;
  }

  .animation_faderight {
    opacity: 0.5;
    transform: translateX(300px);
    animation: animarPage 2s forwards;
  }

  /* --- ESTILOS GERAIS --- */

  .onboarding-header {
    position: absolute;
    top: 20px;
    left: 30px;
    z-index: 1;

    img {
      width: 10rem;
      height: 3.75rem;
    }

    @media screen and (max-width: 1180px) {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }

  .btn__avançar {
    width: 150px;
    height: 40px;
    font-size: 1.25rem;
    border-radius: 10px;
    border: none;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    color: #fff;
    background-color: #1768e4;

    :hover {
      filter: brightness(0.9);
    }

    :focus,
    :active {
      outline: none;
    }
  }

  .btn__avançar[type='submit']:disabled {
    cursor: not-allowed;
    filter: brightness(0.5);
    opacity: 0.7;
  }
  .btn__avançar:disabled {
    cursor: not-allowed;
    filter: brightness(0.5);
    opacity: 0.7;
  }

  .erro__codigo {
    display: block;
    font-size: 1.25rem;
    font-weight: 600;
    color: red;
    margin: 0.5rem 0;
  }

  /* --- CONTINUAÇÃO --- */

  .onboarding-body {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100vh;
    padding: 5rem 1rem;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;

    @media screen and (max-width: 1180px) {
      height: 100%;
      padding: 6rem 1rem 3rem;
    }

    .onboarding-body__title {
      margin-bottom: 5rem;

      h1 {
        font-size: 2.5rem;
        margin-top: 15px;
        margin-bottom: 10px;
      }
      p {
        font-size: 1.125rem;
        margin: 0;
        font-weight: 200;
      }
    }

    .onboarding-body__cards {
      display: flex;
      justify-content: center;
      gap: 2.5rem;

      @media screen and (max-width: 1180px) {
        flex-direction: column;
        align-items: center;
      }
    }

    .onboarding-body__btn {
      margin-top: 5rem;
    }
  }

  .cadcodigo-body,
  .regusuario-body,
  .concadastro-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
    overflow: hidden;

    .cadcodigo-body__code,
    .regusuario-body__data,
    .concadastro-body__data {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 0 2.375rem;
      height: 100vh;

      .title {
        font-size: 3.75rem;
        font-weight: 600;
        width: 21rem;
        margin: 0;
        /* margin-top: 5rem; */
      }

      .subtitle {
        font-size: 1rem;
        font-weight: 300;
        margin: 12px 0 0;
        max-width: 27.1875rem;
        color: #2c2c2c;

        @media screen and (max-width: 1180px) {
          margin-bottom: 1rem;
        }
      }

      .cadcodigo-form__body {
        display: flex;
        flex-direction: column;
        align-items: center;

        .code__input {
          font-size: 6.25rem;
          font-weight: 600;
          width: 100%;
          border: 0;
          border-bottom: solid 1px #cdcdcd;
          outline: 0;
          padding: 0.5rem 0;
          text-transform: uppercase;
          transition: all 0.2s ease-in-out;
          font-family: -apple-system, BlinkMacSystemFont, sans-serif;
          line-height: inherit;
          ::placeholder {
            color: #dadada;
          }

          :focus {
            border-bottom: solid 1px #1768e4;
          }

          @media screen and (max-width: 1180px) {
            font-size: 4.5rem;
          }
        }
        @media screen and (min-width: 1600px) {
          align-items: inherit;
        }
      }

      .regusuario-body__form {
        display: flex;
        flex-direction: column;
        align-items: center;

        .erro__warning {
          color: red;
          margin-top: 0.7rem;
          padding-top: 0.7rem;
          font-weight: 600;
        }

        .form__inputs {
          display: grid;
          grid-template-rows: 1fr 1fr;

          .form__row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;

            :first-child {
              margin-bottom: 1.375rem;
            }

            label {
              font-weight: 400;
              color: #000;
              margin: 0;
              padding: 0;
              font-size: 14px;
            }

            input {
              font-size: 1.68rem;
              font-weight: 600;

              width: 100%;
              max-width: 400px;

              border: 0;
              border-bottom: solid 1px #cdcdcd;
              outline: 0;
              padding: 0.5rem 0;
              /* text-transform: uppercase; */
              transition: all 0.2s ease-in-out;
              margin-bottom: 7px;

              ::placeholder {
                color: #cdcdcd;
              }
            }

            .form-column {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }

      .concadastro-body__card {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        padding: 0.75rem 1.75rem;

        height: 208px;
        border-radius: 1.125rem;
        box-shadow: 0 2px 10px 0 rgba(217, 217, 217, 0.5);
        margin-top: -5rem;
        /* margin-bottom: 2rem; */

        .concadastro-body__card__header {
          display: flex;
          justify-content: space-between;

          .card__header__icons {
            display: flex;
            gap: 0.75rem;

            img {
              cursor: pointer;

              :first-child {
                width: 22px;
                height: 27px;
              }

              :last-child {
                width: 19px;
                height: 25px;
              }
            }
          }
        }

        .concadastro-body__card__content {
          display: flex;
          flex-direction: column;

          .card__content__title {
            margin-bottom: 1rem;
            font-size: 1rem;
          }

          .card__content__input {
            display: flex;
            position: relative;

            align-items: center;
            justify-content: center;

            input {
              display: flex;
              justify-content: center;
              align-items: center;

              height: 45px;
              width: 100%;

              background-color: #f0f0f0;

              border: none;
              border-radius: 0.6875rem;

              padding: 0 1rem;

              font-size: 1.3125rem;
              color: #000;
              font-weight: bold;
            }

            .input__icon {
              height: 45px;
              width: 45px;

              background-color: #1768e4;
              color: #fff;

              border-radius: 0.6875rem;

              position: absolute;
              top: 0;
              right: 0;

              cursor: pointer;
            }
          }
        }
      }

      @media screen and (max-width: 1180px) {
        .cadcodigo-form__header {
          h1 {
            font-size: 2rem;
            margin: 5rem 0 0 0;
          }
        }
      }
    }

    .cadcodigo-body__ui,
    .regusuario-body__ui {
      background-color: #1768e4;
    }

    .cadcodigo-body__ui {
      position: relative;
      width: 100%;
      height: 100%;

      .Onboarding__AnimaRight {
        opacity: 0.5;
        /* transform: translateX(300px) !important; */
        transform: translate(300px);
        animation: animarPage 2s forwards;
      }

      .Onboarding__AnimaTop {
        opacity: 1;
        transform: translateY(250px);
        animation: animarPage 1s forwards;
      }
    }

    .VisualCommunication__UI--Shape,
    .VisualCommunication__UI--Navegador {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .concadastro-body__ui {
      background-color: #00b065;
    }
  }

  @keyframes animarPage {
    to {
      opacity: 1;
      transform: initial;
    }
  }

  @media screen and (max-width: 1180px) {
    .cadcodigo-body__ui {
      display: none;
    }
  }

  @media screen and (max-width: 1180px) {
    .cadcodigo-body {
      grid-template-columns: 1fr;
      height: 100%;
    }

    .cadcodigo-body__code {
      padding: 1rem !important;
      box-sizing: border-box;
    }
  }
`
