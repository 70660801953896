import React, { useState, useEffect } from 'react'
import CreateTemplate from './CreateTemplate.jsx/CreateTemplate'
import { BtnSubmit } from '../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal'
import { FunilWrapper } from '../Funis/styledFunis'
import { FaPlus } from 'react-icons/fa'
import Loading from '../../Loading/Loading'
import { MdUnarchive, MdEdit } from 'react-icons/md'
import axios from 'axios'
import { COLORS } from '../../../colors'
import Tippy from '@tippyjs/react'
import { TableButton } from '../../Home/HomeRoutes/Oportunidades/Data Table/styles/styledDataTable'
import ConfirmarDesarquivar from './ConfirmarDesarquivar/ConfirmarDesarquivar'
import { parametrosRepository } from '../../../api/repositories/parametrosRepository'

function Templates() {
  const DEFAULT_TEMPLATE = {
    TIPO_ID: '',
    TIPO_NAME: '',
    MENSAGEM_ID: '',
    MENSAGEM_TITULO: '',
    MENSAGEM_DESCRICAO: '',
    FORMATACAO: '',
    LOGO_URL: '',
  }

  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  const [loading, setLoading] = useState(false)
  const [openModalCreate, setOpenModalCreate] = useState(false)
  const [openModalArchive, setOpenModalArchive] = useState(false)
  const [tempId, setTempId] = useState('')
  const [template, setTemplate] = useState(DEFAULT_TEMPLATE)
  const [templatesList, setTemplatesList] = useState([])

  useEffect(() => {
    ;(async () => {
      const buscarMensagemTemplates = async () => {
        try {
          const { dados } = await parametrosRepository.listarMensagemTemplates()
          setTemplatesList(dados)
          setLoading(false)
        } catch (error) {
          console.log(error)
          setLoading(false)
        }
      }

      buscarMensagemTemplates()

      /*
      setLoading(true)
      await axios
        .get(
          'https://kaminocrm.com.br:7778/parametros/listarMensagemTemplates',
          {
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          },
        )
        .then((res) => {
          setTemplatesList(res.data.dados)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        })
      */
    })()
  }, [openModalCreate, openModalArchive])

  function checarStatus(MENSAGEM_ATIVO) {
    if (MENSAGEM_ATIVO) {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.pendente}`,
            padding: '2px 10px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          ATIVO
        </span>
      )
    } else {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.perdida}`,
            padding: '2px 7px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          ARQUIVADO
        </span>
      )
    }
  }

  return (
    <>
      {openModalCreate && (
        <CreateTemplate
          setOpenModal={setOpenModalCreate}
          template={template}
          setTemplate={setTemplate}
        />
      )}
      {openModalArchive && (
        <ConfirmarDesarquivar
          setOpenModalArchive={setOpenModalArchive}
          MENSAGEM_ID={tempId}
          setTempId={setTempId}
        />
      )}
      {loading && <Loading />}
      <FunilWrapper>
        <div className="criar">
          <BtnSubmit
            onClick={() => {
              setOpenModalCreate(true)
            }}
          >
            <FaPlus size={11} /> Novo Modelo
          </BtnSubmit>
        </div>
        <h3>Gerenciar modelos</h3>

        <div className="funis_existem">
          <table>
            <thead>
              <tr>
                <th className="col_nome">Nome</th>
                <th className="col_status">Tipo</th>
                <th className="col_status">Status</th>
                <th className="col_acoes">Ações</th>
              </tr>
            </thead>
            <tbody>
              {templatesList.map((i) => {
                return (
                  <tr key={i.MENSAGEM_ID}>
                    <td className="col_nome">{i.MENSAGEM_TITULO}</td>
                    <td className="col_status">{i.TIPO_NAME}</td>
                    <td className="col_status">
                      {checarStatus(i.MENSAGEM_ATIVO)}
                    </td>
                    <td className="col_acoes">
                      {i.MENSAGEM_ATIVO ? (
                        <Tippy
                          content="Editar modelo"
                          delay="275"
                          placement="bottom"
                        >
                          <TableButton
                            onClick={() => {
                              setTemplate(i)
                              setOpenModalCreate(true)
                            }}
                          >
                            <MdEdit size={20} />
                          </TableButton>
                        </Tippy>
                      ) : (
                        <Tippy
                          content="Desarquivar modelo"
                          delay="275"
                          placement="bottom"
                        >
                          <TableButton
                            onClick={() => {
                              setOpenModalArchive(true)
                              setTempId(i.MENSAGEM_ID)
                            }}
                          >
                            <MdUnarchive size={22} />
                          </TableButton>
                        </Tippy>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </FunilWrapper>
    </>
  )
}

export default Templates
