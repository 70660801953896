import React, { createContext, useState } from 'react'

export const CadastroPlano = createContext()

const CadastroPlanoProvider = ({ children }) => {
  const [idPlano, setIdPlano] = useState([])
  const [valoresPlano, setValoresPlano] = useState([])
  const [adicionais, setAdicionais] = useState([])

  return (
    <CadastroPlano.Provider
      value={{
        idPlano,
        setIdPlano,
        valoresPlano,
        setValoresPlano,
        adicionais,
        setAdicionais,
      }}
    >
      {children}
    </CadastroPlano.Provider>
  )
}

export default CadastroPlanoProvider
