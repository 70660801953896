import { useEffect, useState } from 'react'
import { parametrosRepository } from '../../../../../api/repositories/parametrosRepository'
import { ContentMessage } from '../components/ContentMessage/ContentMessage'
import { TypesListModel } from '../components/TypeListModel'
import IconClose from '../../../../Home/assets/icon-close.svg'
import { MenuTemplate } from './MenuTemplate'

export function ModalOrigemLead({
  setOpenModal,
  template,
  setTemplate,
  alteracaoFeita,
}) {
  const [step, setStep] = useState(1)
  const [tiposTemplates, setTiposTemplates] = useState([])
  const [openModalArchive, setOpenModalArchive] = useState(false)
  const [tagList, setTagList] = useState([])

  // useEffect(() => {
  //   if (openModalArchive || setOpenModal) {
  //     document.body.classList.add('no-scroll')
  //   } else {
  //     document.body.classList.remove('no-scroll')
  //   }

  //   return () => {
  //     document.body.classList.remove('no-scroll')
  //   }
  // }, [openModalArchive, setOpenModal])

  useEffect(() => {
    buscarTiposMensagens()
    obterTagsTemplate()
  }, [])

  async function buscarTiposMensagens() {
    try {
      const { dados } = await parametrosRepository.buscarTiposMensagens()
      setTiposTemplates(dados)
    } catch (error) {
      console.log(error)
    }
  }

  async function obterTagsTemplate() {
    try {
      const { dados } = await parametrosRepository.buscarTagsTemplate()
      setTagList(dados)
    } catch (error) {
      console.log(error)
    }
  }

  async function editarOuCriarMensagem(dataSend, template) {
    try {
      await parametrosRepository.editarOuCriarMensagem(dataSend, template)

      setOpenModal(false)
      setTemplate({
        TIPO_ID: '',
        TIPO_NAME: '',
        MENSAGEM_TITULO: '',
        MENSAGEM_DESCRICAO: '',
        FORMATACAO: '',
        LOGO_URL: '',
      })
    } catch (error) {
      console.log(error)
    }
  }

  async function handleTemplate() {
    const dataSend =
      template.MENSAGEM_ID !== ''
        ? {
            TIPO_ID: template.TIPO_ID,
            MENSAGEM_TITULO: template.MENSAGEM_TITULO,
            MENSAGEM_DESCRICAO: template.MENSAGEM_DESCRICAO,
            MENSAGEM_ID: template.MENSAGEM_ID,
            FORMATACAO: template.FORMATACAO,
            LOGO_URL: template.LOGO_URL,
          }
        : {
            TIPO_ID: template.TIPO_ID,
            MENSAGEM_TITULO: template.MENSAGEM_TITULO,
            MENSAGEM_DESCRICAO: template.MENSAGEM_DESCRICAO,
            FORMATACAO: template.FORMATACAO,
            LOGO_URL: template.LOGO_URL,
          }

    await editarOuCriarMensagem(dataSend, template)
    alteracaoFeita(true)
  }

  function handleDisabled() {
    const { TIPO_NAME, MENSAGEM_TITULO, MENSAGEM_DESCRICAO, FORMATACAO } =
      template

    if (step === 1) {
      return TIPO_NAME === ''
    } else {
      return (
        TIPO_NAME === '' ||
        MENSAGEM_TITULO.length < 1 ||
        MENSAGEM_DESCRICAO.length < 1 ||
        FORMATACAO?.length < 1 ||
        !FORMATACAO
      )
    }
  }

  function renderSteps() {
    switch (step) {
      case 1:
        return (
          <TypesListModel
            tiposTemplates={tiposTemplates}
            template={template}
            setTemplate={setTemplate}
          />
        )
      case 2:
        return (
          <ContentMessage
            setTemplate={setTemplate}
            template={template}
            tagList={tagList}
          />
        )
      default:
        return 'Passo inválido'
    }
  }

  return (
    <div className="bg_add Main__Fade">
      <div className="templates_create_container">
        <div className="modal__templates">
          <div className="close__modal">
            <img
              src={IconClose}
              alt="close"
              onClick={() => {
                setOpenModal(false)
                setTemplate({
                  TIPO_ID: '',
                  TIPO_NAME: '',
                  MENSAGEM_ID: '',
                  MENSAGEM_TITULO: '',
                  MENSAGEM_DESCRICAO: '',
                  FORMATACAO: '',
                  LOGO_URL: '',
                })
              }}
            />
          </div>
          <div className="templates__content">
            <MenuTemplate step={step} templateId={template.MENSAGEM_ID} />
            <div className="templates__box">
              {renderSteps()}
              <div className="btn__container">
                {step === 2 && (
                  <button className="btn_voltar" onClick={() => setStep(1)}>
                    Voltar
                  </button>
                )}
                <button
                  className="btn_avancar"
                  disabled={handleDisabled()}
                  onClick={() => {
                    step === 1 ? setStep(2) : handleTemplate()
                  }}
                >
                  {step === 1 ? 'Avançar' : 'Salvar'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
