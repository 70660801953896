import { apiGeral } from '../api'
import { ENDPOINTS } from '../endPoints'

export const filtroRepository = {
  buscarFiltro: async (jwtToken, userLogin, assCod) => {
    try {
      const response = await apiGeral.get(ENDPOINTS.FILTRO.BUSCAR_FILTRO, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          user_login: userLogin,
          ass_cod: assCod,
        },
      })

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}
