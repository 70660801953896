import { useEffect, useState } from 'react'
import ImgAdd from '../../../Home/assets/icon-add.svg'
import menuAjustesTable from '../../../Home/assets/menu-ajustes-table.svg'
import menuAjustesTableFill from '../../../Home/assets/menu-ajustes-table-fill.svg'
import { parametrosRepository } from '../../../../api/repositories/parametrosRepository'
import { ModelosContainer } from './styles'
import { ModalOrigemLead } from './ModalOrigemLead/ModalOrigemLead'
import { Dropdown } from 'react-bootstrap'
import {
  ButtonInsideWrapper,
  TableButton,
} from '../../../Home/HomeRoutes/Oportunidades/Data Table/styles/styledDataTable'
import archiveIcon from '../../../Home/assets/icon-arquivar.svg'
import editPencil from '../../../Home/assets/icon-edit-pencil.svg'
import { ModalArchive } from './ModalArchive/ModalArchive'

export function Modelos({ setLoading }) {
  const DEFAULT_TEMPLATE = {
    TIPO_ID: '',
    TIPO_NAME: '',
    MENSAGEM_ID: '',
    MENSAGEM_TITULO: '',
    MENSAGEM_DESCRICAO: '',
    FORMATACAO: '',
    LOGO_URL: '',
  }

  const [filterResult, setFilterResult] = useState([])
  const [templatesList, setTemplatesList] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [template, setTemplate] = useState(DEFAULT_TEMPLATE)
  const [openModal, setOpenModal] = useState(false)
  const [openModalArchive, setOpenModalArchive] = useState(false)
  const [alteracaoFeita, setAlteracaoFeita] = useState(false)
  const [modalData, setModalData] = useState([])

  useEffect(() => {
    setLoading(true)
    buscarMensagemTemplates()
    setAlteracaoFeita(false)
  }, [alteracaoFeita])

  async function buscarMensagemTemplates() {
    try {
      const { dados } = await parametrosRepository.listarMensagemTemplates()
      setTemplatesList(dados)
      setFilterResult(dados)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  async function handleChangeFilter(e) {
    const { value } = e.target
    setSearchInput(value)
    const filteredData = templatesList.filter((item) => {
      return Object.values(item)
        .join('')
        .toLowerCase()
        .includes(value.toLowerCase())
    })

    setFilterResult(filteredData)
  }

  function ButtonDropDownMenu({ modelo }) {
    return (
      <Dropdown>
        <Dropdown.Toggle>
          <img src={menuAjustesTable} alt="Menu ajustes" />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ minWidth: '5rem' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.9375rem',
            }}
          >
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              disabled={!modelo.MENSAGEM_ATIVO}
              onClick={() => {
                setTemplate(modelo)
                setOpenModal(true)
              }}
            >
              <ButtonInsideWrapper>
                <img src={editPencil} alt="" />
                <span>Editar</span>
              </ButtonInsideWrapper>
            </TableButton>
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              onClick={() => {
                setOpenModalArchive(true)
                setModalData(modelo)
              }}
            >
              <ButtonInsideWrapper>
                <img src={archiveIcon} alt="" />
                <span>
                  {modelo.MENSAGEM_ATIVO ? 'Arquivar' : 'Desarquivar'}
                </span>
              </ButtonInsideWrapper>
            </TableButton>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <ModelosContainer>
      <>
        {openModal && (
          <ModalOrigemLead
            setOpenModal={setOpenModal}
            template={template}
            setTemplate={setTemplate}
            alteracaoFeita={setAlteracaoFeita}
          />
        )}

        {openModalArchive && (
          <ModalArchive
            openModal={openModalArchive}
            setOpenModal={setOpenModalArchive}
            modalData={modalData}
            alteracaoFeita={setAlteracaoFeita}
          />
        )}

        <section>
          <header>
            <div>
              <div className="SettingsUsers_Header_Title">
                <span className="SettingsUsers_Header_Title">Modelos</span>
                <img src={ImgAdd} onClick={() => setOpenModal(true)} />
              </div>
              <div>
                <span className="SettingsUsers_Header_SubTitle">
                  Crie novos modelos ou gerencie as já existentes.
                </span>
              </div>
            </div>
            <div>
              <form onSubmit={(event) => event.preventDefault()}>
                <input
                  type="text"
                  name="searchValue"
                  id="searchValue"
                  value={searchInput}
                  onChange={handleChangeFilter}
                  placeholder="Pesquisar"
                  maxLength="25"
                />
              </form>
            </div>
          </header>
          <main>
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Tipo</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {filterResult.map((modelo) => (
                  <tr key={modelo.MENSAGEM_ID}>
                    <td>{modelo.MENSAGEM_TITULO}</td>
                    <td>{modelo.TIPO_NAME}</td>
                    <td>
                      <div
                        className={`SettingsUsers__Status ${
                          modelo.MENSAGEM_ATIVO
                            ? 'SettingsUsers__Status--active'
                            : 'SettingsUsers__Status--inactive'
                        }`}
                      >
                        {modelo.MENSAGEM_ATIVO ? 'ATIVO' : 'ARQUIVADO'}
                      </div>
                    </td>
                    <td>
                      <div>
                        <ButtonDropDownMenu modelo={modelo} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </main>
        </section>
      </>
    </ModelosContainer>
  )
}
