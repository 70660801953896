import React from 'react'
import { Navigate } from 'react-router-dom'
import axios from 'axios'
import { IoMdClose } from 'react-icons/io'
import {
  BackgroundModal,
  ModalBoxConfirmar,
  ModalHeader,
  X,
} from '../../../../Modal/style/styledModal'
import { TokenWrapper } from '../../styles/styledVistoria'

function ModalToken({ isVisible, setModalToken, token }) {
  return (
    <BackgroundModal>
      <ModalBoxConfirmar>
        <ModalHeader>
          <h2>Token da Vistoria</h2>
          <X
            onClick={() => {
              isVisible(false)
              window.location.reload()
            }}
          >
            <IoMdClose size={17} />
          </X>
        </ModalHeader>

        <div>
          <TokenWrapper>
            <h1>{token}</h1>
          </TokenWrapper>
          {/* <button onClick={() => setModalToken(false)}>Fechar essa janela (temp)</button> */}
        </div>
      </ModalBoxConfirmar>
    </BackgroundModal>
  )
}

export default ModalToken
