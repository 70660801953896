import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ContainerOnboard } from '../style'
import logo from '../assets/kamino_logo.svg'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { EmpresaCadastroContext } from '../../../context/EmpresaCadastroContext'
import { empresaOnboardingRepository } from '../../../api/repositories/empresaRepository'

function RegistroUsuario() {
  const navigate = useNavigate()
  const { empresaData, setEmpresaData } = useContext(EmpresaCadastroContext)
  const [usersData, setUsersData] = useState({
    login_novo_user: '',
    email_novo_user: '',
    senha_novo_user: '',
    senhaconf_novo_user: '',
  })

  const [erroWarning, setErroWarning] = useState({
    login_novo_user: '',
    email_novo_user: '',
    senha_novo_user: '',
    senhaconf_novo_user: '',
  })

  const {
    EMP_ADICIONAIS,
    EMP_CNPJ,
    EMP_COD,
    EMP_ENDOR,
    EMP_FONE,
    EMP_NOME,
    EMP_PLANOS,
    EMP_VENDEDORES_URL,
    NABOO_URL,
  } = empresaData

  // const isFormValid = () => {
  //   return (
  //     Object.values(usersData).every((value) => value !== "") &&
  //     usersData.senha_novo_user === usersData.senhaconf_novo_user
  //   );
  // };

  const handleDisabled = () => {
    const {
      login_novo_user,
      email_novo_user,
      senha_novo_user,
      senhaconf_novo_user,
    } = usersData
    return (
      login_novo_user === '' ||
      login_novo_user.includes(' ') ||
      login_novo_user.toUpperCase() === 'ADMIN' ||
      login_novo_user.length < 5 ||
      email_novo_user === '' ||
      senha_novo_user !== senhaconf_novo_user ||
      senha_novo_user === '' ||
      senhaconf_novo_user === ''
    )
  }

  function handleChange(e) {
    const { name, value } = e.target
    setUsersData((prev) => ({
      ...prev,
      [name]: value,
    }))

    if (name === 'login_novo_user') {
      if (value.includes(' ')) {
        setErroWarning((prev) => ({
          ...prev,
          login_novo_user: 'O login não deve ter espaços!',
        }))
      } else if (value.toUpperCase() === 'ADMIN') {
        setErroWarning((prev) => ({
          ...prev,
          login_novo_user: 'O login não pode ser admin!',
        }))
      } else if (value.length < 5) {
        setErroWarning((prev) => ({
          ...prev,
          login_novo_user: 'O login deve ter no mín. 5 caracteres!',
        }))
      } else {
        setErroWarning((prev) => ({
          ...prev,
          login_novo_user: '',
        }))
      }
    } else if (name === 'senha_novo_user') {
      if (value !== usersData.senhaconf_novo_user) {
        setErroWarning((prev) => ({
          ...prev,
          senha_novo_user: 'As senhas não correspondem!',
        }))
      } else {
        setErroWarning((prev) => ({
          ...prev,
          senha_novo_user: '',
        }))
      }
    } else if (name === 'senhaconf_novo_user') {
      if (value !== usersData.senha_novo_user) {
        setErroWarning((prev) => ({
          ...prev,
          senha_novo_user: 'As senhas não correspondem!',
        }))
      } else {
        setErroWarning((prev) => ({
          ...prev,
          senha_novo_user: '',
        }))
      }
    }
  }

  function feedback(msg, type) {
    if (type === 'success') {
      toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else if (type === 'error') {
      toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else if (type === 'warn') {
      toast.warn(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      toast.info('Internal server error, X-121_06', {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  async function requestCadastro() {
    const data = {
      EMP_ADICIONAIS,
      EMP_CNPJ,
      EMP_COD,
      EMP_ENDOR,
      EMP_FONE,
      EMP_NOME,
      EMP_PLANOS,
      EMP_VENDEDORES_URL,
      NABOO_URL,
      USR_LOGIN: usersData.login_novo_user,
      USR_EMAIL: usersData.email_novo_user,
      USR_PASS: usersData.senha_novo_user,
    }

    try {
      const data = await empresaOnboardingRepository.cadastrarEmpresa(data)

      console.log('CADASTRAR EMPRESA', data)
      sessionStorage.setItem('ASS_COD', EMP_COD)
      navigate('/onboarding/conclusao-cadastro')
    } catch (error) {
      console.log(error)
      feedback('Ops! Algo deu errado', 'error')
    }

    // await axios
    //   .post('https://kaminocrm.com.br:8000/empresa/cadastrarEmpresa', data)
    //   .then((res) => {
    //     console.log(res)
    //     sessionStorage.setItem('ASS_COD', EMP_COD)
    //     navigate('/onboarding/conclusao-cadastro')
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     feedback('Ops! Algo deu errado', 'error')
    //   })
  }

  async function cadastrarEmpresa() {
    setEmpresaData((prev) => ({
      ...prev,
      USR_LOGIN: usersData.login_novo_user,
      USR_EMAIL: usersData.email_novo_user,
      USR_PASS: usersData.senha_novo_user,
    }))

    requestCadastro()
  }

  return (
    <ContainerOnboard>
      <ToastContainer />

      <header className="onboarding-header animation_fadedown">
        <img src={logo} alt="logo_kamino" />
      </header>
      <div className="regusuario-body">
        <section className="regusuario-body__data">
          <div className="regusuario-body__header">
            <h1 className="title">Cadastro de Usuário</h1>
            <p className="subtitle" style={{ maxWidth: 'none' }}>
              Informe abaixo os dados de usuário para login na plataforma.
            </p>
            <p className="subtitle">Esse será o seu usuário para acesso!</p>
          </div>
          <form className="regusuario-body__form">
            <div className="form__inputs">
              <div className="form__row">
                <div className="form__column">
                  <label htmlFor="login_novo_user">Nome/Login</label>
                  <input
                    type="text"
                    name="login_novo_user"
                    id="login_novo_user"
                    value={usersData.login_novo_user}
                    placeholder="NOME"
                    onChange={(e) => handleChange(e)}
                  />
                  {erroWarning.login_novo_user !== '' && (
                    <span className="erro__warning">
                      {erroWarning.login_novo_user}
                    </span>
                  )}
                </div>
                <div className="form__column">
                  <label htmlFor="email_novo_user">email</label>
                  <input
                    type="email"
                    name="email_novo_user"
                    id="email_novo_user"
                    value={usersData.email_novo_user}
                    placeholder="E-MAIL"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="form__row">
                <div className="form__column">
                  <label htmlFor="senha_novo_user">Senha</label>
                  <input
                    type="password"
                    name="senha_novo_user"
                    id="senha_novo_user"
                    value={usersData.senha_novo_user}
                    placeholder="SENHA"
                    onChange={(e) => handleChange(e)}
                  />
                  {erroWarning.senha_novo_user !== '' && (
                    <span className="erro__warning">
                      {erroWarning.senha_novo_user}
                    </span>
                  )}
                </div>
                <div className="form__column">
                  <label htmlFor="senhaconf_novo_user">Confirme a Senha</label>
                  <input
                    type="password"
                    name="senhaconf_novo_user"
                    id="senhaconf_novo_user"
                    value={usersData.senhaconf_novo_user}
                    placeholder="SENHA"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
            <button
              className="btn__avançar"
              type="button"
              onClick={() => {
                cadastrarEmpresa()
              }}
              style={{ marginTop: '3rem' }}
              disabled={handleDisabled()}
            >
              Avançar
            </button>
          </form>
        </section>
        <section className="regusuario-body__ui" />
      </div>
    </ContainerOnboard>
  )
}

export default RegistroUsuario
