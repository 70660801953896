import {
  Avatar,
  BackgroundModal,
  CloseButton,
  Container,
  ContentModal,
  FormField,
  FormRow,
  FormStep,
  Header,
  Label,
  Title,
} from './styles'
import ImgClose from '../../../../Home/assets/icon-close.svg'
import ImgIconUser from '../../../../Home/assets/icon-usr-register-modal.svg'
import { parametrosRepository } from '../../../../../api/repositories/parametrosRepository'
import { useForm } from 'react-hook-form'
import { RiBook2Line } from 'react-icons/ri'
import { Alerts } from '../../../../../utils/alert'

export function ModalTarefa({
  setOpenModal,
  openModal,
  dataUser,
  alteracaoFeita,
}) {
  const { register, handleSubmit } = useForm()

  async function criarTarefa(nomeTipoTarefa) {
    try {
      await parametrosRepository.criarTarefa(nomeTipoTarefa)

      Alerts.SUCCESS('Tarefa criada com sucesso!')

      setOpenModal(false)
    } catch (error) {
      console.log(error.message)
    }
  }

  async function onSubmit(dataSubmiting) {
    const { nomeTipoTarefa } = dataSubmiting

    await criarTarefa(nomeTipoTarefa)
    alteracaoFeita(true)
  }

  return (
    <BackgroundModal open={openModal} onClose={() => setOpenModal(false)}>
      <ContentModal>
        <Container>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Header>
              <CloseButton onClick={() => setOpenModal(false)}>
                <img src={ImgClose} alt="Fechar modal" />
              </CloseButton>
              <div>
                <Title className={'SettingsModal__BlackColor'}>
                  NOVA TAREFA
                </Title>
              </div>
            </Header>
            <main>
              <FormStep>
                <FormRow>
                  <FormField>
                    <Label htmlFor="userName">Nome do tipo</Label>
                    <input
                      type="text"
                      name="userName"
                      id="userName"
                      placeholder="EX.: "
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^a-zA-Z0-9\s]/g,
                          '',
                        )
                      }}
                      {...register('nomeTipoTarefa')}
                    />
                  </FormField>
                </FormRow>
              </FormStep>
              <div className="save_button">
                <button type="submit">Salvar</button>
              </div>
            </main>
          </form>
        </Container>
      </ContentModal>
    </BackgroundModal>
  )
}
