import styled from 'styled-components'

export const FunilWrapper = styled.div`
  h3 {
    margin-bottom: 0.75rem;
  }
  .criar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.75rem;
  }
  .wrapper {
    display: flex;
    gap: 1.5rem;
  }

  .novo_funil {
    display: flex;
    flex-direction: column;
    gap: 5px;

    input {
      padding: 5px 12px;
      border-radius: 4px;
      border: 1px solid #ccc;
      font-family: -apple-system, BlinkMacSystemFont, sans-serif;
      :focus {
        border: 1px solid lightblue;
        outline: none;
      }
    }
    .novo_funil-footer {
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
    }
  }

  .novo_funil_duo {
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* width: 50%; */
    flex-basis: 50%;
    max-width: 50%;

    .input {
      width: 85%;
      padding: 7px 12px;
      border-radius: 4px;
      border: 1px solid #ccc;
      font-family: -apple-system, BlinkMacSystemFont, sans-serif;

      :focus {
        border: 1px solid lightblue;
        outline: none;
      }
    }

    .novo_funil-footer {
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
    }
  }

  .funis_existem {
    table {
      width: 100%;
      border-collapse: collapse;

      th {
        text-align: left;
      }

      td {
        padding: 1rem 0;
        border-bottom: 1px solid #ccc;
      }

      .col_status,
      .col_acoes {
        text-align: center;
      }

      .col_status {
        width: 20%;
      }
      .col_acoes {
        width: 15%;
        padding-right: 1.5rem;
      }
      .col_config {
        width: 13%;
        text-align: center;
        padding-left: 1rem;
      }
    }
  }
`
