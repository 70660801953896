import styled from 'styled-components'
import { COLORS } from '../../../../../../colors'

export const BackgroundResumo = styled.div`
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  height: 200vh;
  width: 100%;
  max-width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  z-index: 99;

  @media only screen and (max-width: 610px) {
    width: 300vw;
    height: 5000vh;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 10em 0 50em 0;
  }

  @media only screen and (max-width: 324px) {
    width: 300vw;
    height: 5000vh;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 20em 0 50em 0;
  }
  /* @media only screen and (max-width: 610px) {
    width: 100%;
    max-width: 200vw;
    height: 100em;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 2px;
  } */

  /* @media only screen and (max-width: 324px) {
    width: 50%;
    height: 100em;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 0;
  } */
`

export const BoxResumo = styled.div`
  background-color: ${COLORS.light};
  margin: 7% auto 0 auto;
  padding: 40px;
  width: 45%;
  max-height: fit-content;
  border-radius: 7px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 782px) {
    display: flex;
    width: 70%;
    max-height: fit-content;
  }

  #perdida {
    background-color: #b10d19;

    :disabled {
      opacity: 0.66;
      cursor: not-allowed;
    }
  }
  #vistoria {
    background-color: #572a8a;

    :disabled {
      opacity: 0.66;
      cursor: not-allowed;
    }
  }

  #pendente {
    background-color: #237bcf;

    :disabled {
      opacity: 0.66;
      cursor: not-allowed;
    }
  }

  #convertida {
    background-color: #00b065;

    :disabled {
      opacity: 0.66;
      cursor: not-allowed;
    }
  }
`

export const BoxResumoDiv = styled.div`
  ul {
    padding-inline-start: 0;
  }

  ul li {
    list-style-type: none;
    margin-bottom: 0.75rem;
  }

  .card_plano {
    padding: 25px;
    border: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;

    border-radius: 7px;
    margin-bottom: 0.4rem;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 0.75rem;
      color: green;
    }
  }
`

export const BoxResumoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 7px;

  ul {
    padding-inline-start: 0;
    /* background-color: lightpink; */
  }

  ul li {
    list-style-type: none;
    margin: 10px 0;
  }

  @media only screen and (max-width: 1036px) {
    display: block;
  }
`

export const ButtonEncerrar = styled.button`
  text-align: center;
  display: inline-block;
  padding: 0.5rem 0.75rem;
  background-color: #b10d19;
  border: none;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;

  :hover {
    cursor: pointer;
    background-color: #7b0b1a;
  }
`
