import React, { useState, useContext } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import {
  BackgroundModal,
  ModalBoxConfirmar,
  ModalHeader,
  InputsWrapper,
  BtnSubmit,
} from '../../Oportunidades/Modal/style/styledModal'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import { AuthContext } from '../../../../../context/auth'
import { ToastContainer, toast } from 'react-toastify'
import { userRepository } from '../../../../../api/repositories/userRepository'

function AlterarSenha() {
  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')
  const USR_ID = sessionStorage.getItem('USR_ID')

  const [senha, setSenha] = useState('')
  const [novaSenha, setNovaSenha] = useState('')
  const [verSenha, setVerSenha] = useState(false)
  const [verNovaSenha, setVerNovaSenha] = useState(false)

  const { USR_NOME } = useContext(AuthContext)

  const DADOS = {
    USR_ID,
    USR_NOME: USR_NOME,
    USR_LOGIN: LOGIN,
    ALTERAR_SENHA: true,
    USR_SENHA: senha,
  }

  async function handleAlterarSenha() {
    try {
      const data = await userRepository.updateUsuario(DADOS)

      console.log('UPDATE USUARIO', data)
      sessionStorage.setItem('ALTERAR_SENHA', false)
      window.location.reload()
    } catch (error) {
      console.log(error)
      toast.error('Ops, algo deu errado!', {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }

    // axios
    //   .post(`https://kaminocrm.com.br:5001/usuario/updateUsuario`, DADOS, {
    //     headers: {
    //       Authorization: `Bearer ${JWT_TOKEN}`,
    //       user_login: LOGIN,
    //       ass_cod: ASS_COD,
    //     },
    //   })
    //   .then((res) => {
    //     console.log(res)
    //     sessionStorage.setItem('ALTERAR_SENHA', false)
    //     window.location.reload()
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     toast.error('Ops, algo deu errado!', {
    //       position: 'top-right',
    //       autoClose: 2200,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     })
    //   })
  }

  return (
    <BackgroundModal>
      <ToastContainer />
      <ModalBoxConfirmar>
        <ModalHeader>
          <h2>Alterar senha de usuário</h2>
        </ModalHeader>

        <div className="modalBody">
          <p
            style={{ textAlign: 'center', margin: '0.75rem auto 1.7rem auto' }}
          >
            Por favor, altere sua senha inicial para prosseguir utilizando o
            sistema.
          </p>

          <InputsWrapper>
            <section>
              <label htmlFor="">Nova senha</label>
              <div style={{ display: 'block' }}>
                <input
                  type={verSenha ? 'text' : 'password'}
                  name="nova_senha"
                  id="nova_senha"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                />
                <button onClick={() => setVerSenha(!verSenha)}>
                  {verSenha ? (
                    <MdVisibilityOff size={17} />
                  ) : (
                    <MdVisibility size={17} />
                  )}
                </button>
              </div>
            </section>

            <section>
              <label htmlFor="">Confirmar nova senha</label>
              <div style={{ display: 'block' }}>
                <input
                  className={senha !== novaSenha ? 'senha-diferente' : ''}
                  type={verNovaSenha ? 'text' : 'password'}
                  name="confirmar_senha"
                  id="confirmar_senha"
                  value={novaSenha}
                  onChange={(e) => setNovaSenha(e.target.value)}
                />

                <button onClick={() => setVerNovaSenha(!verNovaSenha)}>
                  {verNovaSenha ? (
                    <MdVisibilityOff size={17} />
                  ) : (
                    <MdVisibility size={17} />
                  )}
                </button>
                {senha !== novaSenha ? (
                  <span
                    style={{
                      display: 'block',
                      fontSize: '12px',
                      color: '#D84040',
                    }}
                  >
                    As senhas não correspondem!
                  </span>
                ) : (
                  ''
                )}
              </div>
            </section>
          </InputsWrapper>
        </div>

        <div className="modalFooter" style={{ marginTop: '1.7rem' }}>
          <BtnSubmit
            disabled={senha !== novaSenha || senha.length == 0 ? true : false}
            onClick={() => {
              handleAlterarSenha()
            }}
          >
            Alterar senha
          </BtnSubmit>
        </div>
      </ModalBoxConfirmar>

      {/* <button onClick={() => navigate("/home/dashboard")}>
            sair(temp)
        </button> */}
    </BackgroundModal>
  )
}

export default AlterarSenha
