import { useEffect, useState } from 'react'
import ImgAdd from '../../../Home/assets/icon-add.svg'
import menuAjustesTable from '../../../Home/assets/menu-ajustes-table.svg'
import { FunilVendasContainer } from './styles'
import { parametrosRepository } from '../../../../api/repositories/parametrosRepository'
import { ModalFunil } from './ModalFunil/ModalFunil'
import { Dropdown } from 'react-bootstrap'
import {
  ButtonInsideWrapper,
  TableButton,
} from '../../../Home/HomeRoutes/Oportunidades/Data Table/styles/styledDataTable'
import editPencil from '../../../Home/assets/icon-edit-pencil.svg'
import archiveIcon from '../../../Home/assets/icon-arquivar.svg'
import { ModalArchive } from './ModalArchive/ModalArchive'
import { FaTasks } from 'react-icons/fa'
import { ModalSteps } from './ModalSteps/ModalSteps'
import { ImInfo } from 'react-icons/im'
import Tippy from '@tippyjs/react'

export function FunilVendas({ setLoading }) {
  const [filterResult, setFilterResult] = useState([])
  const [funis, setFunis] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [changeModal, setChangeModal] = useState(false)
  const [funilData, setFunilData] = useState(null)
  const [modalArchiveFunil, setModalArchiveFunil] = useState(false)
  const [opemModalConfig, setOpenModalConfig] = useState(false)

  useEffect(() => {
    setLoading(true)
    buscarFunis()
    setChangeModal(false)
  }, [changeModal])

  async function buscarFunis() {
    try {
      const { funis } = await parametrosRepository.listarFunis()
      setFunis(funis)
      setFilterResult(funis)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  async function handleChangeFilter(e) {
    const { value } = e.target
    setSearchInput(value)
    const filteredData = funis.filter((item) => {
      return Object.values(item)
        .join('')
        .toLowerCase()
        .includes(value.toLowerCase())
    })

    setFilterResult(filteredData)
  }

  function ButtonDropDownMenu({ funil }) {
    return (
      <Dropdown>
        <Dropdown.Toggle style={{ padding: '0', margin: '0', border: '0' }}>
          <img src={menuAjustesTable} alt="Menu ajustes" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.9375rem',
            }}
          >
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              disabled={!funil.FUN_ATIVO}
              onClick={() => {
                setFunilData(funil)
                setOpenModal(true)
              }}
            >
              <ButtonInsideWrapper>
                <img src={editPencil} alt="" />
                <span>Editar</span>
              </ButtonInsideWrapper>
            </TableButton>
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              onClick={() => {
                setModalArchiveFunil(true)
                setFunilData(funil)
              }}
            >
              <ButtonInsideWrapper>
                <img src={archiveIcon} alt="" />
                <span>{funil.FUN_ATIVO ? 'Arquivar' : 'Desarquivar'}</span>
              </ButtonInsideWrapper>
            </TableButton>
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              onClick={() => {
                setOpenModalConfig(true)
                setFunilData(funil)
              }}
            >
              <ButtonInsideWrapper>
                <FaTasks style={{ width: '17px' }} />
                <span>Gerenciar Etapas</span>
              </ButtonInsideWrapper>
            </TableButton>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <FunilVendasContainer>
      <>
        {openModal && (
          <ModalFunil
            openModal={openModal}
            setOpenModal={setOpenModal}
            changeModal={setChangeModal}
            funilData={funilData}
          />
        )}

        {opemModalConfig && (
          <ModalSteps
            openModal={opemModalConfig}
            setOpenModal={setOpenModalConfig}
            changeModal={setChangeModal}
            funilData={funilData}
            loading={setLoading}
          />
        )}

        {modalArchiveFunil && (
          <ModalArchive
            openModal={modalArchiveFunil}
            setOpenModal={setModalArchiveFunil}
            funilData={funilData}
            changeModal={setChangeModal}
          />
        )}

        <section>
          <header>
            <div>
              <div className="Funil_Header_Title">
                <span className="Funil_Header_Title">Funil</span>
                <img
                  src={ImgAdd}
                  onClick={() => {
                    setOpenModal(true)
                    setFunilData(null)
                  }}
                />
              </div>
              <div>
                <span className="Funil_Header_SubTitle">
                  Crie ou gerencie funis de vendas.
                </span>
              </div>
            </div>
            <div>
              <form onSubmit={(event) => event.preventDefault()}>
                <input
                  type="text"
                  name="searchValue"
                  id="searchValue"
                  value={searchInput}
                  onChange={handleChangeFilter}
                  placeholder="Pesquisar"
                  maxLength="25"
                />
              </form>
            </div>
          </header>
          <main>
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {filterResult.map((funil) => (
                  <tr key={funil.FUN_ID}>
                    <td>{funil.FUN_NOME}</td>
                    <td>
                      <div
                        className={`Funil__Status ${
                          funil.FUN_ATIVO
                            ? 'Funil__Status--active'
                            : 'Funil__Status--inactive'
                        }`}
                      >
                        {funil.FUN_ATIVO ? 'ATIVO' : 'ARQUIVADO'}
                      </div>
                    </td>
                    <td>
                      {funil.FUN_PADRAO ? (
                        <Tippy
                          content="O funil padrão não pode ser alterado"
                          delay="275"
                          placement="bottom"
                        >
                          <span>
                            <ImInfo size={18} />
                          </span>
                        </Tippy>
                      ) : (
                        <ButtonDropDownMenu funil={funil} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </main>
        </section>
      </>
    </FunilVendasContainer>
  )
}

;<Tippy
  content="O funil padrão não pode ser alterado"
  delay="275"
  placement="bottom"
>
  <span>
    <ImInfo size={19} />
  </span>
</Tippy>
