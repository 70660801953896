export const AutomacoesSvg = (props) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.953 14.172h5.89v1.039H6.649c-.625 0-.851.586-.46 1.055l1.898 2.312c.25.305.664.297.914 0l1.883-2.312c.375-.461.164-1.055-.445-1.055h-1.22v-1.04h5.884c1.218 0 1.96-.733 1.96-1.929V9.617c0-1.195-.742-1.93-1.96-1.93H9.219V6.603h5.883c1.218 0 1.96-.735 1.96-1.93V2.047c0-1.195-.742-1.93-1.96-1.93H1.953c-1.219 0-1.96.735-1.96 1.93v2.625c0 1.195.741 1.93 1.96 1.93h5.899v1.086H1.953c-1.219 0-1.96.734-1.96 1.93v2.624c0 1.196.741 1.93 1.96 1.93zm.133-9.008c-.406 0-.656-.25-.656-.664V2.219c0-.406.25-.664.656-.664h12.89c.399 0 .649.258.649.664V4.5c0 .414-.25.664-.648.664H2.086zm0 7.57c-.406 0-.656-.25-.656-.664V9.79c0-.407.25-.665.656-.665h12.89c.399 0 .649.258.649.664v2.281c0 .414-.25.664-.648.664H2.086z"
      fill="#353535"
      fillRule="nonzero"
    />
  </svg>
)

export const AutomacoesSvgSelected = (props) => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.117 15.273h5.774v.97H6.727c-.727 0-.961.632-.508 1.171l2.07 2.477c.274.328.727.32.992 0l2.063-2.477c.43-.523.203-1.172-.5-1.172h-1.18v-.969h5.758c1.305 0 2.133-.82 2.133-2.101V10.57c0-1.28-.828-2.093-2.133-2.093H9.656v-1h5.766c1.305 0 2.133-.82 2.133-2.102V2.773c0-1.28-.828-2.093-2.133-2.093H2.117C.82.68-.008 1.492-.008 2.773v2.602c0 1.281.828 2.102 2.125 2.102h5.774v1H2.117c-1.297 0-2.125.812-2.125 2.093v2.602c0 1.281.828 2.101 2.125 2.101z"
      fill="#0049C4"
      fillRule="nonzero"
    />
  </svg>
)
