import { useForm } from 'react-hook-form'
import {
  BackgroundModal,
  CloseButton,
  Container,
  ContentModal,
  FormField,
  FormRow,
  FormStep,
  Header,
  Label,
  Title,
} from './styles'
import ImgClose from '../../../../Home/assets/icon-close.svg'
import { parametrosRepository } from '../../../../../api/repositories/parametrosRepository'
import { FaBookmark, FaRegBookmark } from 'react-icons/fa'
import { Alerts } from '../../../../../utils/alert'

export function EditarOrigem({
  origemData,
  openModal,
  setOpenModal,
  alteracaoFeita,
}) {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      novoNomeOrigem: origemData.ORG_DESCRICAO,
    },
  })

  console.log(origemData)

  async function editarNomeTipoTarefa(novoNomeOrigem, ORG_ID) {
    try {
      await parametrosRepository.atualizarOrigem(novoNomeOrigem, ORG_ID)

      Alerts.SUCCESS('Origem atualizada com sucesso!')
    } catch (error) {
      console.log(error)
    }
  }

  async function onSubmit(dataSubmitting) {
    console.log(dataSubmitting)
    const { novoNomeOrigem } = dataSubmitting
    const { ORG_ID } = origemData

    await editarNomeTipoTarefa(novoNomeOrigem, ORG_ID)
    setOpenModal(false)
    alteracaoFeita(true)
  }

  return (
    <BackgroundModal open={openModal} onClose={() => setOpenModal(false)}>
      <ContentModal>
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Header>
              <CloseButton onClick={() => setOpenModal(false)}>
                <img src={ImgClose} alt="Fechar modal" />
              </CloseButton>
              <div>
                <Title className={'SettingsModal__BlackColor'}>
                  EDITAR ORIGEM
                </Title>
              </div>
            </Header>
            <main>
              <FormStep>
                <FormRow>
                  <FormField>
                    <Label htmlFor="userName">Renomear Origem</Label>
                    <input
                      type="text"
                      name="userName"
                      id="userName"
                      placeholder="EX.: "
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^a-zA-Z0-9\s]/g,
                          '',
                        )
                      }}
                      {...register('novoNomeOrigem')}
                    />
                  </FormField>
                </FormRow>
              </FormStep>
              <div className="save_button">
                <button type="submit">Salvar</button>
              </div>
            </main>
          </form>
        </Container>
      </ContentModal>
    </BackgroundModal>
  )
}
