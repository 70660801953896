import { apiBackground } from '../api'
import { ENDPOINTS } from '../endPoints'

export const backgroundRepository = {
  buscarBackground: async () => {
    try {
      const response = await apiBackground.get(
        ENDPOINTS.BACKGROUND.BUSCAR_BACKGROUND,
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}
