import { apiGeral } from '../api'
import { ENDPOINTS } from '../endPoints'

export const statusRepository = {
  verificarStatus: async () => {
    try {
      const response = await apiGeral.get(ENDPOINTS.STATUS.VERIFICAR_STATUS)

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}
