import styled from 'styled-components'
import { COLORS } from '../../../../../../colors'

export const TabStepContainer = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 3.75rem;

  box-sizing: border-box;

  background-color: #f5f5f5;

  padding: 30px 40px;

  border-radius: 6px 0px 0px 6px;

  header {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }

  h2 {
    font-size: 1.375rem;
    font-weight: bold;
  }

  span {
    font-size: 0.75rem;
    font-weight: 300;
  }
`

export const StepsContainer = styled.div`
  ul {
    display: flex;
    flex-direction: column;

    gap: 0.5rem;
  }
`

export const Step = styled.li`
  li {
    display: flex;
    align-items: center;

    padding: 0.5rem 1rem;
    border-radius: 8px;

    color: #c6c6c6;
    font-size: 1.1rem;
    font-weight: 500;
    cursor: default;
  }
`
