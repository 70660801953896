import styled from 'styled-components'
import { COLORS } from '../../../../../colors'
import { Dialog } from '@headlessui/react'

export const BackgroundModal = styled(Dialog)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  z-index: 100;
`

export const ModalBoxPlanos = styled.div`
  background-color: ${COLORS.light};
  margin: 7% auto 0 auto;
  padding: 25px;
  min-height: fit-content;
  width: 55%;
  max-height: fit-content;
  border-radius: 7px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1440px) {
    display: flex;
    width: 60%;
    max-height: fit-content;
  }
  @media only screen and (max-width: 1270px) {
    display: flex;
    width: 90%;
    max-height: fit-content;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #d7d7d7;

  h2 {
    align-items: flex-start;
    margin: 0;
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
`

export const SelectFiltroOpo = styled.select`
  width: 28%;
  padding: 7px 18px;
  border-radius: 4px;
  border: 1px solid ${COLORS.lightGray};
  margin-bottom: 10px;
  background-color: white;
  border: 1px solid #ccc;
  font-weight: bolder;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  color: #515054;
  -webkit-appearance: none;
  background: url(https://i.imgur.com/pz0fzEP.png) no-repeat right white;

  option {
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    color: #515054;
  }

  :focus {
    outline: none;
  }

  @media only screen and (max-width: 1120px) {
    width: 70%;
  }
  @media only screen and (max-width: 980px) {
    width: 99%;
  }
`

export const ButtonSalvarSenha = styled.button`
  /* text-align: center;
  display: inline-block;
  margin: 5px;
  padding: 7px 25px;
  background-color: ${COLORS.primary};
  border: none;
  color: white;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px; */

  text-align: center;
  display: inline-block;
  padding: 0.5rem 0.75rem;
  background-color: ${COLORS.primary};
  border: none;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;

  :hover {
    cursor: pointer;
    background-color: ${COLORS.primaryHover};
  }

  :disabled {
    background-color: rgba(0, 174, 239, 0.6);
    cursor: not-allowed;
  }
`

export const X = styled.a`
  background-color: transparent;
  padding: 0;
  border: none;
  color: rgb(73, 73, 73);

  :hover {
    cursor: pointer;
    color: rgb(14, 10, 10);
  }
`

export const Container = styled.div`
  width: auto;
  height: 13rem;
  max-height: 13rem;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 10px;
  margin: 1rem;
  position: relative;

  .open {
    animation: showUp 0.5s forwards;
  }

  @keyframes showUp {
    from {
      width: 3rem;
    }
    to {
      width: 40%;
    }
  }
`

export const Possibilidades = styled.div`
  padding: 10px;
  h3 {
    margin: 0;
  }
  ul li {
    margin-bottom: 0.5rem;
  }
`

export const StepWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  /* padding: 1rem; */
`

export const AddStep = styled.div`
  width: 3rem;
  height: 100%;
  flex-direction: column;
`

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 20rem;
  max-width: 20rem; */
  height: 100%;
  flex-basis: 12rem;
  flex-grow: 0;
  flex-shrink: 0;
`

export const StepProgress = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 15%;

  .add__button {
    position: absolute;
    z-index: 3;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: grid;
    place-content: center;
    background-color: #19ca95;
    border: none;
    color: #fff;
    :hover {
      background-color: #0f9e8a;
      cursor: pointer;
    }
  }

  .arrows {
    position: absolute;
    z-index: 4;
    width: 30%;
    display: flex;
    top: 29px;

    .able {
      padding: 1px;
      display: flex;
      align-items: center;
      background-color: ${COLORS.primary};
      border: 2px solid #fff;
      color: #fff;
      border-radius: 10px;

      :hover {
        background-color: ${COLORS.primaryHover};
      }
    }

    .not_able {
      padding: 1px;
      display: flex;
      align-items: center;
      background-color: transparent;
      border: 2px solid transparent;
      color: transparent;
      border-radius: 10px;
    }
  }

  .ball {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;

    position: absolute;
    z-index: 3;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: grid;
    place-content: center;
    background-color: skyblue;

    /* ::before {
      content: "";
      width: 20px;
      height: 20px;
      border-radius: 50px;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background-color: #fff;
    }

    :checked::before {
      transform: scale(1);
    } */
  }

  .line {
    height: 3px;
    width: 100%;
    background-color: skyblue;
    position: relative;
  }
  .line_button {
    height: 3px;
    width: 5px;
    background-color: skyblue;
    position: relative;
  }
`

export const StepBox = styled.div`
  width: 80%;
  padding: 1rem 4rem 0 0.35rem;

  .titulo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    p {
      display: inline;
      font-weight: 600;
      text-transform: capitalize;
      margin: 0;
    }
  }

  .acoes {
    width: 100%;
    .editar {
      display: flex;
      align-items: center;
      gap: 0.3rem;
      font-weight: 600;
      background-color: #dee2e6;
      font-family: -apple-system, BlinkMacSystemFont, sans-serif;
      border: none;
      padding: 0.3rem 0.4rem;
      border-radius: 0.4rem;

      :disabled {
        opacity: 0.999;
        cursor: not-allowed;
      }

      /* :hover {
        background-color: #b4bdcb;
      } */
    }

    .btn_excluir {
      display: flex;
      align-items: center;
      background-color: #ec707f;
      color: #fff;
      border: none;
      padding: 0.3rem 0.4rem;
      text-align: center;
      border-radius: 0.4rem;

      :hover {
        background-color: #a83667;
      }
    }
  }

  input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    width: 85%;
    :focus {
      outline: none;
    }
  }

  .btn_salvar {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    font-weight: 600;
    background-color: #18aa8a;
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    border: none;
    padding: 0.4rem 0.5rem;
    border-radius: 0.4rem;
    :hover {
      background-color: #0b8e7f;
    }
  }

  .adicionar {
    padding: 0.5rem;
    background-color: #a6b9d0;
    border: none;
    border-radius: 4px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 600;
    color: #fff;

    :hover {
      background-color: #7993ba;
    }
  }

  .acoes {
    display: flex;
    gap: 0.5rem;
  }

  .poss {
    font-size: 12px;
    font-weight: 600;
    color: ${COLORS.primary};
    text-decoration: underline;

    :hover {
      cursor: pointer;
      color: ${COLORS.primaryHover};
    }
  }
`
