import React, { useContext, useState } from 'react'
import Header from '../../components/Header/Header'
import AjustesSidebar from './AjustesSidebar/AjustesSidebar'
import { SiteContext } from '../../context/SiteContext'
import './styles.css'
import Usuarios from './Usuarios/Usuarios'
import Loading from '../Loading/Loading'

function Ajustes() {
  const { siteState } = useContext(SiteContext)
  const { settingsTab } = siteState
  const [loading, setLoading] = useState(false)
  return (
    <>
      {loading && <Loading />}
      <Header />
      <div className="Settings__Container">
        <AjustesSidebar />
        {settingsTab === 'usuarios' && <Usuarios setLoading={setLoading} />}
      </div>
    </>
  )
}

export default Ajustes
