import styled from 'styled-components'

export const VistoriasContainer = styled.div`
  section {
    display: flex;
    flex-direction: column;
    gap: 3.125rem;

    padding: 3rem 3rem 0 3rem;

    > header {
      display: grid;
      grid-template-columns: 4fr 2fr;
      justify-content: space-between;

      img {
        cursor: pointer;
      }

      .SettingsUsers_Header_Title {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        font-size: 1.875rem;
        font-weight: bold;
      }

      .SettingsUsers_Header_SubTitle {
        line-height: 2rem;
        font-size: 1rem;
      }
    }

    main {
      .vistoria__options {
        display: flex;
        gap: 3.125rem;
        align-items: center;
        justify-content: center;
      }
    }
  }
`
