import { Dialog } from '@headlessui/react'
import styled from 'styled-components'
import iconArrowSelect from '../../../../../Home/assets/icon-arrow-select.svg'

export const BackgroundModal = styled(Dialog)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  z-index: 100;
`

export const ContentModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  overflow: hidden;
  outline: none;
  width: 528px;
  margin: 0 auto;
`

export const Container = styled.div`
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 320px;
  margin: 0 auto;

  .save_button {
    display: flex;
    justify-content: center;
    padding-top: 2rem;

    button {
      width: 7.5rem;
      height: 1.5625rem;
      margin-top: 1.25rem;
      border: none;
      border-radius: 5px;
      background-color: #00b065;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 600;
    }
  }
`
export const ModalAlertContainer = styled.div`
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 auto;
  height: 360px;
  box-sizing: border-box;

  h1 {
  }
`
