import styled from 'styled-components'

export const AutomacaoRulesContainer = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 40px;

  h3 {
    margin: 0 0 2rem;
  }

  h4 {
    margin: 2rem 0;
  }
`

export const AutomacaoRulesContent = styled.div``
