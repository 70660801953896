import React, { useContext, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { FaCog } from 'react-icons/fa'
import { FiLogOut } from 'react-icons/fi'
import { AiOutlineUser, AiOutlineInfoCircle } from 'react-icons/ai'
import { AuthContext } from '../../context/auth'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import Header from '../../components/Header/Header'

import ConfigBar from '../../components/ConfigBar/ConfigBar'

import {
  TopNavbar,
  Notificacao,
  Configuracao,
  LogOut,
  BottomNavbar,
  OpenLinksButton,
  ResponsiveNav,
} from './style/styledHomePage'

import logoIMG from './assets/kamino_logo.svg'

const HomePage = () => {
  const { logout, PER_ID, versaoSistema } = useContext(AuthContext)
  const handleLogout = () => {
    logout()
  }

  const LOGIN = sessionStorage.getItem('LOGIN')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  const [extenderNav, setExtenderNav] = useState(false)

  const [config, setConfig] = useState(false)

  return (
    <div className="containerHome">
      <Header />
      {/* <TopNavbar>
        <h5 style={{ color: "white", marginLeft: "10px" }}>
          <span style={{ marginRight: "5px" }}> <AiOutlineUser size={15} /> </span>
          Usuário: <span style={{ textTransform: "uppercase" }}> <span style={{ marginLeft: "5px" }}>{LOGIN}</span></span>
          <span style={{ margin: "0 15px", fontSize: "7px", fontWeight: "500" }}>|</span>
          <span>{ASS_COD.toUpperCase()}</span>
          <span style={{ margin: "0 15px", fontSize: "7px", fontWeight: "500" }}>|</span>
          <span style={{ marginRight: "5px" }}><AiOutlineInfoCircle size={15} /></span>  Versão: {versaoSistema}
        </h5>
        <ul>
          {PER_ID == 1 ? (<li>
            <Tippy content="Configurações" delay={275}>
              <Configuracao onClick={() => setConfig(!config)}>
                <FaCog size={16} />
              </Configuracao>
            </Tippy>
          </li>) : null}
          <li>
            <Tippy content="Sair" delay={275}>
              <LogOut onClick={handleLogout}>
                <FiLogOut size={16} />
              </LogOut>
            </Tippy>
          </li>
        </ul>
      </TopNavbar>


      {config ? <ConfigBar /> : null}


      <BottomNavbar>
        <span>
          <img src={logoIMG} alt="logo-img" id="img_HOME" />
        </span>

        <ul>
          <li>
            <Link to="dashboard">Início</Link>
          </li>
          <li>
            <Link to="oportunidades">Oportunidades</Link>
          </li>


          <li>
            <Link to="perfil">Perfil</Link>
          </li>
        </ul>
        <OpenLinksButton onClick={() => setExtenderNav(!extenderNav)}>
          {extenderNav ? <> &#10005; </> : <> &#8801; </>}{" "}
        </OpenLinksButton>
      </BottomNavbar>

      {extenderNav && (
        <ResponsiveNav>
          <ul>
            <li>
              <Link to="dashboard" onClick={() => setExtenderNav(!extenderNav)}>Início</Link>
            </li>

            <li>
              <Link to="oportunidades" onClick={() => setExtenderNav(!extenderNav)}>Oportunidades</Link>
            </li>


            <li>
              <Link to="perfil" onClick={() => setExtenderNav(!extenderNav)}>Perfil</Link>
            </li>
          </ul>
        </ResponsiveNav>
      )} */}
      <Outlet />
    </div>
  )
}

export default HomePage
