import axios from 'axios'

const setupInterceptors = (api) => {
  api.interceptors.request.use(
    (config) => {
      const userLogin = sessionStorage.getItem('LOGIN')
      const jwtToken = sessionStorage.getItem('JWT')
      const assCod = sessionStorage.getItem('ASS_COD')

      if (jwtToken) {
        config.headers.Authorization = `Bearer ${jwtToken}`
      }

      if (userLogin) {
        config.headers.user_login = userLogin
      }

      if (assCod) {
        config.headers.ass_cod = assCod
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )
}

export const createAPI = (port) => {
  const api = axios.create({
    baseURL: `https://kaminocrm.com.br:${port}`,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  })

  setupInterceptors(api)

  return api
}

export const apiGeral = createAPI(7778)
export const apiUser = createAPI(5001)
export const apiConsultaPlaca = createAPI(7777)
export const apiConsultaFipe = createAPI(8999)
export const apiPDF = createAPI(1000)
export const apiEmpresa = createAPI(4021)
export const apiBackground = createAPI(2051)
export const apiEmpresaOnboarding = createAPI(8000)
