import { apiGeral } from '../api'
import { ENDPOINTS } from '../endPoints'

export const veiculoRepository = {
  buscarTiposVeiculos: async () => {
    try {
      const response = await apiGeral.get(
        ENDPOINTS.VEICULOS.BUSCAR_TIPOS_VEICULOS,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  criarImplementoVeiculo: async (implementos) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.VEICULOS.CRIAR_IMPLEMENTO_VEICULO,
        implementos,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  criarVeiculo: async (DADOS) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.VEICULOS.CADASTRAR_VEICULO,
        DADOS,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}
