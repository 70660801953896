import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { IoMdClose } from 'react-icons/io'
import { BiCheckbox, BiSolidCheckboxChecked } from 'react-icons/bi'
import {
  BackgroundModal,
  ModalBoxPlaca,
  BtnSubmit,
  Select,
  ModalHeader,
  CloseLink,
  BtnVoltar,
  Input,
  LinkManual,
  BotaoZeroKM,
} from './style/styledModal'
import Loading from '../../../../Loading/Loading'
import { useNavigate } from 'react-router-dom'
import { CadastroVeiculo } from '../../../../../context/CadastroVEIContext'

import './style/ModalStyle.css'
import { handleClose } from '../../../../../services/handleClose'
import { fipeRepository } from '../../../../../api/repositories/fipeRepository'
import { planosRepository } from '../../../../../api/repositories/planosRepository'
import { veiculoRepository } from '../../../../../api/repositories/veiculosRepository'

const ModalPlaca = () => {
  const {
    setVEI_MARCA,
    setVEI_MODELO,
    setVEI_ANO,
    zeroKM,
    setZeroKM,
    setNoFipe,
    setImplementos,
  } = useContext(CadastroVeiculo)

  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')
  const VEN_ID = sessionStorage.getItem('VEN_ID')
  const CLI_ID = sessionStorage.getItem('CLI_ID')

  const navigate = useNavigate()

  const MySwal = withReactContent(Swal)

  const [carro, setCarro] = useState('')

  const [info, setInfo] = useState([])
  const [fipe, setFipe] = useState([])

  //RENDERIZAÇÃO CONDICIONAL

  const [selectVisivel, setSelectVisivel] = useState(undefined)
  const [erroVeiculo, setErroVeiculo] = useState(false)

  const [loading, setLoading] = useState(Boolean)

  const [placa, setPlaca] = useState('')

  //BUSCAR PLACA
  const getApi = async (placa) => {
    try {
      const data = await fipeRepository.consultarPlaca(placa)

      if (data.FIPE.length > 0) {
        setInfo(data)
        setCarro(JSON.stringify(data.FIPE[0]))
        setFipe(data.FIPE)
        setLoading(false)
        setSelectVisivel(true)
      } else {
        setLoading(false)
        setSelectVisivel(false)
        navigate('/home/cadastro-placa-fipeFalse')
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      setSelectVisivel(false)
      navigate('/home/cadastro-placa-fipeFalse')
    }

    // axios
    //   .get(`https://kaminocrm.com.br:7777/fipe/${placa}`)
    //   .then((res) => {
    //     if (res.data.FIPE.length > 0) {
    //       setInfo(res.data)
    //       setCarro(JSON.stringify(res.data.FIPE[0]))
    //       setFipe(res.data.FIPE)
    //       setLoading(false)
    //       setSelectVisivel(true)
    //     } else {
    //       setLoading(false)
    //       setSelectVisivel(false)
    //       navigate('/home/cadastro-placa-fipeFalse')
    //     }
    //   })

    //   .catch((err) => {
    //     console.log(err)
    //     setLoading(false)
    //     setSelectVisivel(false)
    //     navigate('/home/cadastro-placa-fipeFalse')
    //   })
  }

  // API PLACAS (SELEÇÃO AUTOMATICA)
  const verificarPlaca = (placa) => {
    setPlaca(placa)
    getApi(placa)
    setLoading(true)
  }

  if (info.SEGMENTO === 'CARRO') {
    info.SEGMENTO = info.SEGMENTO + 'S'
  }
  if (info.SEGMENTO === 'MOTO') {
    info.SEGMENTO = info.SEGMENTO + 'S'
  }

  const enviarDadosVeiculos = async () => {
    const carroJson = JSON.parse(carro)

    sessionStorage.setItem('VEI_PLACA', info.PLACA)
    sessionStorage.setItem('VEI_VALOR_FIPE', carroJson.Valor)
    sessionStorage.setItem('VEI_SEGMENTO', info.SEGMENTO)

    const DADOS = {
      VEI_PLACA: info.PLACA,
      VEI_CHASSI: info.CHASSI,
      VEI_MARCA: info.MARCA,
      VEI_CODIGO_MARCA: info.CODIGO_MARCA,
      VEI_MODELO: carroJson.Modelo,
      VEI_ANO_MODELO: `${carroJson.AnoModelo}`,
      VEI_CODIGO_ANO_MODELO: info.CODIGO_ANO_MODELO,
      VEI_CODIGO_MODELO: carroJson.CodigoModelo,
      VEI_ANO_FAB: info.ANOFABRICACAO,
      VEI_COD_TABELA_FIPE: carroJson.CodigoFipe,
      VEI_COD_FIPE: `${info.CODIGO_REFERENCIA_FIPE}`,
      VEI_VALOR_FIPE: carroJson.Valor.replace('R$', ''),
      VEI_COR: info.COR,
      VEI_TIPO: info.SEGMENTO,
      VEI_UF: info.UF,
      VEI_CIDADE: info.CIDADE,
      VEN_ID: VEN_ID,
      CLI_ID: CLI_ID,
    }

    try {
      const data = await veiculoRepository.criarVeiculo(DADOS)

      sessionStorage.setItem('VEI_ID', data.DADOS.VEI_ID)
      setVEI_MARCA(info.MARCA)
      setVEI_MODELO(carroJson.Modelo)
      setVEI_ANO(carroJson.AnoModelo)

      if (info.SEGMENTO === 'CAMINHOES') {
        navigate('/home/cadastro-implementos')
      } else {
        navigate('/home/planos-veiculo')
      }
    } catch (error) {
      console.error(error)

      // Verifique se error.response está presente
      if (error.response) {
        const { data } = error.response
        const erroAPI = data.message

        if (
          erroAPI ===
          `error: duplicate key value violates unique constraint \"VEICULOS_VEI_PLACA_key\"`
        ) {
          setErroVeiculo(true)
        }
      } else {
        console.error('Erro sem resposta do servidor:', error.message)
      }
    }
    // axios
    //   .post('https://kaminocrm.com.br:7778/veiculos/cadastrarVeiculo', DADOS, {
    //     headers: {
    //       Authorization: `Bearer ${JWT_TOKEN}`,
    //       user_login: LOGIN,
    //       ass_cod: ASS_COD,
    //     },
    //   })
    //   .then((res) => {
    //     sessionStorage.setItem('VEI_ID', res.data.DADOS.VEI_ID)
    //     setVEI_MARCA(info.MARCA)
    //     setVEI_MODELO(carroJson.Modelo)
    //     setVEI_ANO(carroJson.AnoModelo)
    //     console.log(DADOS)
    //     if (info.SEGMENTO === 'CAMINHOES') {
    //       navigate('/home/cadastro-implementos')
    //     } else {
    //       navigate('/home/planos-veiculo')
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     const { data } = err.response
    //     let erroAPI = data.message
    //     if (
    //       erroAPI ===
    //       `error: duplicate key value violates unique constraint \"VEICULOS_VEI_PLACA_key\"`
    //     ) {
    //       setErroVeiculo(true)
    //     }
    //   })
  }

  const buscarPlanosVeiculo = async (segmento, valor) => {
    const carroJson = JSON.parse(carro)

    if (info.SEGMENTO === 'CARRO') {
      info.SEGMENTO = 'CARROS'
    }
    if (info.SEGMENTO === 'MOTO') {
      info.SEGMENTO = 'MOTOS'
    }
    if (info.SEGMENTO === 'CAMINHAO') {
      info.SEGMENTO = 'CAMINHOES'
    }

    segmento = info.SEGMENTO
    valor = carroJson.Valor.replace('R$', '')

    const buscarPlanosSegmento = async () => {
      try {
        const data = await planosRepository.buscarPlanos(
          segmento,
          valor,
          VEN_ID,
        )

        console.log('BUSCANDO PLANOS SEGMENTO', data)

        if (data.length <= 0) {
          MySwal.fire({
            title: 'Atenção!',
            text: 'Não há planos disponíveis para esse veículo.',
            icon: 'warning',
            confirmButtonText: 'Ok',
          })
        } else {
          enviarDadosVeiculos()
        }
      } catch (error) {
        console.log(error)
      }
    }

    buscarPlanosSegmento()

    // await axios
    //   .get(
    //     `https://kaminocrm.com.br:7778/planos/buscarPlanos?segmento=${segmento}&preco=${valor}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${JWT_TOKEN}`,
    //         user_login: LOGIN,
    //         ass_cod: ASS_COD,
    //         ven_id: VEN_ID,
    //       },
    //     },
    //   )
    //   .then((res) => {
    //     if (res.data.length <= 0) {
    //       MySwal.fire({
    //         title: 'Atenção!',
    //         text: 'Não há planos disponíveis para esse veículo.',
    //         icon: 'warning',
    //         confirmButtonText: 'Ok',
    //       })
    //     } else {
    //       enviarDadosVeiculos()
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
  }

  function handleZeroKM() {
    setNoFipe(false)
    setZeroKM(!zeroKM)
    setNoFipe(false)
    if (!zeroKM) {
      setLoading(true)
      setTimeout(() => {
        navigate('/home/cadastro-placa-fipeFalse')
      }, '1000')
    }
  }

  function handleNoFipe(ev) {
    setZeroKM(false)
    setNoFipe(ev)
  }

  return (
    <>
      {loading ? <Loading /> : selectVisivel}

      <BackgroundModal>
        <ModalBoxPlaca>
          <ModalHeader>
            <h2>Dados do veículo</h2>

            <CloseLink
              to="/home/oportunidades"
              onClick={() => {
                setImplementos([])
                handleClose()
              }}
            >
              <IoMdClose size={17} />
            </CloseLink>
          </ModalHeader>

          <div
            className="modalBody"
            style={{
              textAlign: 'center',
            }}
          >
            <div className="label-select">
              <label htmlFor="placa">Informe a placa do seu veículo:</label>
              <BotaoZeroKM onClick={() => handleZeroKM()}>
                {!zeroKM ? (
                  <BiCheckbox color="#4d5561" size={19} />
                ) : (
                  <BiSolidCheckboxChecked color="#1768e4" size={19} />
                )}
                Zero KM
              </BotaoZeroKM>
            </div>
            <Input
              type="text"
              name="placa"
              id="placa"
              value={placa}
              onChange={(e) => {
                setPlaca(e.target.value)

                if (e.target.value.length === 7) {
                  verificarPlaca(e.target.value)
                  sessionStorage.setItem('PLACA', e.target.value)
                }
              }}
              maxLength={7}
              style={{
                textAlign: 'center',
                display: 'inline-block',
                textTransform: 'uppercase',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '.5rem',
              }}
            >
              <LinkManual
                to="/home/cadastro-placa-fipeFalse"
                onClick={() => handleNoFipe(true)}
              >
                Veículo sem FIPE
              </LinkManual>
              <span style={{ color: '#1768e4', fontSize: '10px' }}>|</span>
              <LinkManual
                to="/home/cadastro-placa-fipeFalse"
                onClick={() => handleNoFipe(false)}
              >
                Informar dados FIPE manualmente
              </LinkManual>
            </div>

            {/* info */}
            {selectVisivel && (
              <>
                <div
                  style={{
                    margin: '25px 0',
                  }}
                >
                  <p>
                    Encontramos o modelo <strong>{info.MODELO}</strong> do ano{' '}
                    <strong>{info.ANOMODELO}</strong>.
                  </p>
                </div>

                <div className="label-select">
                  <label>Selecione o modelo FIPE do seu veículo abaixo:</label>
                </div>

                <form>
                  <Select
                    onChange={(e) => {
                      setCarro(e.target.value)
                    }}
                  >
                    <option value="null">Selecione...</option>
                    {fipe.map((carroFIPE) => {
                      return (
                        <option
                          key={carroFIPE.CodigoFipe}
                          value={JSON.stringify(carroFIPE)}
                        >
                          {carroFIPE.Modelo} | {carroFIPE.Valor}
                        </option>
                      )
                    })}
                  </Select>

                  {erroVeiculo && (
                    <span
                      style={{
                        color: 'red',
                        display: 'block',
                        marginTop: '5px',
                        marginBottom: '5px',
                        fontSize: '12px',
                        fontWeight: 'bolder',
                      }}
                    >
                      Veículo já cadastrado!
                    </span>
                  )}
                  <div
                    className="modalFooter"
                    style={{
                      marginTop: '40px',
                    }}
                  >
                    <BtnVoltar
                      type="button"
                      style={{ margin: '0 20px' }}
                      onClick={() =>
                        navigate('/home/oportunidades/cadastro-cliente')
                      }
                    >
                      Voltar
                    </BtnVoltar>

                    <BtnSubmit
                      type="button"
                      onClick={() => buscarPlanosVeiculo()}
                    >
                      Avançar
                    </BtnSubmit>
                  </div>
                </form>
              </>
            )}
          </div>
        </ModalBoxPlaca>
      </BackgroundModal>
    </>
  )
}

export default ModalPlaca
