import { apiGeral } from '../api'
import { ENDPOINTS } from '../endPoints'

export const endorRepository = {
  listarVistoriadores: async () => {
    try {
      const response = apiGeral.get(ENDPOINTS.ENDOR.LISTAR_VISTORIADORES)

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}
