import { apiGeral } from '../api'
import { ENDPOINTS } from '../endPoints'

export const parametrosRepository = {
  buscarVendedores: async () => {
    try {
      const response = await apiGeral.get(
        ENDPOINTS.PARAMETROS.LISTAR_VENDEDORES,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  listarOrigens: async () => {
    try {
      const response = await apiGeral.get(ENDPOINTS.PARAMETROS.LISTAR_ORIGENS)

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  listarVistorias: async () => {
    try {
      const response = await apiGeral.get(
        ENDPOINTS.PARAMETROS.CONFIGURAR_VISTORIA,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  listarTarefas: async () => {
    try {
      const response = await apiGeral.get(ENDPOINTS.PARAMETROS.LISTAR_TAREFAS)

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  listarTabelaCotaParticipacao: async () => {
    try {
      const response = await apiGeral.get(
        ENDPOINTS.PARAMETROS.LISTAR_TABELA_COTA_PARTICIPACAO,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  buscarParametros: async () => {
    try {
      const response = await apiGeral.get(
        ENDPOINTS.PARAMETROS.BUSCAR_PARAMETROS,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  buscarImagensSistema: async () => {
    try {
      const response = await apiGeral.get(
        ENDPOINTS.PARAMETROS.BUSCAR_IMAGENS_SISTEMA,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  listarAutomacao: async () => {
    try {
      const response = await apiGeral.get(ENDPOINTS.PARAMETROS.LISTAR_AUTOMACAO)

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  listarMensagemTemplates: async () => {
    try {
      const response = await apiGeral.get(
        ENDPOINTS.PARAMETROS.LISTAR_MENSAGEM_TEMPLATES,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  listarFunis: async () => {
    try {
      const response = await apiGeral.get(ENDPOINTS.PARAMETROS.LISTAR_FUNIS)

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  listarTabelasFiliacao: async () => {
    try {
      const response = await apiGeral.get(
        ENDPOINTS.PARAMETROS.LISTAR_TABELAS_FILIACAO,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  buscarTiposMensagens: async () => {
    try {
      const response = await apiGeral.get(
        ENDPOINTS.PARAMETROS.BUSCAR_TIPOS_MENSAGENS,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  buscarTagsTemplate: async () => {
    try {
      const response = await apiGeral.get(
        ENDPOINTS.PARAMETROS.BUSCAR_TAGS_TEMPLATE,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  listarTabelaMensalidade: async () => {
    try {
      const response = await apiGeral.get(
        ENDPOINTS.PARAMETROS.LISTAR_TABELA_MENSALIDADE,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  buscarTabelaMensalidade: async (menId) => {
    try {
      const response = await apiGeral.get(
        `${ENDPOINTS.PARAMETROS.BUSCAR_TABELA_MENSALIDADE}?men_id=${menId}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  buscarDadosTabelaFiliacao: async (filId) => {
    try {
      const response = await apiGeral.get(
        `${ENDPOINTS.PARAMETROS.BUSCAR_DADOS_TABELA_FILIACAO}?fil_id=${filId}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  buscarTabelaCotaParticipacao: async (cotId) => {
    try {
      const response = await apiGeral.get(
        `${ENDPOINTS.PARAMETROS.BUSCAR_TABELA_COTA_PARTICIPACAO}?cot_id=${cotId}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  buscarDadosFunil: async (FUN_ID) => {
    try {
      const response = await apiGeral.get(
        `${ENDPOINTS.PARAMETROS.BUSCAR_DADOS_FUNIL}/${FUN_ID}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  atualizarParametros: async (DADOS_VENCIMENTO) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.PARAMETROS.ATUALIZAR_PARAMETROS,
        DADOS_VENCIMENTO,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  configurarCapaAssociacao: async (capaEnviada, checkboxCapa, progressBar) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.PARAMETROS.CONFIGURAR_CAPA_ASSOCIACAO,
        {
          BASE_64: capaEnviada,
          ATIVO: checkboxCapa,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          onUploadProgress: (event) => {
            if (event.lengthComputable) {
              const percentCompleted = Math.round(
                (event.loaded * 100) / event.total,
              )
              progressBar(percentCompleted)
            }
          },
        },
      )

      return response.data
    } catch (error) {
      console.log('Erro ao fazer upload:', error)
      throw error
    }
  },

  configurarContraCapaAssociacao: async (
    contraCapaEnviada,
    checkboxContraCapa,
    progressBar,
  ) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.PARAMETROS.CONFIGURAR_CONTRA_CAPA_ASSOCIACAO,
        {
          BASE_64: contraCapaEnviada,
          ATIVO: checkboxContraCapa,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          onUploadProgress: (event) => {
            if (event.lengthComputable) {
              const percentCompleted = Math.round(
                (event.loaded * 100) / event.total,
              )
              progressBar(percentCompleted)
            }
          },
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  arquivarAutomacoes: async (AUT_ID) => {
    try {
      const response = await apiGeral.post(
        `${ENDPOINTS.PARAMETROS.ARQUIVAR_AUTOMACOES}?aut_id=${AUT_ID}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  editarOuCriarAutomacao: async (automacao) => {
    const requestUrl =
      automacao.AUT_ID !== ''
        ? `${ENDPOINTS.PARAMETROS.EDITAR_AUTOMACAO}`
        : `${ENDPOINTS.PARAMETROS.CRIAR_AUTOMACAO}`

    try {
      const response = await apiGeral.post(requestUrl, automacao)

      return response.data
    } catch (error) {
      return {
        error: true,
      }
    }
  },

  atualizarOrdemFunil: async (FUN_ID, dataSend) => {
    try {
      const response = await apiGeral.post(
        `${ENDPOINTS.PARAMETROS.ATUALIZAR_ORDEM_FUNIL}/${FUN_ID}`,
        dataSend,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  arquivarOuDesarquivarFunil: async (FUN_ID) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.PARAMETROS.ARQUIVAR_OU_DESARQUIVAR_FUNIL,
        { FUN_ID: FUN_ID },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  atualizarFunil: async (novoNomeFunil, FUN_ID, vendedoresEscolhidos) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.PARAMETROS.ATUALIZAR_FUNIL,
        {
          FUN_NOME: novoNomeFunil,
          FUN_ID: FUN_ID,
          VEN_IDS: vendedoresEscolhidos,
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  criarFunil: async (nomeFunil, vendedoresEscolhidos) => {
    try {
      const response = await apiGeral.post(ENDPOINTS.PARAMETROS.CRIAR_FUNIL, {
        FUN_NOME: nomeFunil,
        VEN_IDS: vendedoresEscolhidos,
      })

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  arquivarOuDesarquivarOrigem: async (ORG_ID) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.PARAMETROS.ARQUIVAR_OU_DESARQUIVAR_ORIGEM,
        {
          ORG_ID: ORG_ID,
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  atualizarOrigem: async (novoNomeOrigem, ORG_ID) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.PARAMETROS.ATUALIZAR_ORIGEM,
        {
          ORG_DESCRICAO: novoNomeOrigem,
          ORG_ID: ORG_ID,
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  criarOrigem: async (nomeOrigem) => {
    try {
      const response = await apiGeral.post(ENDPOINTS.PARAMETROS.CRIAR_ORIGEM, {
        ORG_DESCRICAO: nomeOrigem,
      })

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  arquivarOuDesarquivarCotaParticipacao: async (cot_id) => {
    try {
      const response = await apiGeral.post(
        `${ENDPOINTS.PARAMETROS.ARQUIVAR_E_DESARQUIVAR_COTA_PARTICIPACAO}/${cot_id}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  criarTabelaCotaParticipacao: async (cotaDados) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.PARAMETROS.CRIAR_TABELA_COTA_PARTICIPACAO,
        cotaDados,
      )

      return response.data
    } catch (error) {
      return {
        error: true,
        message: error.response?.data?.message || 'Erro ao cadastrar cotação!',
        status: error.response?.status,
      }
    }
  },

  editarTabelaCotaParticipacao: async (dataEdit) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.PARAMETROS.EDITAR_TABELA_COTA_PARTICIPACAO,
        dataEdit,
      )

      return response.data
    } catch (error) {
      return {
        error: true,
        message: error.response?.data?.message || 'Erro ao editar cotação!',
        status: error.response?.status,
      }
    }
  },

  arquivarOuDesarquivarTabelaFiliacao: async (fil_id) => {
    try {
      const response = await apiGeral.post(
        `${ENDPOINTS.PARAMETROS.ARQUIVAR_E_DESARQUIVAR_TABELA_FILIACAO}/${fil_id}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  criarTabelaFiliacao: async (filiacaoDados) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.PARAMETROS.CRIAR_TABELA_FILIACAO,
        filiacaoDados,
      )

      return response.data
    } catch (error) {
      return {
        error: true,
        message: error.response?.data?.message || 'Erro ao criar filiação!',
        status: error.response?.status,
      }
    }
  },

  editarTabelaFiliacao: async (dataEdit) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.PARAMETROS.EDITAR_TABELA_FILIACAO,
        dataEdit,
      )

      return response.data
    } catch (error) {
      return {
        error: true,
        message: error.response?.data?.message || 'Erro ao editar filiação!',
        status: error.response?.status,
      }
    }
  },

  arquivarOuDesarquivarTabelaMensalidade: async (men_id) => {
    try {
      const response = await apiGeral.post(
        `${ENDPOINTS.PARAMETROS.ARQUIVAR_E_DESARQUIVAR_TABELA_MENSALIDADE}/${men_id}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  criarTabelaMensalidade: async (mensalidadeDados) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.PARAMETROS.CRIAR_TABELA_MENSALIDADE,
        mensalidadeDados,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  editarTabelaMensalidade: async (dataEdit) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.PARAMETROS.EDITAR_TABELA_MENSALIDADE,
        dataEdit,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  criarTarefa: async (nomeTipoTarefa) => {
    try {
      const response = await apiGeral.post(ENDPOINTS.PARAMETROS.CRIAR_TAREFA, {
        TAREFA_NOME: nomeTipoTarefa,
      })

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  arquivarOuDesarquivarTarefa: async (TAREFA_ID) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.PARAMETROS.ARQUIVAR_OU_DESARQUIVAR_TAREFA,
        { TAREFA_ID: TAREFA_ID },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  atualizarTarefa: async (novoNomeTipoTarefa, TAREFA_ID) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.PARAMETROS.ATUALIZAR_TAREFA,
        {
          TAREFA_NOME: novoNomeTipoTarefa,
          TAREFA_ID: TAREFA_ID,
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  arquivarOuDesarquivarMensagem: async (MENSAGEM_ID) => {
    try {
      const response = await apiGeral.post(
        `${ENDPOINTS.PARAMETROS.ARQUIVAR_OU_DESARQUIVAR_MENSAGEM}?mensagem_id=${MENSAGEM_ID}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  editarOuCriarMensagem: async (dataSend, template) => {
    const requestUrl =
      template.MENSAGEM_ID !== ''
        ? `${ENDPOINTS.PARAMETROS.EDITAR_MENSAGEM}`
        : `${ENDPOINTS.PARAMETROS.CRIAR_MENSAGEM}`

    try {
      const response = await apiGeral.post(requestUrl, dataSend)

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  configurarVistoriaIcon: async (icon) => {
    try {
      const response = await apiGeral.patch(
        `${ENDPOINTS.PARAMETROS.CONFIGURAR_VISTORIA_ICON}/${icon}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}
