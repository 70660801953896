import {
  CheckboxParamter,
  InputContainer,
  InputNumber,
  ParametrosCard,
  ParametrosContainer,
} from './styles'
import IconArrowCount from '../../assets/icon-combo-seta-white.svg'
import { useEffect, useState } from 'react'
import { parametrosRepository } from '../../../../api/repositories/parametrosRepository'

export function Parametros() {
  const [loading, setLoading] = useState(false)
  const [daysExpiration, setDaysExpiration] = useState(0)
  const [activeParamter, setActiveParamter] = useState(false)

  const [activeVistoria, setActiveVistoria] = useState(false)

  const DADOS_VENCIMENTO = {
    PAR_NOME: 'DATA_VENCIMENTO_OPORTUNIDADE',
    PAR_ATIVO: activeParamter,
    PAR_CONFIG: String(daysExpiration),
  }

  useEffect(() => {
    buscarParametros()
    listarVistorias()
  }, [])

  useEffect(() => {
    if (daysExpiration || activeParamter) {
      enviarVencimento()
    }
  }, [daysExpiration, activeParamter])

  console.log(daysExpiration)

  async function setarEndorVistoria() {
    try {
      await parametrosRepository.configurarVistoriaIcon('endor')

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  async function listarVistorias() {
    try {
      const data = await parametrosRepository.listarVistorias()

      if (data.type === 'ENDOR') {
        setActiveVistoria(true)
      }

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const enviarVencimento = async () => {
    try {
      await parametrosRepository.atualizarParametros(DADOS_VENCIMENTO)

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  async function buscarParametros() {
    try {
      const data = await parametrosRepository.buscarParametros()

      console.log(data)

      setDaysExpiration(data[0].PAR_CONFIG)
      setActiveParamter(data[0].PAR_ATIVO)
      console.log(data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ParametrosContainer>
      <ParametrosCard>
        <CheckboxParamter>
          <input
            type="checkbox"
            checked={activeParamter}
            onChange={(e) => setActiveParamter(e.target.checked)}
          />
        </CheckboxParamter>
        <div>
          <InputContainer>
            <h3>Ao atingir</h3>
            <InputNumber>
              <input
                type="number"
                min={0}
                value={daysExpiration}
                onChange={(e) => setDaysExpiration(e.target.value)} // Mantém como string para exibição
              />
              <div>
                <img
                  src={IconArrowCount}
                  alt="Incrementar"
                  onClick={() =>
                    setDaysExpiration((prev) => Math.max(parseInt(prev) + 1, 0))
                  }
                />
                <img
                  src={IconArrowCount}
                  alt="Decrementar"
                  onClick={() =>
                    setDaysExpiration((prev) => Math.max(parseInt(prev) - 1, 0))
                  }
                />
              </div>
            </InputNumber>
            <h3 style={{ marginLeft: '3px' }}>
              dias Pendente, a Oportunidade será marcada como Perdida!
            </h3>
          </InputContainer>
          <p>
            Se uma oportunidade atingir o número acima de dias, Pendente, a
            Oportunidade será automaticamente Perdida!
          </p>
        </div>
      </ParametrosCard>
      <ParametrosCard>
        <CheckboxParamter>
          <input
            type="checkbox"
            checked={activeVistoria}
            onChange={(e) => setarEndorVistoria()}
            disabled={activeVistoria}
          />
        </CheckboxParamter>
        <div>
          <h3>Utilizar Endor Vistorias & Antifraude</h3>
          <p>
            Selecione essa opção para realizar o processo de vistorias pelo
            aplicativo Endor Vistorias & Antifraude
          </p>
        </div>
      </ParametrosCard>
    </ParametrosContainer>
  )
}
