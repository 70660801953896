import React, { useEffect, useState } from 'react'
import {
  BackgroundModal,
  ModalHeader,
  ModalBoxCliente,
  X,
} from '../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal'
import { IoMdClose } from 'react-icons/io'
import { ClipLoader } from 'react-spinners'

function ModalImagem({ closeModal, imagem }) {
  const [loaded, setLoaded] = useState(false)
  const [imgSrc, setImgSrc] = useState('')

  useEffect(() => {
    // Força a atualização da imagem com um timestamp para evitar cache
    const timestampedUrl = `${imagem}?t=${new Date().getTime()}`
    setImgSrc(timestampedUrl)
    setLoaded(false) // Resetando o estado de carregamento

    // Cria uma nova instância de Image para monitorar o carregamento
    const img = new Image()
    img.src = timestampedUrl

    img.onload = () => {
      setLoaded(true) // Somente após a imagem ser carregada
    }
  }, [imagem])

  return (
    <BackgroundModal>
      <ModalBoxCliente>
        <ModalHeader>
          <h2>Imagem</h2>
          <X onClick={() => closeModal(false)}>
            <IoMdClose size={17} />
          </X>
        </ModalHeader>

        {!loaded && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '25px 0',
            }}
          >
            <ClipLoader color={'#cccc'} size={30} />
          </div>
        )}

        {loaded && (
          <img
            src={imgSrc}
            alt="img_capa"
            style={{ display: loaded ? 'block' : 'none' }}
          />
        )}
      </ModalBoxCliente>
    </BackgroundModal>
  )
}

export default ModalImagem
