import Logo from '../assets/icon_kamino.svg'
import './styles.css'
import { capitalizeFirstLetter } from '../../../utils/formats'

export function VersionUpdate({ version }) {
  console.log(version)

  console.log(version.data.collaborators.length)

  return (
    <div className="VersionUpdate Onboarding__AnimaTopVersion">
      <div className="VersionUpdate--LeftContainer">
        <div className="VersionUpdate--Container Header">
          <img src={Logo} height={80} />
          <h3 style={{ marginTop: 10 }}>{version?.data.project}</h3>
          <span className="version">
            {version?.data?.version} ({version?.data?.dateRelease})
          </span>
        </div>
        <div
          className={`VersionUpdate--Container colaborators ${version?.data?.collaborators.length > 7 ? 'Start' : 'Center'}`}
        >
          {/* Esta parte suporta até 7 colaboradores de projeto */}
          {version?.data?.collaborators.map((e) => (
            <span className="colaborator">{e.name}</span>
          ))}
        </div>
        <div className="VersionUpdate--Container Brand">
          <b className="BrandName">EPTA Tecnologia</b>
          <a
            className="version"
            href="https://www.eptatecnologia.com.br"
            target="_blank"
            rel="noreferrer"
          >
            www.eptatecnologia.com.br
          </a>
        </div>
      </div>
      <div className="VersionUpdate--RightContainer">
        <h2 className="VersionUpdate--Title">Software Atualizado!</h2>
        <span className="VersionUpdate--Description">
          Atualizamos nosso Software para oferecer sempre uma melhor
          experiência! Confira abaixo o que preparamos para vocês!
        </span>
        {version?.data?.innovations.length > 0 ? (
          <div className="VersionUpdate--Items">
            <h3 className="MinorItem--Tile">Novidades</h3>
            {version?.data?.innovations.map((e) => (
              <span className="Item--Description">- {e.title}</span>
            ))}
          </div>
        ) : (
          <></>
        )}
        {version?.data?.improvements?.length > 0 ? (
          <div className="VersionUpdate--Items">
            <h3 className="MinorItem--Tile">Melhorias</h3>
            {version?.data?.improvements.map((e) => (
              <span className="Item--Description">- {e.title}</span>
            ))}
          </div>
        ) : (
          <></>
        )}
        {version?.data?.corrections.length > 0 ? (
          <div className="VersionUpdate--Items">
            <h3 className="MinorItem--Tile">Correções</h3>
            {version?.data?.corrections.map((e) => (
              <span className="Item--Description">- {e.title}</span>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
