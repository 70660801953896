import { useEffect, useState } from 'react'
import { UsuarioContainer } from './styles'
import { userRepository } from '../../../../api/repositories/userRepository'
import ImgAdd from '../../../Home/assets/icon-add.svg'
import { ModalUser } from './ModalUser/ModalUser'
import menuAjustesTable from '../../../Home/assets/menu-ajustes-table.svg'
import menuAjustesTableFill from '../../../Home/assets/menu-ajustes-table-fill.svg'
import { Dropdown } from 'react-bootstrap'
import {
  ButtonInsideWrapper,
  TableButton,
} from '../../../Home/HomeRoutes/Oportunidades/Data Table/styles/styledDataTable'
import { EditarUsuario } from './EditarUsuario/EditarUsuario'
import editPencil from '../../../Home/assets/icon-edit-pencil.svg'
import archiveIcon from '../../../Home/assets/icon-arquivar.svg'
import { ModalArchive } from './ModalArchive/ModalArchive'

export function Usuarios({ setLoading }) {
  const [filterResult, setFilterResult] = useState([])
  const [userList, setUserList] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [editUserModal, setEditUserModal] = useState(false)
  const [userData, setUserData] = useState({})
  const [alteracaoFeita, setAlteracaoFeita] = useState(false)
  const [archiveUser, setArchiveUser] = useState(false)

  async function buscarUsuarios() {
    try {
      const usuarios = await userRepository.buscarUsuarios()
      setUserList(usuarios)
      setFilterResult(usuarios)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  async function handleChangeFilter(e) {
    const { value } = e.target
    setSearchInput(value)
    const filteredData = userList.filter((item) => {
      return Object.values(item)
        .join('')
        .toLowerCase()
        .includes(value.toLowerCase())
    })
    setFilterResult(filteredData)
  }

  useEffect(() => {
    setLoading(true)
    buscarUsuarios()
    setAlteracaoFeita(false)
  }, [alteracaoFeita])

  function ButtonDropDownMenu({ usuario }) {
    return (
      <Dropdown>
        <Dropdown.Toggle>
          <img src={menuAjustesTable} alt="Menu ajustes" />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ minWidth: '5rem' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.9375rem',
            }}
          >
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              disabled={!usuario.USR_ATIVO}
              onClick={() => {
                setEditUserModal(true)
                setUserData(usuario)
              }}
            >
              <ButtonInsideWrapper>
                <img src={editPencil} alt="" />
                <span>Editar</span>
              </ButtonInsideWrapper>
            </TableButton>
            <TableButton
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: '-apple-system,BlinkMacSystemFont,sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
              onClick={() => {
                setArchiveUser(true)
                setUserData(usuario)
              }}
            >
              <ButtonInsideWrapper>
                <img src={archiveIcon} alt="" />
                <span>{usuario.USR_ATIVO ? 'Arquivar' : 'Desarquivar'}</span>
              </ButtonInsideWrapper>
            </TableButton>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <UsuarioContainer>
      {openModal && (
        <ModalUser
          openModal={openModal}
          setOpenModal={setOpenModal}
          setAlteracaoFeita={setAlteracaoFeita}
        />
      )}

      {editUserModal && (
        <EditarUsuario
          openModal={editUserModal}
          setOpenModal={setEditUserModal}
          userData={userData}
          setAlteracaoFeita={setAlteracaoFeita}
          setLoading={setLoading}
        />
      )}

      {archiveUser && (
        <ModalArchive
          openModal={archiveUser}
          setOpenModal={setArchiveUser}
          userData={userData}
          setAlteracaoFeita={setAlteracaoFeita}
        />
      )}

      <section>
        <header>
          <div>
            <div className="SettingsUsers_Header_Title">
              <span className="SettingsUsers_Header_Title">Usuários</span>
              <img
                src={ImgAdd}
                onClick={() => {
                  setUserData(null)
                  setOpenModal(true)
                }}
              />
            </div>
            <div>
              <span className="SettingsUsers_Header_SubTitle">
                Adicione novos usuários ou pesquise e gerencie os existentes.
              </span>
            </div>
          </div>
          <div>
            <form onSubmit={(event) => event.preventDefault()}>
              <input
                type="text"
                name="searchValue"
                id="searchValue"
                value={searchInput}
                onChange={handleChangeFilter}
                placeholder="Pesquisar"
                maxLength="25"
              />
            </form>
          </div>
        </header>
        <main>
          <table>
            <thead>
              <tr>
                <th>Nome do Usuário</th>
                <th>Perfil de Acesso</th>
                <th>Status</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {filterResult &&
                filterResult.map((usuario) => (
                  <tr>
                    <td>{usuario.USR_NOME}</td>
                    <td>{usuario.PER_ID > 0 ? 'ADMIN' : 'Vendedor'}</td>
                    <td>
                      <div
                        className={`SettingsUsers__Status ${
                          usuario.USR_ATIVO
                            ? 'SettingsUsers__Status--active'
                            : 'SettingsUsers__Status--inactive'
                        }`}
                      >
                        {usuario.USR_ATIVO ? 'ATIVO' : 'ARQUIVADO'}
                      </div>
                    </td>
                    <td>
                      <div>
                        <ButtonDropDownMenu usuario={usuario} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </main>
      </section>
    </UsuarioContainer>
  )
}
