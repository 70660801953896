import styled from 'styled-components'
import { COLORS } from '../../../../colors'

export const Container = styled.div`
  padding: 0 20px;
`

export const PerfilCard = styled.div`
  margin: 20px auto;
  padding: 20px;
  border-radius: 4px;
  width: 50%;
  background-color: #fefefe;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);

  p {
    display: flex;
    padding: 5px 2px;
    margin: 5px 10px;
    border-bottom: 1px solid #ccc;
    align-items: center;

    @media only screen and (max-width: 500px) {
      display: block;
    }
  }

  p strong {
    margin-right: 5px;
    @media only screen and (max-width: 500px) {
      display: block;
    }
  }

  @media only screen and (max-width: 760px) {
    width: 80%;
    margin: 10px auto;
  }
`

export const PerfilCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 12px;
`

export const Line = styled.div`
  width: auto;
  height: 1px;
  margin: 10px 0;
  background-color: black;
`

export const InputSenha = styled.input`
  margin: 0 5px;
  padding: 2px;
  height: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;

  :focus {
    outline: none;
  }

  @media only screen and (max-width: 500px) {
    display: inline-block;
    margin: 0 5px 0 0;
  }

  @media only screen and (max-width: 280px) {
    width: 70%;
  }
`

export const ButtonVerSenha = styled.button`
  background-color: #fff;
  border: none;
  margin: 0;
  padding: 0;
  color: #aaa;
  :hover {
    cursor: pointer;
    color: #54546c;
  }
  @media only screen and (max-width: 500px) {
    display: inline-block;
  }
`

export const DivErro = styled.div`
  display: block;
  color: red;
  font-size: 12px;
  font-weight: bold;
`

export const ButtonSalvarSenha = styled.button`
  /* text-align: center;
  display: inline-block;
  margin: 5px;
  padding: 7px 25px;
  background-color: ${COLORS.primary};
  border: none;
  color: white;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px; */

  text-align: center;
  display: inline-block;
  padding: 0.5rem 0.75rem;
  background-color: ${COLORS.primary};
  border: none;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;

  :hover {
    cursor: pointer;
    background-color: ${COLORS.primaryHover};
  }

  :disabled {
    background-color: rgba(0, 174, 239, 0.6);
    cursor: not-allowed;
  }
`

export const ButtonConfirmar = styled.button`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 5px;
  padding: 0.5em 0.75em;
  background-color: #19ca95;
  border: none;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;

  :hover {
    cursor: pointer;
    background-color: #0f9e8a;
  }

  :disabled {
    background-color: #93d8c3;
    cursor: not-allowed;
  }
`
