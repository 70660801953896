import { Tab } from '@headlessui/react'
import styled from 'styled-components'
import lupaIcon from '../../../Home/assets/icon-lupa.svg'

const StyledDiv = styled.div`
  background-image: url(${lupaIcon});
`

export const FunilVendasContainer = styled.div`
  section {
    display: flex;
    flex-direction: column;
    gap: 3.125rem;

    padding: 3rem 3rem 0 3rem;

    header {
      display: grid;
      grid-template-columns: 4fr 2fr;
      justify-content: space-between;

      img {
        cursor: pointer;
      }

      .Funil_Header_Title {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        font-size: 1.875rem;
        font-weight: bold;
      }

      .Funil_Header_SubTitle {
        line-height: 2rem;
        font-size: 1rem;
      }
    }

    form {
      width: 100%;

      input {
        width: 99%;
        padding: 0 0.5rem;
        line-height: 2rem;

        background: #f2f2f2;

        border: none;
        border-radius: 8px;
        outline: none;

        background-image: url(${lupaIcon});
        background-position: 10px center;
        background-repeat: no-repeat;
        text-indent: 1.875rem;
        font-size: 1rem;
      }
    }

    main {
      height: 65.5vh;
      overflow: auto;

      table {
        width: 100%;

        border-collapse: separate;
        border-spacing: 0;

        position: sticky;

        thead {
          line-height: 2rem;
        }

        thead th {
          padding-left: 0.5rem;
          color: #838387;

          text-align: inherit;
          font-weight: normal;
          font-size: 0.9375rem;

          border-bottom: 0.2px solid #e2e2e2;

          background-color: #fff;

          position: sticky;
          position: -webkit-sticky;

          top: 0;
          z-index: 2;
        }

        thead th:nth-child(2) {
          width: 140px;
        }

        thead th:last-child {
          padding-left: 0.8rem;
          width: 50px;
        }

        tbody tr {
          line-height: 3.5rem;

          :hover {
            background: #f2f2f2;
          }
        }

        tbody tr td {
          color: #000;
          font-weight: 500;
          font-size: 1rem;

          padding-left: 0.5rem;
        }

        tbody tr td:first-child {
          display: flex;
          align-items: center;

          gap: 0.6rem;
        }

        tbody tr td:last-child {
          align-items: center;
        }

        tbody tr td:first-child img {
          width: 1.5625rem;
          height: auto;
        }

        .Funil__Status {
          display: flex;
          justify-content: center;
          align-items: center;

          color: white;
          font-size: 10px;
          font-weight: bold;
          text-align: center;

          border-radius: 9px;

          line-height: 1rem;
          width: 4.75rem;

          height: 18px;
        }

        .Funil__Status--active {
          background-color: #00b065;
        }

        .Funil__Status--inactive {
          background-color: #484848;
        }
      }
    }
  }
`
