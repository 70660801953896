import { apiGeral } from '../api'
import { ENDPOINTS } from '../endPoints'

export const clientesRepository = {
  cadastrarCliente: async (DADOS) => {
    try {
      const response = await apiGeral.post(
        ENDPOINTS.CLIENTES.CADASTRAR_CLIENTES,
        DADOS,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}
