import styled from 'styled-components'
import { COLORS } from '../../../colors'

export const Container = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`

export const LoginWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0;
  height: 100vh;
`

export const CardLogin = styled.div`
  background-color: #fff;
  flex: 0 0 24%;
  max-width: 24%;
  min-height: 100vh;
  padding: 0 20px;
  box-shadow: 1px 0px 3px rgba(27, 26, 117, 0.425);

  @media only screen and (max-width: 1240px) {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 10px;
  }
`

export const CardHeader = styled.div`
  text-align: center;
  padding: 2rem;

  @media only screen and (max-width: 1240px) {
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    width: 50%;
  }

  @media only screen and (max-width: 622px) {
    width: 100%;
  }

  img {
    width: 87%;
    margin: 0 auto;
    /* margin-right: 15%; */

    @media only screen and (max-width: 1240px) {
      width: 70%;
      margin: 0 auto;
      /* margin-left: 20%; */
    }

    @media only screen and (max-width: 966px) {
      width: 80%;
      /* margin: 0 auto; */
      margin-right: 20%;
    }
  }
`

export const CardBody = styled.div`
  text-align: left;
  /* padding-left: 0.5rem; */

  @media only screen and (max-width: 1240px) {
    margin: 0 auto;
    width: 30%;
    padding: 0;
  }

  @media only screen and (max-width: 966px) {
    margin: 0 auto;
    width: 50%;
  }

  @media only screen and (max-width: 622px) {
    /* margin: 0 auto; */
    width: 100%;
  }

  span {
    display: block;
    font-size: 26px;
    font-weight: bolder;
    margin: 20px 0px;

    @media only screen and (max-width: 306px) {
      font-size: 20px;
    }
  }
`

export const CardBottom = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 14px;

  a {
    color: ${COLORS.primary};
    text-decoration: none;
  }

  a:hover {
    color: ${COLORS.primaryHover};
  }

  @media only screen and (max-width: 622px) {
    width: 90%;
  }
`

export const Input = styled.input`
  display: block;
  width: 94%;
  height: 25px;
  padding: 0.3rem 0.5rem;
  border: 1px solid ${COLORS.lightGray};
  border-radius: 4px;
  margin: 10px 0px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;

  :focus {
    outline: none;
  }

  @media only screen and (max-width: 1240px) {
    width: 90%;
  }

  // @media only screen and (max-width: 896px) {
  //   width: 80%;
  // }

  // @media only screen and (max-width: 622px) {
  //   width: 80%;
  // }
`

export const Select = styled.select`
  display: block;
  width: 99%;
  padding: 0.5rem;
  border: 1px solid ${COLORS.lightGray};
  border-radius: 4px;
  margin: 10px 0px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;

  :focus {
    outline: none;
  }

  @media only screen and (max-width: 1240px) {
    width: 100%;
  }
`

export const BtnLogin = styled.button`
  display: block;
  width: 99%;
  height: 40px;
  margin: 55px 0px 10px 0px;
  padding: 10px;
  text-align: center;
  align-items: center;
  color: white;
  background-color: #1b9b45;
  border: 1px solid #1d6d47;
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;

  :hover {
    background-color: #0c5925;
    border: 1px solid #08352b;
    cursor: pointer;
  }

  :disabled {
    background-color: #56a563;
    border: 1px solid #2f8b60;
    cursor: not-allowed;
  }
  @media only screen and (max-width: 1240px) {
    width: 95%;
  }
`
