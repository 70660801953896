export const FunisVenda = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={19} height={15} {...props}>
    <path
      fill="#353535"
      d="M2.555 14.656h13.383c1.687 0 2.562-.867 2.562-2.531V2.609c0-1.664-.875-2.53-2.562-2.53H2.555C.867.078-.008.936-.008 2.608v9.516c0 1.664.875 2.531 2.563 2.531zm.093-1.531c-.726 0-1.125-.375-1.125-1.133v-9.25c0-.758.399-1.14 1.125-1.14h3.024v11.523H2.648zm4.524 0V1.602h4.148v11.523H7.172zm8.672-11.523c.719 0 1.125.382 1.125 1.14v9.25c0 .758-.406 1.133-1.125 1.133H12.82V1.602h3.024z"
    />
  </svg>
)

export const FunisVendaSelected = (props) => (
  <svg
    width="19"
    height="16"
    viewBox="0 0 19 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.703 15.836V.898h2.18c1.789 0 2.758.97 2.758 2.743v9.453c0 1.773-.97 2.742-2.758 2.742h-2.18zm-1.922 0h-4.93V.898h4.93v14.938zm-9.031 0c-1.781 0-2.758-.969-2.758-2.742V3.64C-.008 1.867.97.898 2.75.898h2.18v14.938H2.75z"
      fill="#0049C4"
      fillRule="nonzero"
    />
  </svg>
)
