import { Dialog } from '@headlessui/react'
import styled from 'styled-components'
import iconArrowSelect from '../../../../Home/assets/icon-arrow-select.svg'

export const BackgroundModal = styled(Dialog)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  z-index: 100;
`

export const ContentModal = styled.div`
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  outline: none;
  width: 528px;
  margin: 0 auto;
`

export const Container = styled.div`
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  padding: 2.1875rem 30px 20px;
  height: 100%;
  margin: 0 auto;

  .save_button {
    display: flex;
    justify-content: center;

    button {
      width: 7.5rem;
      height: 1.5625rem;
      margin-top: 1.25rem;
      border: none;
      border-radius: 5px;
      background-color: #00b065;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 600;
    }
  }
`

export const Header = styled.header`
  position: relative;
  display: flex;
  gap: 1.5rem;
  border-bottom: solid 1px #e2e2e2;
  padding-bottom: 1rem;
`

export const CloseButton = styled.span`
  position: absolute;
  top: -20px;
  right: 0px;
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    opacity: 1;
    transition: all 0.3s ease-in;
  }
`

export const Avatar = styled.img`
  width: 3.25rem;
  opacity: 0.2;
  transition: all 0.5s ease-out;

  &.SettingsModal__BlackColor {
    opacity: 1;
  }
`

export const Title = styled.h1`
  font-size: 1.375rem;
  font-weight: bold;
  color: #353535;
  opacity: 0.2;
  transition: all 0.5s ease-out;

  &.SettingsModal__BlackColor {
    opacity: 1;
  }
`

export const Subtitle = styled.p`
  color: #353535;
  font-size: 0.75rem;
  font-weight: 300;
  opacity: 0.2;
`

export const FormStep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #fff;
  margin-top: 1.25rem;
  padding: 1.25rem 1.5625rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(217, 217, 217, 0.5);
`

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  width: 100%;

  input,
  select {
    font-size: 0.875rem;
    height: 2.1875rem;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    padding: 0 1rem;
    background: #f0f0f0;
    transition: all 0.3s ease;
    outline: 0;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      border: solid 1.5px #0049c4;
    }

    &::placeholder {
      color: #c9c9c9;
    }

    box-sizing: border-box;
  }

  select {
    background-image: url(${iconArrowSelect});
    background-position: calc(100% - 15px) center;
    background-repeat: no-repeat;
    background-size: 12px;
  }
`

export const FormRow = styled.div`
  display: flex;
`

export const FormCol = styled.div`
  display: flex;
  gap: 1rem;
`

export const Label = styled.label`
  font-size: 0.75rem;
  font-weight: 300;
`
export const ListboxWrapper = styled.div`
  position: relative;
  width: 100%;

  .ListboxButton {
    width: 100%;
    font-size: 0.875rem;
    height: 2.1875rem;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    padding: 0 2rem 0 1rem;
    background: #f0f0f0;
    background-image: url(${iconArrowSelect});
    background-position: calc(100% - 15px) center;
    background-repeat: no-repeat;
    background-size: 12px;
    outline: 0;
    transition: all 0.3s ease;
    cursor: default;
    text-align: left;

    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      border: solid 1.5px #0049c4;
    }
  }

  .ListboxOptions {
    box-sizing: border-box;
    position: absolute;
    z-index: 100;
    width: 100%;
    max-height: 90px;
    overflow-y: auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 0;
    top: 100%; /* Faz com que a lista de opções apareça abaixo do botão */
    left: 0;

    .ListboxOption {
      padding: 0.5rem 1rem;
      cursor: pointer;

      &:hover {
        background: #f0f0f0;
      }
    }
  }

  .combobox-options {
    position: absolute;
    z-index: 1;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }

  .combobox-option {
    cursor: pointer;

    &:hover {
      background: #f0f0f0;
    }
  }

  .combobox-search {
    padding: 0.5rem;
    border-bottom: 1px solid #ddd;
  }

  .combobox-search input {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0.5rem;
  }
`
