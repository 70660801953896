import React from 'react'
import Pagination from '@mui/material/Pagination'

export const Paginator = ({ count, page, handleChange }) => {
  return (
    <Pagination
      count={count}
      size="medium"
      page={page}
      variant="outlined"
      shape="rounded"
      onChange={handleChange}
    />
  )
}
