import {
  Avatar,
  BackgroundModal,
  CloseButton,
  Container,
  ContentModal,
  FormField,
  FormCol,
  FormStep,
  Header,
  Label,
  Title,
  ListboxWrapper,
  FormRow,
} from './styles'
import ImgClose from '../../../../Home/assets/icon-close.svg'
import ImgIconUser from '../../../../Home/assets/icon-usr-register-modal.svg'
import { parametrosRepository } from '../../../../../api/repositories/parametrosRepository'
import { useForm } from 'react-hook-form'
import { MultiSelect } from 'react-multi-select-component'
import { useEffect, useState } from 'react'
import { Listbox } from '@headlessui/react'
import { Checkbox } from '@mui/material'
import { Alerts } from '../../../../../utils/alert'

export function ModalFunil({
  setOpenModal,
  openModal,
  funilData,
  changeModal,
}) {
  const { register, handleSubmit, setValue } = useForm()

  const [vendedores, setVendedores] = useState([])
  const [selectedVendedores, setSelectedVendedores] = useState([])
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (funilData) {
      setSelectedVendedores(funilData.VEN_IDS)
      setValue('nomeFunil', funilData.FUN_NOME)
    }
    buscarVendedores()
  }, [])

  async function buscarVendedores() {
    try {
      const vendedores = await parametrosRepository.buscarVendedores()
      setVendedores(vendedores)
    } catch (error) {
      console.log(error)
    }
  }

  async function criarFunil(nomeFunil) {
    try {
      await parametrosRepository.criarFunil(nomeFunil, selectedVendedores)

      Alerts.SUCCESS('Funil criado com sucesso')
    } catch (error) {
      console.log(error)
    }
  }

  async function editarFunil(novoNomeFunil, funilId, vendedoresSelecionados) {
    try {
      await parametrosRepository.atualizarFunil(
        novoNomeFunil,
        funilId,
        vendedoresSelecionados,
      )

      Alerts.SUCCESS('Funil editado com sucesso')
    } catch (error) {
      console.log(error)
    }
  }

  async function onSubmit(dataSubmiting) {
    const { nomeFunil } = dataSubmiting

    if (funilData) {
      await editarFunil(nomeFunil, funilData.FUN_ID, selectedVendedores)
    } else {
      await criarFunil(nomeFunil, selectedVendedores)
    }

    setOpenModal(false)
    changeModal(true)
  }

  function handleSelectVendedor(vendedor) {
    setSelectedVendedores(vendedor)
    setValue('vendedorId', vendedor)
  }

  const filtrarVendedores =
    query === ''
      ? vendedores
      : vendedores.filter((vendedor) => {
          return vendedor.label.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <BackgroundModal open={openModal} onClose={() => setOpenModal(false)}>
      <ContentModal>
        <Container>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Header>
              <CloseButton onClick={() => setOpenModal(false)}>
                <img src={ImgClose} alt="Fechar modal" />
              </CloseButton>
              <div>
                <Title className={'SettingsModal__BlackColor'}>
                  NOVO FUNIL
                </Title>
              </div>
            </Header>
            <main>
              <FormStep>
                <FormRow>
                  <FormField>
                    <Label htmlFor="userName">Nome do tipo</Label>
                    <input
                      type="text"
                      placeholder=""
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^a-zA-Z0-9\s]/g,
                          '',
                        )
                      }}
                      {...register('nomeFunil')}
                    />
                  </FormField>
                </FormRow>
                <FormField>
                  <ListboxWrapper>
                    <Label htmlFor="userName">Vendedores</Label>
                    <Listbox
                      value={selectedVendedores}
                      onChange={handleSelectVendedor}
                      multiple
                    >
                      <>
                        <div style={{ display: 'flex' }}>
                          <Listbox.Button className="ListboxButton">
                            {selectedVendedores.length === 0
                              ? 'Selecione o Vendedor'
                              : selectedVendedores
                                  .map((vendedor) => vendedor.label)
                                  .join(', ')}
                          </Listbox.Button>
                        </div>

                        {
                          <Listbox.Options className="combobox-options">
                            <div className="combobox-search">
                              <input
                                type="text"
                                placeholder="Pesquisar..."
                                value={query}
                                onChange={(event) =>
                                  setQuery(event.target.value)
                                }
                              />
                            </div>

                            <div
                              className="combobox-option"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              onClick={() => {
                                selectedVendedores.length === vendedores.length
                                  ? setSelectedVendedores([])
                                  : setSelectedVendedores(vendedores)
                              }}
                            >
                              <Checkbox
                                checked={
                                  selectedVendedores.length ===
                                  vendedores.length
                                }
                                onClick={(event) => {
                                  event.target.checked
                                    ? setSelectedVendedores(vendedores)
                                    : setSelectedVendedores([])
                                }}
                              />
                              <span
                                style={{
                                  fontSize: '12px',
                                }}
                              >
                                Selecionar todos
                              </span>
                            </div>
                            {filtrarVendedores.map((vendedor) => (
                              <Listbox.Option
                                value={vendedor}
                                className="combobox-option"
                              >
                                {({ selected }) => (
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Checkbox checked={selected} />
                                    <span style={{ fontSize: '12px' }}>
                                      {vendedor.label}
                                    </span>
                                  </div>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        }
                      </>
                    </Listbox>{' '}
                  </ListboxWrapper>
                </FormField>
              </FormStep>
              <div className="save_button">
                <button type="submit">Salvar</button>
              </div>
            </main>
          </form>
        </Container>
      </ContentModal>
    </BackgroundModal>
  )
}
