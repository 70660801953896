import React, { createContext, useState } from 'react'

export const KanbanContext = createContext()

const KanbanContextProvider = ({ children }) => {
  const [veiculoExiste, setVeiculoExiste] = useState({
    veiculoBoolean: false,
    opoId: '',
  })

  return (
    <KanbanContext.Provider value={{ veiculoExiste, setVeiculoExiste }}>
      {children}
    </KanbanContext.Provider>
  )
}

export default KanbanContextProvider
