import styled from 'styled-components'
import { COLORS } from '../../../../../../../colors.js'

export const TarefaOportunidade = styled.div`
  margin: 1em 0;

  .card_tarefa {
    display: block;
    margin: 0.75rem 0;
    padding: 1rem;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 4px solid;

    border-radius: 7px;

    .header {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 0.25rem;
      font-size: 14px;

      /* #realizada{
        margin: 0;
      } */

      .i {
        height: 0.5em;
        width: 1.3em;
        background-color: ${COLORS.primary};
        border-radius: 5px;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 0.35rem;
      color: #31303f;

      h3 {
        margin-top: 0;
        margin-bottom: 0.25rem;
      }

      .tarefa_item {
        font-size: 13px;
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;
    }
  }
`

export const CriarNovaTarefa = styled.button`
  padding: 0.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  border-top: 0;
  border-bottom: 0;
  border-left: 1px solid #cdd1df;
  border-right: 1px solid #cdd1df;
  width: 100%;
  background-color: white;
  font-weight: 600;
  color: #14152b;

  .icon {
    color: ${COLORS.primary};
  }

  :hover {
    background-color: #edeff5;
    border-left: 1px solid #b9bed1;
    border-right: 1px solid #b9bed1;
    transition: 0.4s;
    color: #000;

    .icon {
      color: ${COLORS.primaryHover};
    }
  }
`

export const FormTarefa = styled.div`
  padding: 0.75rem;
  animation: showUp 0.5s forwards;
  margin: 0 auto;
  width: 90%;

  .input_flex {
    display: flex;
    width: 100%;
    margin-bottom: 0.75rem;
    @media only screen and (max-width: 999px) {
      display: block;
    }

    .wrapper,
    .wrapper_1,
    .wrapper_2,
    .wrapper_3 {
      padding: 0 0.5rem;

      @media only screen and (max-width: 999px) {
        width: 100%;
        padding: 0;
      }

      label {
        display: block;
        font-size: 12px;
        font-weight: 500;
        color: #5e5e5e;
      }

      input,
      input[type='number'] {
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
        border: 1px solid #ccc;
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        width: 96%;
        display: block;
        text-transform: uppercase;

        :focus {
          outline: none;
        }
      }

      textarea {
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
        border: 1px solid #ccc;
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        resize: none;
        display: block;
        text-transform: uppercase;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;

        :focus {
          outline: none;
        }
      }

      select {
        padding: 0.3rem 0.5rem;
        margin: 0;
        border-radius: 2px;
        border: 1px solid #ccc;
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        width: 100%;
        display: block;
        background-color: white;
        color: #000;
        /* background: url(https://i.imgur.com/pz0fzEP.png) no-repeat right white; */
        /* -webkit-appearance: none; */

        :focus {
          outline: none;
        }
      }
    }

    .wrapper {
      width: 50%;

      .calendario-wrapper {
        display: flex;
        input {
          display: inline-block;
          width: 70%;
          margin-right: 0.5rem;
        }

        .button_calendario {
          position: relative;
          display: flex;
          align-items: center;
          border: none;
          color: ${COLORS.primary};
          background-color: transparent;
          border-radius: 4px;
          font-weight: 700;
          :hover {
            color: ${COLORS.primaryHover};
          }
        }
      }

      .hora_wrapper {
        display: flex;
        gap: 1rem;
        align-items: center;
        select {
          display: inline-block;
        }
      }

      @media only screen and (max-width: 999px) {
        width: 100%;
        padding: 0;
      }
    }
    .wrapper_1 {
      width: 30%;
      @media only screen and (max-width: 999px) {
        width: 100%;
        padding: 0;
      }
    }
    .wrapper_2 {
      width: 70%;
      @media only screen and (max-width: 999px) {
        width: 100%;
        padding: 0;
      }
    }

    .wrapper_3 {
      width: 100%;
      @media only screen and (max-width: 999px) {
        width: 100%;
        padding: 0;
      }
    }
    .calendario {
      position: absolute;
    }
  }

  .footer {
    display: flex;
    justify-content: end;
  }

  @keyframes showUp {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
