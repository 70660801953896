export const TarefasSvg = (props) => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.438 7.297c1.875 0 3.437-1.563 3.437-3.445C6.875 1.96 5.313.406 3.438.406A3.461 3.461 0 0 0-.008 3.852c0 1.875 1.563 3.445 3.446 3.445zm0-1.328A2.14 2.14 0 0 1 1.32 3.852c0-1.157.97-2.125 2.118-2.125 1.14 0 2.117.976 2.117 2.125 0 1.148-.977 2.117-2.117 2.117zm5.914-1.422h7.859a.69.69 0 0 0 .695-.688.69.69 0 0 0-.695-.687h-7.86a.679.679 0 0 0-.687.687c0 .383.297.688.688.688zM3.438 16.328c1.875 0 3.437-1.562 3.437-3.445 0-1.89-1.562-3.446-3.437-3.446-1.891 0-3.446 1.563-3.446 3.446 0 1.883 1.563 3.445 3.446 3.445zm0-1.328a2.14 2.14 0 0 1-2.118-2.117c0-1.149.97-2.125 2.118-2.125 1.14 0 2.117.976 2.117 2.125C5.555 14.03 4.578 15 3.438 15zm5.914-1.414h7.859a.69.69 0 0 0 .695-.688.69.69 0 0 0-.695-.687h-7.86a.679.679 0 0 0-.687.687c0 .383.297.688.688.688z"
      fill="#353535"
      fillRule="nonzero"
    />
  </svg>
)

export const TarefasSvgSelected = (props) => (
  <svg
    width="19"
    height="17"
    viewBox="0 0 19 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.57 7.422c1.961 0 3.57-1.617 3.57-3.578 0-1.961-1.609-3.57-3.57-3.57a3.586 3.586 0 0 0-3.578 3.57c0 1.96 1.61 3.578 3.578 3.578zm-.43-1.64c-.187 0-.335-.102-.46-.25l-.875-1.04a.503.503 0 0 1-.133-.344c0-.28.21-.492.476-.492.172 0 .274.07.391.211l.594.703 1.383-2.21c.117-.188.257-.274.437-.274.274 0 .5.203.5.46 0 .11-.031.212-.117.345L3.609 5.516a.568.568 0 0 1-.468.265zM9.86 4.71h7.898a.859.859 0 1 0 0-1.719H9.859a.857.857 0 0 0-.859.86c0 .484.383.859.86.859zM3.57 16.437c1.961 0 3.57-1.617 3.57-3.578 0-1.96-1.609-3.578-3.57-3.578A3.593 3.593 0 0 0-.008 12.86c0 1.961 1.61 3.578 3.578 3.578zm-.43-1.648c-.187 0-.335-.094-.46-.242l-.875-1.04a.508.508 0 0 1-.133-.35c0-.274.21-.485.476-.485.172 0 .274.07.391.203l.594.71 1.383-2.218c.117-.18.257-.265.437-.265.274 0 .5.203.5.453a.608.608 0 0 1-.117.351l-1.727 2.617a.55.55 0 0 1-.468.266zm6.72-1.031h7.898a.864.864 0 0 0 .867-.86c0-.476-.39-.859-.867-.859H9.859a.857.857 0 0 0-.859.86c0 .476.383.859.86.859z"
      fill="#0049C4"
      fillRule="nonzero"
    />
  </svg>
)
