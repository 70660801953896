import { apiGeral } from '../api'
import { ENDPOINTS } from '../endPoints'

export const nabooRepository = {
  cidades: async (estado) => {
    try {
      const response = await apiGeral.post(
        `${ENDPOINTS.NABOO.CIDADES}?uf=${estado}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}
