import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { VistoriaContext } from '../../../../../../../context/VistoriaContext'

import {
  VistoriaBody,
  SectionForm,
  InputWrapper,
  BtnWrapper,
  PlanosLista,
} from '../styles/styledVistoria'
import { BtnSubmit } from '../../../Modal/style/styledModal'
import { MdAttachMoney, MdGppGood } from 'react-icons/md'
import { FaPlus } from 'react-icons/fa'
import Loading from '../../../../../../Loading/Loading'
import { oportunidadeRepository } from '../../../../../../../api/repositories/oportunidadesRepository'

function EscolhaPlano({ OPO_ID, DESCRICAO_PLAN, PLANO_ESCOLHIDO, handleNext }) {
  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  const {
    planoEscolhido,
    setPlanoEscolhido,
    adicionaisPlanos,
    setAdicionaisPlanos,
    dadosPlanos,
  } = useContext(VistoriaContext)

  const [arrayDados, setArrayDados] = useState([])
  const [arrayValores, setArrayValores] = useState([])
  const [arrayAdicionais, setArrayAdicionais] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setPlanoEscolhido('')
    setAdicionaisPlanos('')
  }, [OPO_ID])

  useEffect(async () => {
    let isMounted = true

    const listarOportunidadeWithOpoId = async () => {
      try {
        const data =
          await oportunidadeRepository.listarOportunidadeWithOpoID(OPO_ID)

        console.log('LISTAR OPORTUNIDADE WITH OPOID: ', data)
        var log = data[0].DESCRICAO_PLANOS_ID
        var planEsc = Number(data[0].PLANO_ESCOLHIDO)
        var logId = log.map((i) => {
          return i.PLAN_ID
        })

        if (logId.includes(planEsc)) {
          var posId = logId.indexOf(planEsc)
          var posAdd = log
            .map(function (i) {
              return i.PLAN_ID
            })
            .indexOf(logId[posId])

          setPlanoEscolhido(logId[posId].toString())
          setAdicionaisPlanos(log[posAdd].ADICIONAIS)
          handleNext()
        } else {
          setLoading(false)
        }
      } catch (error) {
        console.log('ERROR AO LISTAR OPORTUNIDADE WITH OPOID: ', error)
        console.log(error)
      }
    }

    listarOportunidadeWithOpoId()

    /*
    await axios
      .get(
        `https://kaminocrm.com.br:7778/oportunidades/listar-oportunidade/${OPO_ID}`,
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        },
        { timeout: 5 * 1000 },
      )
      .then((res) => {
        var log = res.data[0].DESCRICAO_PLANOS_ID
        var planEsc = Number(res.data[0].PLANO_ESCOLHIDO)
        var logId = log.map((i) => {
          return i.PLAN_ID
        })

        if (logId.includes(planEsc)) {
          var posId = logId.indexOf(planEsc)
          var posAdd = log
            .map(function (i) {
              return i.PLAN_ID
            })
            .indexOf(logId[posId])

          setPlanoEscolhido(logId[posId].toString())
          setAdicionaisPlanos(log[posAdd].ADICIONAIS)
          handleNext()
        } else {
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    */

    return () => {
      isMounted = false
    }
  }, [])

  const itensPlano = arrayDados ? arrayDados : []
  const valoresPlano = arrayValores ? arrayValores : []
  const adicionaisPlano = arrayAdicionais ? arrayAdicionais : []

  function validarPlano() {
    if (planoEscolhido === '') {
      return true
    } else {
      return false
    }
  }

  return (
    <VistoriaBody>
      {loading && <Loading />}
      <SectionForm>
        <InputWrapper
          style={{ display: 'block', width: '75%', margin: '0 auto' }}
        >
          <label htmlFor="plano-escolha">
            Escolha um plano para prosseguir:
          </label>
          <select
            name="plano-escolha"
            id="plano-escolha"
            onChange={(e) => {
              const valueJSON =
                e.target.value !== '' ? JSON.parse(e.target.value) : {}
              setArrayDados(valueJSON.itens)
              setArrayValores(valueJSON.valores)
              setArrayAdicionais(valueJSON.adicionais)
              setPlanoEscolhido(valueJSON.plan_id)
              setAdicionaisPlanos(valueJSON.adicionais)
              console.log(valueJSON.adicionais)
            }}
          >
            <option value={''}>Selecione...</option>
            {DESCRICAO_PLAN.map((i) => {
              return (
                <option
                  value={JSON.stringify({
                    plan_id: i.PLAN_ID,
                    valores: [
                      {
                        plan_1_franquia: i.PLAN_PARTICIPACAO_1,
                        plan_2_franquia: i.PLAN_PARTICIPACAO_2,
                        plan_valor_mensalidade: i.PLAN_VALOR_MENSALIDADE,
                      },
                    ],
                    itens: i.PLANOS_ITENS,
                    adicionais: i.ADICIONAIS,
                  })}
                >
                  {i.PLAN_DESCRICAO}
                </option>
              )
            })}
          </select>

          <PlanosLista>
            {itensPlano.map((element) => {
              if (
                typeof element != typeof {} &&
                typeof element == typeof 'string'
              ) {
                return (
                  <li>
                    <span className="icon">
                      <MdGppGood size={15} />
                    </span>

                    <span>
                      {element[0].toUpperCase() +
                        element.slice(1).toLowerCase()}
                    </span>
                  </li>
                )
              } else {
                return <></>
              }
            })}

            {adicionaisPlano.map((element) => {
              return (
                <li>
                  <span className="icon">
                    <FaPlus size={12} />
                  </span>
                  <span>
                    {element.ITEM_DESCRICAO[0].toUpperCase() +
                      element.ITEM_DESCRICAO.slice(1).toLowerCase()}
                  </span>
                </li>
              )
            })}

            {valoresPlano.map((i) => {
              return (
                <>
                  <li>
                    <span className="icon">
                      <MdAttachMoney size={17} />
                    </span>
                    <span>
                      Cota de participação:{' '}
                      {i.plan_1_franquia ? i.plan_1_franquia : ''}%
                    </span>
                  </li>
                  <li>
                    <span className="icon">
                      <MdAttachMoney size={17} />
                    </span>
                    <span>
                      Cota de participação:{' '}
                      {i.plan_2_franquia ? i.plan_2_franquia : ''}%
                    </span>
                  </li>
                  <li>
                    <span className="icon">
                      <MdAttachMoney size={17} />
                    </span>
                    <span>
                      Valor da mensalidade: R${' '}
                      {i.plan_valor_mensalidade ? i.plan_valor_mensalidade : ''}
                    </span>
                  </li>
                </>
              )
            })}
          </PlanosLista>
        </InputWrapper>
      </SectionForm>

      <SectionForm style={{ justifyContent: 'flex-end' }}>
        <BtnWrapper>
          <BtnSubmit
            onClick={() => {
              console.log(planoEscolhido)
              handleNext()
            }}
            disabled={validarPlano()}
          >
            Próximo
          </BtnSubmit>
        </BtnWrapper>
      </SectionForm>
    </VistoriaBody>
  )
}

export default EscolhaPlano
