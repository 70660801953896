import { apiGeral } from '../api'
import { ENDPOINTS } from '../endPoints'

export const vendedorRepository = {
  buscarVendedor: async () => {
    try {
      const response = await apiGeral.get(ENDPOINTS.VENDEDOR.BUSCAR_VENDEDORES)

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}
