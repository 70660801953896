import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { VistoriaContainer, VistoriaScreen } from './styles/styledVistoria'
import { IoMdClose } from 'react-icons/io'
import { ModalHeader, X } from '../../Modal/style/styledModal'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepConnector from '@material-ui/core/StepConnector'
import Typography from '@material-ui/core/Typography'
import Check from '@material-ui/icons/Check'
import EscolhaPlano from './Etapas/EscolhaPlano'
import DadosCliente from './Etapas/DadosCliente'
import DadosVeiculo from './Etapas/DadosVeiculo'
import DadosVistoria from './Etapas/DadosVistoria'
import Resumo from './Etapas/Resumo'
import { KanbanContext } from '../../../../../../context/KanbanContext'
import { useNavigate } from 'react-router-dom'
import { oportunidadeRepository } from '../../../../../../api/repositories/oportunidadesRepository'

/// --- ESTILIZAÇÃO DO STEPPER --- ///

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    //   '& $line': {
    //     backgroundImage:
    //       'linear-gradient( 95deg,rgb(0, 174, 239) 0%,rgb(10, 109, 201) 50%,rgb(8, 130, 201) 100%)',
    //   },
    '& $line': {
      backgroundColor: '#1768e4',
    },
  },
  completed: {
    //   '& $line': {
    //     backgroundImage:
    //       'linear-gradient( 95deg,rgb(0, 174, 239) 0%,rgb(10, 109, 201) 50%,rgb(8, 130, 201) 100%)',
    //   },
    '& $line': {
      backgroundColor: '#1768e4',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    //   backgroundImage:
    //     'linear-gradient( 136deg, rgb(0, 174, 239) 0%,rgb(10, 109, 201) 50%,rgb(8, 130, 201) 100%)',
    backgroundColor: '#1768e4',
  },
  completed: {
    //   backgroundImage:
    //     'linear-gradient( 136deg, rgb(0, 174, 239) 0%,rgb(10, 109, 201) 50%,rgb(8, 130, 201) 100%)',
    backgroundColor: '#1768e4',
  },
})

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: <span style={{ fontSize: '20px', fontWeight: 'bold' }}>1</span>,
    2: <span style={{ fontSize: '20px', fontWeight: 'bold' }}>2</span>,
    3: <span style={{ fontSize: '20px', fontWeight: 'bold' }}>3</span>,
    4: <span style={{ fontSize: '20px', fontWeight: 'bold' }}>4</span>,
    5: <span style={{ fontSize: '20px', fontWeight: 'bold' }}>5</span>,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? <Check /> : icons[String(props.icon)]}
    </div>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

function getSteps() {
  return [
    'Planos Escolhidos',
    'Dados do Cliente',
    'Dados do Veículo',
    'Dados da Vistoria',
    'Resumo',
  ]
}

/// --- COMPONENTE GERAL --- ///

function Vistoria({ isVisible, OPO_ID }) {
  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  const navigate = useNavigate()

  /// ------ FUNÇÕES DO COMPONENTE ------ ///

  const { veiculoExiste, setVeiculoExiste } = useContext(KanbanContext)

  const [nomeExiste, setNomeExiste] = useState('')
  const [cpfExiste, setCpfExiste] = useState('')
  const [emailExiste, setEmailExiste] = useState('')
  const [telefoneExiste, setTelefoneExiste] = useState('')

  const [placaExiste, setPlacaExiste] = useState('')
  const [marcaExiste, setMarcaExiste] = useState('')
  const [modeloExiste, setModeloExiste] = useState('')
  const [chassiExiste, setChassiExiste] = useState('')
  const [anoFabExiste, setAnoFabExiste] = useState('')
  const [anoModeloExiste, setAnoModeloExiste] = useState('')
  const [codigoFipeExiste, setCodigoFipeExiste] = useState('')
  const [tipoExiste, setTipoExiste] = useState('')
  const [valorExiste, setValorExiste] = useState('')
  const [codMarcaExiste, setCodMarcaExiste] = useState('')
  const [codModeloExiste, setCodModeloExiste] = useState('')
  const [cidadeExiste, setCidadeExiste] = useState('')
  const [ufExiste, setUfExiste] = useState('')

  const [codigoCombustivel, setCodigoCombustivel] = useState('')

  const [planos, setPlanos] = useState([])
  const [planoJaEscolhido, setPlanoJaEscolhido] = useState('')

  useEffect(async () => {
    const listarOportunidadeWithOpoId = async () => {
      try {
        const data =
          await oportunidadeRepository.listarOportunidadeWithOpoID(OPO_ID)

        console.log('LISTAR OPORTUNIDADE WITH OPOID: ', data)

        setNomeExiste(data[0].CLI_NOME)
        setCpfExiste(data[0].CLI_CPF)
        setEmailExiste(data[0].CLI_EMAIL)
        setTelefoneExiste(data[0].CLI_TELEFONE)

        setPlacaExiste(data[0].VEI_PLACA)
        setMarcaExiste(data[0].VEI_MARCA)
        setModeloExiste(data[0].VEI_MODELO)
        setChassiExiste(data[0].VEI_CHASSI)
        setAnoFabExiste(data[0].VEI_ANO_FAB)
        setAnoModeloExiste(data[0].VEI_ANO_MODELO)
        setCodigoFipeExiste(data[0].VEI_COD_TABELA_FIPE)
        setTipoExiste(data[0].VEI_TIPO)
        setValorExiste(data[0].VEI_VALOR_FIPE)
        setCodigoCombustivel(data[0].VEI_CODIGO_ANO_MODELO)
        setCodMarcaExiste(data[0].VEI_CODIGO_MARCA)
        setCodModeloExiste(data[0].VEI_CODIGO_MODELO)
        setCidadeExiste(data[0].CIDADE)
        setUfExiste(data[0].UF)

        setPlanos(data[0].DESCRICAO_PLANOS_ID)
        setPlanoJaEscolhido(data[0].PLANO_ESCOLHIDO)
      } catch (error) {
        console.log('ERROR AO LISTAR OPORTUNIDADE WITH OPOID: ', error)
        console.log(error)
      }
    }

    listarOportunidadeWithOpoId()

    /*
    await axios
      .get(
        `https://kaminocrm.com.br:7778/oportunidades/listar-oportunidade/${OPO_ID}`,
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        },
        { timeout: 5 * 1000 },
      )
      .then((res) => {
        setNomeExiste(res.data[0].CLI_NOME)
        setCpfExiste(res.data[0].CLI_CPF)
        setEmailExiste(res.data[0].CLI_EMAIL)
        setTelefoneExiste(res.data[0].CLI_TELEFONE)

        setPlacaExiste(res.data[0].VEI_PLACA)
        setMarcaExiste(res.data[0].VEI_MARCA)
        setModeloExiste(res.data[0].VEI_MODELO)
        setChassiExiste(res.data[0].VEI_CHASSI)
        setAnoFabExiste(res.data[0].VEI_ANO_FAB)
        setAnoModeloExiste(res.data[0].VEI_ANO_MODELO)
        setCodigoFipeExiste(res.data[0].VEI_COD_TABELA_FIPE)
        setTipoExiste(res.data[0].VEI_TIPO)
        setValorExiste(res.data[0].VEI_VALOR_FIPE)
        setCodigoCombustivel(res.data[0].VEI_CODIGO_ANO_MODELO)
        setCodMarcaExiste(res.data[0].VEI_CODIGO_MARCA)
        setCodModeloExiste(res.data[0].VEI_CODIGO_MODELO)
        setCidadeExiste(res.data[0].CIDADE)
        setUfExiste(res.data[0].UF)

        setPlanos(res.data[0].DESCRICAO_PLANOS_ID)
        setPlanoJaEscolhido(res.data[0].PLANO_ESCOLHIDO)
      })
      .catch((err) => console.log(err))
    */
  }, [])

  /// --- STEPPER --- ///

  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set())
  const steps = getSteps()

  const handleNext = () => {
    let newSkipped = skipped

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <EscolhaPlano
            OPO_ID={OPO_ID}
            DESCRICAO_PLAN={planos}
            PLANO_ESCOLHIDO={planoJaEscolhido}
            handleNext={handleNext}
            isVisible={isVisible}
          />
        )
      case 1:
        return (
          <DadosCliente
            OPO_ID={OPO_ID}
            CLI_NOME={nomeExiste}
            CLI_CPF={cpfExiste}
            CLI_EMAIL={emailExiste}
            CLI_TELEFONE={telefoneExiste}
            handleNext={handleNext}
            handleBack={handleBack}
            isVisible={isVisible}
          />
        )
      case 2:
        return (
          <DadosVeiculo
            OPO_ID={OPO_ID}
            VEI_PLACA={placaExiste}
            VEI_MARCA={marcaExiste}
            VEI_MODELO={modeloExiste}
            VEI_CHASSI={chassiExiste}
            VEI_ANO_FAB={anoFabExiste}
            VEI_ANO_MODELO={anoModeloExiste}
            VEI_COD_TABELA_FIPE={codigoFipeExiste}
            VEI_TIPO={tipoExiste}
            VEI_VALOR_FIPE={valorExiste}
            VEI_CODIGO_ANO_MODELO={codigoCombustivel}
            VEI_CODIGO_MARCA={codMarcaExiste}
            VEI_CODIGO_MODELO={codMarcaExiste}
            VEI_CIDADE={cidadeExiste}
            VEI_UF={ufExiste}
            handleNext={handleNext}
            handleBack={handleBack}
            isVisible={isVisible}
          />
        )
      case 3:
        return (
          <DadosVistoria
            OPO_ID={OPO_ID}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        )
      case 4:
        return (
          <Resumo
            OPO_ID={OPO_ID}
            handleBack={handleBack}
            isVisible={isVisible}
          />
        )
      default:
        return 'Unknown step'
    }
  }

  return (
    <VistoriaContainer>
      <VistoriaScreen>
        <ModalHeader>
          <h2>Cadastro de Vistoria</h2>
          <X
            onClick={() => {
              isVisible(false)
              if (veiculoExiste.veiculoBoolean) {
                setVeiculoExiste({
                  veiculoBoolean: false,
                  opoId: '',
                })
                navigate('/home/oportunidades')
              }
            }}
          >
            <IoMdClose size={17} />
          </X>
        </ModalHeader>

        <div className="modalBody">
          <div className={classes.root}>
            <div className="stepper">
              <Stepper
                alternativeLabel
                connector={<ColorlibConnector />}
                activeStep={activeStep}
              >
                {steps.map((label, index) => {
                  const stepProps = {}
                  const labelProps = {}

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel
                        StepIconComponent={ColorlibStepIcon}
                        {...labelProps}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
            </div>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <button onClick={() => alert('Enviado!')}>
                    Enviar dados
                  </button>
                </div>
              ) : (
                <div>
                  <Typography className={classes.instructions}>
                    {getStepContent(activeStep)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      </VistoriaScreen>
    </VistoriaContainer>
  )
}

export default Vistoria
