import React, { useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import TriggerItem from './TriggerItem/TriggerItem.jsx'

export function AutomacaoConfig({
  columnsFunil,
  optionsStatus,
  automacao,
  setAutomacao,
}) {
  const { AUT_TRIGGER, INFOS } = automacao

  function createNewTrigger() {
    setAutomacao((prev) => ({
      ...prev,
      INFOS: [
        ...INFOS,
        {
          AUT_TRIGGER: AUT_TRIGGER, // STATUS, ETAPA, TAREFA -> TELA 2
          AUT_EVENT: '', // CREATE, MOVE, ALTER, DELETE -> TELA 3
          AUT_FROM: '', // TAREFA && null -> TELA 3
          AUT_TO: '', // TAREFA || (STATUS && AUT_EVENT === 'CREATE') && null -> TELA 3
          AUT_ACTIONS: [], // -> TELA 3
        },
      ],
    }))
  }

  function handleInfoTrigger(index, newInfo) {
    const newInfoList = [...INFOS]
    newInfoList[index] = newInfo
    setAutomacao((prev) => ({
      ...prev,
      INFOS: newInfoList,
    }))
  }

  function removeTrigger(item) {
    const updatedInfo = INFOS.filter((i) => i !== item)
    setAutomacao((prev) => ({
      ...prev,
      INFOS: updatedInfo,
    }))
  }

  return (
    <div className="automacao_config_container">
      <span className="step__title">
        Configurar processos de automações por{' '}
        {automacao.AUT_TRIGGER?.toLowerCase()}
      </span>
      <div className="automacao_config_form">
        <div className="create_trigger" onClick={() => createNewTrigger()}>
          <span className="icon">
            <FaPlus />
          </span>
          <span>Novo gatilho</span>
        </div>
        <div className="trigger_list">
          {INFOS?.length > 0 &&
            INFOS.map((el, index) => {
              return (
                <TriggerItem
                  item={el}
                  index={index}
                  columnsFunil={columnsFunil}
                  optionsStatus={optionsStatus}
                  handleInfoTrigger={handleInfoTrigger}
                  removeTrigger={removeTrigger}
                />
              )
            })}
        </div>
      </div>
    </div>
  )
}
