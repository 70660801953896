import React, { useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import logo from '../../pages/Onboarding/assets/kamino_logo.svg'
import './styles.css'
import UserMenu from '../UserMenu/UserMenu'
import { AuthContext } from '../../context/auth'

function Header() {
  const navigate = useNavigate()
  const location = useLocation()

  const handleNavigate = (route) => navigate(route)
  const { PER_ID } = useContext(AuthContext)

  return (
    <header className="Header__Container">
      <img src={logo} alt="logo kamino" />

      <nav>
        <ul className="Header__Menu">
          <li
            className={
              location.pathname === '/home/dashboard'
                ? 'Header_Menu--selected'
                : ''
            }
            onClick={() => handleNavigate('/home/dashboard')}
          >
            <span>Dashboard</span>
          </li>
          <li
            className={
              location.pathname === '/home/oportunidades'
                ? 'Header_Menu--selected'
                : ''
            }
            onClick={() => handleNavigate('/home/oportunidades')}
          >
            <span>Oportunidades</span>
          </li>
          {PER_ID == 1 && (
            <li
              className={
                location.pathname === '/parametros-sistema'
                  ? 'Header_Menu--selected'
                  : ''
              }
              onClick={() => handleNavigate('/parametros-sistema')}
            >
              <span>Ajustes</span>
            </li>
          )}
          <UserMenu />
        </ul>
      </nav>
    </header>
  )
}

export default Header
