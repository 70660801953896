import styled from 'styled-components'

export const LoadingScreen = styled.div`
  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  /* position: absolute; */
  bottom: 0;

  padding-bottom: 400px;

  height: 100vh;
  z-index: 999;

  img {
    width: 13rem;
    height: auto;
    display: block;
  }

  /* @media only screen and (max-width: 610px) {
    width: 300vw;
    height: 5000vh;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 30em 0 50em 0;
  }

  @media only screen and (max-width: 324px) {
    width: 300vw;
    height: 5000vh;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 20em 0 50em 0;
  } */
`
