import { toast, ToastContainer } from 'react-toastify'
import { parametrosRepository } from '../../../../../api/repositories/parametrosRepository'
import {
  AddStep,
  BackgroundModal,
  ButtonSalvarSenha,
  Container,
  ModalBoxPlanos,
  ModalHeader,
  SelectFiltroOpo,
  Step,
  StepBox,
  StepProgress,
  StepWrapper,
  X,
} from './styles'
import { IoIosArrowBack, IoIosArrowForward, IoMdClose } from 'react-icons/io'
import { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { AiFillEdit } from 'react-icons/ai'
import { RiDeleteBin6Fill } from 'react-icons/ri'

export function ModalSteps({
  openModal,
  setOpenModal,
  funilData,
  changeModal,
  loading,
}) {
  const [steps, setSteps] = useState([])
  const [etapaPadrao, setEtapaPadrao] = useState({})
  const [add, setAdd] = useState(false)
  const [novoNome, setNovoNome] = useState('')
  const [editNovoNome, setEditNovoNome] = useState('')
  const [edit, setEdit] = useState(false)

  useEffect(async () => {
    obterDadosFunil()
  }, [])

  async function obterDadosFunil() {
    try {
      const data = await parametrosRepository.buscarDadosFunil(funilData.FUN_ID)

      setSteps(data)
      const etapa = data.find((e) => {
        return (
          e.KANBAN_NOME.toUpperCase() == data[0]?.FUN_KANBAN_NOME.toUpperCase()
        )
      })
      setEtapaPadrao({
        KANBAN_NOME: etapa?.KANBAN_NOME,
        KANBAN_ID: etapa?.KANBAN_ID,
      })
    } catch (error) {
      console.log(error)
    }
  }

  async function atualizarOrdemFunil(dataSend) {
    try {
      const data = await parametrosRepository.atualizarOrdemFunil(
        funilData.FUN_ID,
        dataSend,
      )

      loading(false)
      console.log('ATUALIZANDO ORDEM FUNIL:', data)
    } catch (error) {
      console.log(error)
    }
  }

  async function enviarSteps(steps, etapaPadrao) {
    const dataSend = {
      funis: steps,
      padrao:
        etapaPadrao.KANBAN_NOME !== undefined
          ? etapaPadrao
          : {
              KANBAN_NOME: steps[0].KANBAN_NOME,
              KANBAN_ID: steps[0].KANBAN_ID ? steps[0].KANBAN_ID : '',
            },
    }

    atualizarOrdemFunil(dataSend)
  }

  function newStep(titulo, pos) {
    console.log(titulo, pos)
    if (steps.length > 0) {
      pos = steps[pos - 1]['ORDER'] + 0.01
    }

    var item = {
      KANBAN_COLOR: '#605C5C',
      KANBAN_NOME: titulo,
      KANBAN_PADRAO: false,
      KANBAN_TITULO: titulo,
      ORDER: pos,
    }

    const isFound = steps.some((element) => {
      if (element.KANBAN_NOME === titulo) {
        return true
      } else {
        return false
      }
    })

    if (isFound) {
      return
    } else {
      setSteps([...steps, item])
    }
  }

  function onMove(array, originIndex, toIndex, side) {
    const element = array.splice(originIndex, 1)[0]
    var newSteps = [...steps]

    newSteps.splice(toIndex, 0, element)
    var orderUpdate
    var nextItem = newSteps[originIndex]
    var prevItem = newSteps[originIndex - 1]

    if (side === 'esquerda') {
      orderUpdate = prevItem.ORDER + 0.01
      if (!nextItem.KANBAN_PADRAO) {
        var newOrderPrev = nextItem.ORDER + 0.01
        orderUpdate = element.ORDER - 0.01
        nextItem.ORDER = Number(newOrderPrev.toFixed(2))
      }
    } else if (side === 'direita') {
      orderUpdate = nextItem.ORDER + 0.01
    }

    element.ORDER = Number(orderUpdate.toFixed(2))
    if (toIndex !== -1) {
      newSteps[toIndex] = element
    }
    setSteps(newSteps)
  }

  function updateItem(originIndex, name) {
    var stepsCopy = [...steps]
    const element = steps.splice(originIndex, 1)[0]
    element.KANBAN_TITULO = name
    element.KANBAN_NOME = name
    if (originIndex !== -1) {
      stepsCopy[originIndex] = element
    }
    console.log(stepsCopy)
    setSteps(stepsCopy)
  }

  function deleteItem(originIndex) {
    var stepsCopy = [...steps]
    stepsCopy.splice(originIndex, 1)
    setSteps(stepsCopy)
  }

  function handleSubmit() {
    enviarSteps(steps, etapaPadrao)
    setOpenModal(false)
    changeModal(true)
  }

  return (
    <BackgroundModal open={openModal} onClose={() => setOpenModal(false)}>
      <ToastContainer />
      <ModalBoxPlanos>
        <ModalHeader>
          <h2>Configurações do funil</h2>
          <X onClick={() => setOpenModal(false)}>
            <IoMdClose size={17} />
          </X>
        </ModalHeader>
        <div className="modalBody">
          <div className="flex_label">
            <label>Gerenciar etapas</label>
          </div>
          <Container>
            <StepWrapper>
              {steps.map((i, index) => {
                return (
                  <Step key={i.KANBAN_NOME}>
                    <StepProgress>
                      {!i.KANBAN_PADRAO && (
                        <div>
                          <div className="arrows">
                            {index !== 0 ? (
                              <button
                                className="able"
                                onClick={() => {
                                  const fromIndex = steps.indexOf(i)
                                  const toIndex = fromIndex - 1
                                  onMove(steps, fromIndex, toIndex, 'esquerda')
                                }}
                              >
                                <IoIosArrowBack />
                              </button>
                            ) : (
                              <button className="not_able">
                                <IoIosArrowBack />
                              </button>
                            )}
                            {index !== steps.length - 1 ? (
                              <button
                                className="able"
                                onClick={() => {
                                  const fromIndex = steps.indexOf(i)
                                  const toIndex = fromIndex + 1
                                  onMove(steps, fromIndex, toIndex, 'direita')
                                }}
                              >
                                <IoIosArrowForward />
                              </button>
                            ) : (
                              <button className="not_able">
                                <IoIosArrowForward />
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                      <input
                        type="checkbox"
                        name="ball"
                        id="ball"
                        className="ball"
                      />
                      <div className="line" />
                    </StepProgress>
                    <StepBox>
                      <div className="titulo">
                        <p>{i.KANBAN_TITULO}</p>
                      </div>

                      {!i.KANBAN_PADRAO ? (
                        <>
                          <div className="acoes">
                            <button
                              className="editar"
                              onClick={() => {
                                setEdit(!edit)
                                setEditNovoNome(i.KANBAN_TITULO)
                                setNovoNome(i.KANBAN_TITULO)
                                setAdd(false)
                              }}
                            >
                              <AiFillEdit /> <span>Editar</span>
                            </button>

                            {!i.KANBAN_PADRAO && !i.KANBAN_EDITAVEL ? (
                              <button
                                onClick={() => {
                                  const fromIndex = steps.indexOf(i)
                                  deleteItem(fromIndex)
                                }}
                                className="btn_excluir"
                              >
                                <RiDeleteBin6Fill size={13} />
                              </button>
                            ) : (
                              ''
                            )}
                          </div>

                          {edit && novoNome === i.KANBAN_TITULO ? (
                            <div>
                              <label htmlFor="edit_nome">Novo nome</label>
                              <input
                                type="text"
                                value={editNovoNome}
                                name="edit_nome"
                                id="edit_nome"
                                style={{ textTransform: 'none' }}
                                onChange={(e) => {
                                  setEditNovoNome(e.target.value)
                                }}
                              />
                              <button
                                className="btn_salvar"
                                onClick={() => {
                                  const fromIndex = steps.indexOf(i)
                                  updateItem(fromIndex, editNovoNome)
                                  setEdit(false)
                                }}
                              >
                                Salvar
                              </button>
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </StepBox>
                  </Step>
                )
              })}
              <AddStep className={add ? 'open' : ''}>
                <StepProgress>
                  <button className="add__button" onClick={() => setAdd(!add)}>
                    <FaPlus />
                  </button>
                  <div className="line_button" />
                </StepProgress>
                {add && (
                  <StepBox>
                    <label htmlFor="itemnovo">Nova etapa</label>
                    <input
                      type="text"
                      name="itemnovo"
                      id="itemnovo"
                      style={{ textTransform: 'none' }}
                      value={novoNome}
                      onChange={(e) => setNovoNome(e.target.value)}
                    />

                    <button
                      className="adicionar"
                      onClick={() => {
                        newStep(novoNome, steps.length)
                        setNovoNome('')
                      }}
                    >
                      Adicionar etapa
                    </button>
                  </StepBox>
                )}
              </AddStep>
            </StepWrapper>
          </Container>

          {steps.length > 0 && (
            <>
              <label
                htmlFor="sendXls"
                style={{ display: 'block', marginBottom: '0.5rem' }}
              >
                Etapa inicial de nova oportunidade
              </label>
              <div style={{ display: 'block' }}>
                <SelectFiltroOpo
                  name="steps"
                  id="steps"
                  value={JSON.stringify(etapaPadrao)}
                  onChange={(e) => setEtapaPadrao(JSON.parse(e.target.value))}
                >
                  {steps.map((el) => {
                    var opt = {
                      KANBAN_NOME: el.KANBAN_NOME,
                      KANBAN_ID: el.KANBAN_ID ? el.KANBAN_ID : '',
                    }
                    return (
                      <option value={JSON.stringify(opt)}>
                        {el.KANBAN_NOME.toUpperCase()}
                      </option>
                    )
                  })}
                </SelectFiltroOpo>
              </div>
            </>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '5px',
            }}
          >
            <ButtonSalvarSenha
              onClick={() => {
                handleSubmit()
              }}
              disabled={steps.length == 0}
            >
              Salvar alterações
            </ButtonSalvarSenha>
          </div>
        </div>
      </ModalBoxPlanos>
    </BackgroundModal>
  )
}
