import styled from 'styled-components'
import { COLORS } from '../../../../../colors'

export const BackgroundModal = styled.div`
  align-items: center;
  justify-content: center;
  overflow: hidden;

  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100%;
  max-width: 100vw;

  padding: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 99;

  box-sizing: border-box;
`

export const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 9fr;

  background-color: ${COLORS.light};
  width: 100%;
  height: 100%;
  border-radius: 6px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);

  box-sizing: border-box;

  > img {
    position: absolute;
    top: 50px;
    right: 50px;

    opacity: 0.7;

    cursor: pointer;
  }
`
