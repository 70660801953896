import styled from 'styled-components'
import { COLORS } from '../../colors'

export const BackgroundModal = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  margin: 0;
  padding: 0;
  z-index: 99;
`

export const BoxResumo = styled.div`
  background-color: ${COLORS.light};
  width: 700px;
  border-radius: 6px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  padding: 30px 40px;

  p {
    margin: 1.5rem 0;
    font-style: italic;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      padding: 8px;
      background-color: ${COLORS.primary};
      color: white;
      border-radius: 6px;
      width: 46px;
      height: 46px;

      font-weight: bold;
      font-size: large;

      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;

      cursor: pointer;

      &:hover {
        border: 1px solid ${COLORS.primary};
      }
    }
  }

  .layout_button {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    button {
      text-align: center;
      display: inline-block;
      padding: 0.5rem 0.75rem;
      border: none;
      color: white;
      font-size: 14px;
      font-weight: 600;
      border-radius: 4px;
      background: #1768e4;
    }
  }

  .numbers_orders {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
