export const handleClose = () => {
  sessionStorage.removeItem('VEI_ID')
  sessionStorage.removeItem('PLANOS')
  sessionStorage.removeItem('VEI_PLACA')
  sessionStorage.removeItem('VEI_SEGMENTO')
  sessionStorage.removeItem('CLI_ID')
  sessionStorage.removeItem('PLACA')
  sessionStorage.removeItem('ADICIONAIS')
  sessionStorage.removeItem('VEI_VALOR_FIPE')
}
