import { BackgroundModal, Container, ContentModal } from './styles'
import IconAlert from '../../../../Home/assets/alerta.svg'
import { parametrosRepository } from '../../../../../api/repositories/parametrosRepository'

export function ModalArchive({
  openModal,
  setOpenModal,
  funilData,
  alteracaoFeita,
}) {
  console.log(funilData)
  async function handleArquivarOuDesarquivar() {
    try {
      await parametrosRepository.arquivarOuDesarquivarFunil(funilData.FUN_ID)

      setOpenModal(false)
      alteracaoFeita(true)
    } catch (error) {
      console.log(error)
      setOpenModal(false)
    }
  }

  return (
    <BackgroundModal open={openModal} onClose={() => setOpenModal(false)}>
      <ContentModal>
        <Container>
          <div className="SettingsUsersModal__Archive--Container">
            <img src={IconAlert} alt="icone de alerta" />
            <h1 className="SettingsUsersModal__Archive--Title">
              {funilData.FUN_ATIVO ? 'ARQUIVAR' : 'DESARQUIVAR'} REGISTRO?
            </h1>
            <p className="SettingsUsersModal__Archive--Subtitle">
              <span>
                O registro {funilData.FUN_ATIVO ? 'não' : ''} poderá ser
                utilizado após
                {funilData.FUN_ATIVO ? ' arquivado' : ' desarquivado'}!
              </span>
              <span>
                Tem certeza que deseja{' '}
                {funilData.FUN_ATIVO ? 'arquivar' : 'desarquivar'}?
              </span>
            </p>
            <div className="SettingsUsersModal__Archive--Buttons">
              <button
                style={{ border: 'none' }}
                onClick={() => setOpenModal(false)}
              >
                NÃO
              </button>
              <button
                style={{ border: 'none' }}
                onClick={handleArquivarOuDesarquivar}
              >
                SIM
              </button>
            </div>
          </div>
        </Container>
      </ContentModal>
    </BackgroundModal>
  )
}
