import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {
  Table,
  CardTable,
  CardTableHeaderNoTitle,
  TableSearch,
  TableSearchButton,
  SearchWrapper,
  TableButton,
  TableALink,
  ButtonInsideWrapper,
} from './styles/styledDataTable'
import ResumoTable from './ResumoTable/ResumoTable'
import { FaSearch } from 'react-icons/fa'
import { AiFillFilePdf, AiOutlineShareAlt } from 'react-icons/ai'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'
import { BsGrid3X3GapFill } from 'react-icons/bs'
import { BiWindowOpen } from 'react-icons/bi'
import Endor from './assets/Endor'
import './assets/endor-icon.css'
import 'tippy.js/dist/tippy.css'
import { COLORS } from '../../../../../colors'
import Loading from '../../../../Loading/Loading'
import ReactPaginate from 'react-paginate'
import './styles/paginationStyle.css'
import './styles/dropdownStyle.css'
import Dropdown from 'react-bootstrap/Dropdown'
import Vistoria from './Vistoria/Vistoria'
// import enviarPDF from "../../../../../utils/enviarPDF.js";
import checarStatusOportunidade from '../../../../../utils/checarStatusOportunidade.js'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from 'react-router-dom'
import { messageWpp } from '../messageWpp'
import { oportunidadeRepository } from '../../../../../api/repositories/oportunidadesRepository.js'
import { statusRepository } from '../../../../../api/repositories/statusRepository.js'

function DataTable({ FUN_ID, filterStatus }) {
  const [oportunidades, setOportunidades] = useState([])
  const [pesquisa, setPesquisa] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [dataResumo, setDataResumo] = useState({})
  const [loading, setLoading] = useState(true)
  const [vistoria, setVistoria] = useState(false)
  const [filterParam, setFilterParam] = useState('')

  const VEN_ID = sessionStorage.getItem('VEN_ID')

  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  const MySwal = withReactContent(Swal)
  const navigate = useNavigate()

  useEffect(() => {
    setFilterParam(filterStatus)
  }, [filterStatus])

  // pegar as oportunidades
  useEffect(() => {
    setLoading(true)

    const listarOportunidades = async () => {
      try {
        const data = await oportunidadeRepository.listarOportunidades(
          VEN_ID,
          FUN_ID,
        )
        // console.log('LISTAR OPORTUNIDADES: ', data)
        setLoading(false)
        setOportunidades(data)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    listarOportunidades()

    /*
    axios
      .get(
        `https://kaminocrm.com.br:7778/oportunidades/listar-oportunidades/${VEN_ID}/${FUN_ID}`,
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        },
      )
      .then((res) => {
        // console.log(res.data)
        setLoading(false)
        setOportunidades(res.data)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
    */
  }, [FUN_ID])

  function checarStatusVistoria() {
    const verificarStatus = async () => {
      try {
        await statusRepository.verificarStatus()
        setVistoria(true)
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
        MySwal.fire({
          title: 'Atenção!',
          text: 'Serviço indisponível no momento. Por favor, tente mais tarde.',
          icon: 'warning',
          confirmButtonText: 'Ok',
        }).then((res) => {
          if (res.isConfirmed) {
            navigate('/home/oportunidades')
          } else {
            // not clicked
          }
        })
      }
    }

    verificarStatus()
    /*
    axios
      .get(`https://kaminocrm.com.br:7778/status/verificarStatus/`, {
        headers: {
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        setVistoria(true)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        MySwal.fire({
          title: 'Atenção!',
          text: 'Serviço indisponível no momento. Por favor, tente mais tarde.',
          icon: 'warning',
          confirmButtonText: 'Ok',
        }).then((res) => {
          if (res) {
            navigate('/home/oportunidades')
          } else {
            // not clicked
          }
        })
      })
    */
  }

  //cores por status
  function corStatus(OPO_STATUS) {
    if (OPO_STATUS === 'PERDIDA') {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.perdida}`,
            padding: '2px 10%',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          {OPO_STATUS}
        </span>
      )
    }
    if (OPO_STATUS === 'PENDENTE') {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.pendente}`,
            padding: '2px 7%',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          {OPO_STATUS}
        </span>
      )
    }
    if (OPO_STATUS === 'CONVERTENDO') {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.vistoria}`,
            padding: '2px 9%',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          CONVERTENDO
        </span>
      )
    }
    if (OPO_STATUS === 'APROVADA') {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.aprovada}`,
            padding: '2px 11%',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          APROVADA
        </span>
      )
    }

    if (OPO_STATUS === 'CONVERTIDA') {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.convertida}`,
            padding: '2px 5%',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          CONVERTIDA
        </span>
      )
    }

    if (OPO_STATUS === 'SEM CONTATO') {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.semContato}`,
            padding: '2px 5%',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          SEM CONTATO
        </span>
      )
    }
  }

  // keys para filtro de pesquisa
  const keys = [
    'CLI_NOME',
    'CLI_TELEFONE',
    'CLI_EMAIL',
    'VEI_PLACA',
    'OPO_STATUS',
    'VEN_NOME',
    'OPO_DATA_CADASTRO',
    'OPO_ID_INTEGER',
  ]

  const OptionsButton = ({ i }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          id={'options'}
          // @ts-ignore
          variant="borderless-dark"
          bsPrefix="no-chevron"
          size="sm"
          disabled={openModal ? true : false}
          // onClick={() => console.log(i)}
        >
          <BsGrid3X3GapFill size={21} />
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ willChange: 'transform' }}>
          <TableALink
            className={`dropdown-item ${checarStatusOportunidade(i.OPO_STATUS) ? 'not-allowed' : ''}`}
            disabled={checarStatusOportunidade(i.OPO_STATUS)}
            target="_blank"
            href={`https://kaminocrm.com.br:9898/${ASS_COD}/${i.OPO_ID}`}
            style={{
              pointerEvents: `${checarStatusOportunidade(i.OPO_STATUS) ? 'none' : ''}`,
              opacity: `${checarStatusOportunidade(i.OPO_STATUS) ? '0.6' : '1'}`,
            }}
          >
            <ButtonInsideWrapper>
              <AiFillFilePdf size={20} /> <span>Visualizar Proposta</span>
            </ButtonInsideWrapper>
          </TableALink>

          <TableButton
            className="dropdown-item"
            disabled={checarStatusOportunidade(i.OPO_STATUS)}
            onClick={() => {
              window.scrollTo(0, 0)
              setLoading(true)
              setTimeout(function () {
                window.open(
                  `https://wa.me/+55${i.CLI_TELEFONE}?text=${messageWpp(i.EMP_ASSOCIACAO, i.VEN_NOME, ASS_COD, i.OPO_ID)}`,
                  'response',
                  'resizable=yes',
                )
                setLoading(false)
              }, 1000)
            }}
          >
            <ButtonInsideWrapper>
              <AiOutlineShareAlt size={20} />{' '}
              <span style={{ fontSize: '14px' }}>
                Compartilhar oportunidade
              </span>
            </ButtonInsideWrapper>
          </TableButton>

          {/* <TableButton
            className="dropdown-item"
            disabled={
              checarStatusOportunidade(i.OPO_STATUS) || i.OPO_STATUS == "VISTORIAS"
                ? true
                : false
            }
            onClick={() => {
              checarStatusVistoria();
              setLoading(true)
              setDataResumo(i.OPO_ID);
            }}
          >
            <ButtonInsideWrapper>
              <Endor size={17} /> <span>Gerar Vistoria</span>
            </ButtonInsideWrapper>
          </TableButton> */}

          <TableButton
            className="dropdown-item"
            onClick={() => {
              setOpenModal(true)
              setDataResumo(i.OPO_ID)
              window.scrollTo(0, 0)
            }}
          >
            <ButtonInsideWrapper>
              <BiWindowOpen size={20} />{' '}
              <span style={{ fontSize: '14px' }}>Resumo da oportunidade</span>
            </ButtonInsideWrapper>
          </TableButton>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  // *** --- PAGINAÇÃO --- *** //
  const [numeroPagina, setNumeroPagina] = useState(0)
  const itensPorPagina = 25
  const paginasVisitadas = numeroPagina * itensPorPagina

  function displayOportunidades(items) {
    return items
      .slice(paginasVisitadas, paginasVisitadas + itensPorPagina)
      .filter((item) => {
        if (item.OPO_STATUS == filterParam) {
          return keys.some((newItem) => {
            return (
              item[newItem]
                .toString()
                .toLowerCase()
                .indexOf(pesquisa.toLowerCase()) > -1
            )
          })
        } else if (filterParam == 'TODAS') {
          return keys.some((newItem) => {
            return (
              item[newItem]
                .toString()
                .toLowerCase()
                .indexOf(pesquisa.toLowerCase()) > -1
            )
          })
        }
      })
  }

  const pageCount = Math.ceil(oportunidades.length / itensPorPagina)

  const mudarPagina = ({ selected }) => {
    setNumeroPagina(selected)
  }

  return (
    <>
      {loading ? <Loading /> : null}

      {vistoria && <Vistoria isVisible={setVistoria} OPO_ID={dataResumo} />}

      <div
        style={{
          padding: '0px 20px 1em 20px',
          backgroundImage:
            'linear-gradient(white, #f3f3f3, #eeeeee, #eeeeee, white)',
        }}
      >
        {openModal && (
          <ResumoTable closeModal={setOpenModal} OPO_ID={dataResumo} />
        )}

        <CardTable>
          <CardTableHeaderNoTitle>
            <SearchWrapper>
              <TableSearch
                type="text"
                name="search"
                id="search"
                placeholder="Pesquisa..."
                value={pesquisa}
                onChange={(e) => {
                  setPesquisa(e.target.value)
                }}
              />
              <TableSearchButton>
                <FaSearch />
              </TableSearchButton>
            </SearchWrapper>
            {/* <div className="select">
              <span className="label">
                <span className="icon">
                  <FaFilter size={15} />
                </span>
                <strong>Filtrar por Status:</strong>
              </span>

              <select
                onChange={(e) => {
                  setFilterParam(e.target.value);
                }}
                className="custom-select"
              >
                <option value="All">TODAS</option>
                <option value="PENDENTE">PENDENTES</option>
                <option value="PERDIDA">PERDIDAS</option>
                <option value="CONVERTIDA">CONVERTIDA</option>
              </select>
            </div> */}
          </CardTableHeaderNoTitle>
          <Table>
            <thead>
              <tr>
                <th>Número</th>
                <th>Status</th>
                <th>Nome do Contato</th>
                <th>Placa do Veículo</th>
                <th>Data de Criação</th>
                <th>Nº de Telefone</th>
                <th>E-mail</th>
                <th>Consultor responsável</th>
                <th style={{ textAlign: 'center' }}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {displayOportunidades(oportunidades).map((i) => {
                return (
                  <tr key={i.OPO_ID}>
                    <td>{i.OPO_ID_INTEGER}</td>
                    <td>{corStatus(i.OPO_STATUS)}</td>
                    {/* <td>{i.OPO_STATUS}</td> */}
                    <td>{i.CLI_NOME.toUpperCase()}</td>
                    <td
                      style={{
                        textTransform: 'uppercase',
                        paddingLeft: '2.2rem',
                      }}
                    >
                      {i.VEI_PLACA}
                    </td>
                    <td style={{ paddingLeft: '2rem' }}>
                      {i.OPO_DATA_CADASTRO}
                    </td>
                    <td>
                      {i.CLI_TELEFONE.replace(/\D/g, '')
                        .replace(/^(\d{2})(\d)/g, '($1) $2')
                        .replace(/(\d)(\d{4})$/, '$1-$2')}
                    </td>
                    <td>{i.CLI_EMAIL}</td>
                    <td>{i.VEN_NOME}</td>
                    <td id="opcoes_oportunidade">
                      <OptionsButton i={i} />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </CardTable>

        <ReactPaginate
          previousLabel={<IoIosArrowBack />}
          nextLabel={<IoIosArrowForward />}
          pageCount={pageCount}
          onPageChange={mudarPagina}
          containerClassName={'paginationContainer'}
          pageLinkClassName={'Buttons'}
          previousLinkClassName={'AnteriorBttn'}
          nextLinkClassName={'ProximoBttn'}
          disabledClassName={'paginationDisabled'}
          activeClassName={'paginationActive'}
        />
      </div>
    </>
  )
}

export default DataTable
