import styled from 'styled-components'
import lupaIcon from '../../../../../Home/assets/icon-lupa.svg'
import { RadioGroup } from '@headlessui/react'
import { FaRegCheckCircle } from 'react-icons/fa'

export const FunilSelectContainer = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 40px;

  h3 {
    margin: 0 0 2rem;
  }

  h4 {
    margin: 2rem 0;
  }
`

export const FunilSelectContent = styled.div``

export const InputsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  input {
    border: 1px solid rgb(240, 240, 240);
    height: 35px;
    background-color: rgb(240, 240, 240);

    border-radius: 8px;
    outline: none;
    width: 300px;
    box-sizing: border-box;
  }

  div {
    display: flex;
    flex-direction: column;

    gap: 4px;
  }

  form {
    display: flex;
    align-items: flex-end;

    input {
      line-height: 2rem;

      outline: none;

      background-image: url(${lupaIcon});
      background-position: 10px center;
      background-repeat: no-repeat;
      text-indent: 1.875rem;
      font-size: 1rem;
    }
  }
`
export const FunilCardContainer = styled(RadioGroup.Option)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-right: 8px;
`

export const FunilCard = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background-color: #f0f0f0;

  font-size: small;

  font-weight: 600;

  padding: 0 16px;
  border-radius: 8px;

  box-sizing: border-box;

  cursor: pointer;

  border: 1px solid ${(props) => (props.checked ? '#00B065' : 'none')};
  background-color: ${(props) => (props.checked ? '#c0f7e9' : 'none')};
`

export const NextStep = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    width: 100px;
    height: 30px;
    font-size: 1rem;
    border-radius: 8px;
    border: none;
    font-weight: 600;
    color: #fff;
    background-color: #1768e4;
  }
`

export const FunilContent = styled(RadioGroup)`
  overflow: auto;
  height: 550px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
