import { useEffect, useState } from 'react'
import { COLORS } from '../../../../../colors'
import Loading from '../../../../Loading/Loading'
import {
  ButtonInsideWrapper,
  TableALink,
  TableButton,
} from './styles/styledDataTable'
import Vistoria from './Vistoria/Vistoria'
import { BsGrid3X3GapFill } from 'react-icons/bs'
import checarStatusOportunidade from '../../../../../utils/checarStatusOportunidade'
import Dropdown from 'react-bootstrap/Dropdown'
import { AiFillFilePdf, AiOutlineShareAlt } from 'react-icons/ai'
import { messageWpp } from '../messageWpp'
import { BiWindowOpen } from 'react-icons/bi'
import { oportunidadeRepository } from '../../../../../api/repositories/oportunidadesRepository'
import {
  DropdownMenuContainer,
  MainTableContainer,
  SectionTableContainer,
  SpanStatusTable,
  TableBody,
  TableContainer,
  TableHeader,
} from './stylesTableData'
import { Paginator } from '../../../../../components/Paginator'
import ResumoTable from './ResumoTable/ResumoTable'
import { LineWeight } from '@material-ui/icons'
// import './styles/dropdownStyle.css'
// import './styles/tableData.css'

export function TableData({
  FUN_ID,
  filterStatus,
  pesquisa,
  numeroPagina,
  setNumeroPagina,
}) {
  const ASS_COD = sessionStorage.getItem('ASS_COD')
  const VEN_ID = sessionStorage.getItem('VEN_ID')

  const [oportunidades, setOportunidades] = useState([])
  const [vistoria, setVistoria] = useState(false)
  const [loading, setLoading] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [dataResumo, setDataResumo] = useState({})
  const [filterParam, setFilterParam] = useState('')
  const [page, setPage] = useState(1)

  useEffect(() => {
    setFilterParam(filterStatus)
  }, [filterStatus])

  useEffect(() => {
    setLoading(true)

    listarOportunidades()
  }, [FUN_ID])

  const handlePageChange = (event, value) => {
    setNumeroPagina(value - 1) // value vem como 1-indexado, por isso subtraímos 1
    setPage(value)
  }

  const listarOportunidades = async () => {
    try {
      const data = await oportunidadeRepository.listarOportunidades(
        VEN_ID,
        FUN_ID,
      )
      // console.log('LISTAR OPORTUNIDADES: ', data)
      setLoading(false)
      setOportunidades(data)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  function corStatus(OPO_STATUS) {
    if (OPO_STATUS === 'PERDIDA') {
      return (
        <SpanStatusTable
          style={{
            backgroundColor: `${COLORS.perdida}`,
          }}
        >
          {OPO_STATUS}
        </SpanStatusTable>
      )
    }
    if (OPO_STATUS === 'PENDENTE') {
      return (
        <SpanStatusTable
          style={{
            backgroundColor: `${COLORS.pendente}`,
          }}
        >
          {OPO_STATUS}
        </SpanStatusTable>
      )
    }
    if (OPO_STATUS === 'CONVERTENDO') {
      return (
        <SpanStatusTable
          style={{
            backgroundColor: `${COLORS.vistoria}`,
          }}
        >
          CONVERTENDO
        </SpanStatusTable>
      )
    }
    if (OPO_STATUS === 'APROVADA') {
      return (
        <SpanStatusTable
          style={{
            backgroundColor: `${COLORS.aprovada}`,
          }}
        >
          APROVADA
        </SpanStatusTable>
      )
    }

    if (OPO_STATUS === 'CONVERTIDA') {
      return (
        <SpanStatusTable
          style={{
            backgroundColor: `${COLORS.convertida}`,
          }}
        >
          CONVERTIDA
        </SpanStatusTable>
      )
    }

    if (OPO_STATUS === 'SEM CONTATO') {
      return (
        <SpanStatusTable
          style={{
            backgroundColor: `${COLORS.semContato}`,
          }}
        >
          SEM CONTATO
        </SpanStatusTable>
      )
    }
  }

  // keys para filtro de pesquisa
  const keys = [
    'CLI_NOME',
    'CLI_TELEFONE',
    'CLI_EMAIL',
    'VEI_PLACA',
    'OPO_STATUS',
    'VEN_NOME',
    'OPO_DATA_CADASTRO',
    'OPO_ID_INTEGER',
  ]

  const ButtonDropdownMenu = ({ i }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle>
          <BsGrid3X3GapFill size={21} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.9375rem',
            }}
          >
            <TableALink
              className={`dropdown-item ${checarStatusOportunidade(i.OPO_STATUS) ? 'not-allowed' : ''}`}
              disabled={checarStatusOportunidade(i.OPO_STATUS)}
              target="_blank"
              href={`https://kaminocrm.com.br:9898/${ASS_COD}/${i.OPO_ID}`}
              style={{
                pointerEvents: `${checarStatusOportunidade(i.OPO_STATUS) ? 'none' : ''}`,
                opacity: `${checarStatusOportunidade(i.OPO_STATUS) ? '0.6' : '1'}`,
              }}
            >
              <ButtonInsideWrapper>
                <AiFillFilePdf size={20} /> <span>Visualizar Proposta</span>
              </ButtonInsideWrapper>
            </TableALink>

            <TableButton
              className="dropdown-item"
              disabled={checarStatusOportunidade(i.OPO_STATUS)}
              onClick={() => {
                window.scrollTo(0, 0)
                setLoading(true)
                setTimeout(function () {
                  window.open(
                    `https://wa.me/+55${i.CLI_TELEFONE}?text=${messageWpp(i.EMP_ASSOCIACAO, i.VEN_NOME, ASS_COD, i.OPO_ID)}`,
                    'response',
                    'resizable=yes',
                  )
                  setLoading(false)
                }, 1000)
              }}
            >
              <ButtonInsideWrapper>
                <AiOutlineShareAlt size={20} />{' '}
                <span style={{ fontSize: '14px' }}>
                  Compartilhar oportunidade
                </span>
              </ButtonInsideWrapper>
            </TableButton>

            <TableButton
              className="dropdown-item"
              onClick={() => {
                setOpenModal(true)
                setDataResumo(i.OPO_ID)
                window.scrollTo(0, 0)
              }}
            >
              <ButtonInsideWrapper>
                <BiWindowOpen size={20} />{' '}
                <span style={{ fontSize: '14px' }}>Resumo da oportunidade</span>
              </ButtonInsideWrapper>
            </TableButton>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  // *** --- PAGINAÇÃO --- *** //

  // const [numeroPagina, setNumeroPagina] = useState(0)
  const itensPorPagina = 10
  const paginasVisitadas = numeroPagina * itensPorPagina

  const filteredOportunidades = oportunidades.filter((item) => {
    if (filterParam === 'TODAS' || item.OPO_STATUS === filterParam) {
      return keys.some((key) =>
        item[key].toString().toLowerCase().includes(pesquisa.toLowerCase()),
      )
    }
    return false
  })

  const pageCount = Math.ceil(filteredOportunidades.length / itensPorPagina)

  function displayOportunidades(items) {
    const filteredItems = items.filter((item) => {
      if (filterParam === 'TODAS' || item.OPO_STATUS === filterParam) {
        return keys.some((key) =>
          item[key].toString().toLowerCase().includes(pesquisa.toLowerCase()),
        )
      }
      return false
    })

    return filteredItems.slice(
      paginasVisitadas,
      paginasVisitadas + itensPorPagina,
    )
  }

  const mudarPagina = ({ selected }) => {
    setNumeroPagina(selected)
  }

  return (
    <SectionTableContainer>
      {loading ? <Loading /> : null}

      {vistoria && <Vistoria isVisible={setVistoria} OPO_ID={dataResumo} />}

      {openModal && (
        <ResumoTable closeModal={setOpenModal} OPO_ID={dataResumo} />
      )}
      <MainTableContainer>
        <TableContainer>
          <TableHeader>
            <tr>
              <th>
                <span>Número</span>
              </th>
              <th>
                <span>Nome do Contato</span>
              </th>
              <th>
                <span>Placa</span>
              </th>
              <th>
                <span>Data de Criação</span>
              </th>
              <th>
                <span>Nº de Telefone</span>
              </th>
              <th>
                <span>E-mail</span>
              </th>
              <th>
                <span>Consultor</span>
              </th>
              <th>
                <span>Status</span>
              </th>
              <th style={{ textAlign: 'center' }}>Ações</th>
            </tr>
          </TableHeader>
          <TableBody>
            {displayOportunidades(filteredOportunidades).length > 0 ? (
              displayOportunidades(filteredOportunidades).map((item) => (
                <tr key={item.OPO_ID}>
                  <td>{item.OPO_ID_INTEGER}</td>
                  <td>{item.CLI_NOME.toUpperCase()}</td>
                  <td>{item.VEI_PLACA}</td>
                  <td>{item.OPO_DATA_CADASTRO}</td>
                  <td>
                    {item.CLI_TELEFONE.replace(/\D/g, '')
                      .replace(/^(\d{2})(\d)/g, '($1) $2')
                      .replace(/(\d)(\d{4})$/, '$1-$2')}
                  </td>
                  <td>{item.CLI_EMAIL}</td>
                  <td>{item.VEN_NOME}</td>
                  <td>
                    <div>{corStatus(item.OPO_STATUS)}</div>
                  </td>
                  <td>
                    <div>
                      <ButtonDropdownMenu i={item} />
                      {/* <OptionsButton i={item} onClick={() => console.log(item)} /> */}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" style={{ textAlign: 'center' }}>
                  Sem oportunidades para mostrar
                </td>
              </tr>
            )}
          </TableBody>
        </TableContainer>
      </MainTableContainer>
      <Paginator
        count={pageCount}
        page={page}
        handleChange={handlePageChange}
      />
    </SectionTableContainer>
  )
}
