import { api, createAPI } from '../api'
import { ENDPOINTS } from '../endPoints'

export const oportunidadeRepository = {
  listarTiposTarefas: async () => {
    const api = createAPI(7778)
    try {
      const response = await api.get(ENDPOINTS.OPORTUNIDADE.LISTAR_TIPOS_TAREFA)

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  listarTiposOrigens: async () => {
    const api = createAPI(7778)
    try {
      const response = await api.get(
        ENDPOINTS.OPORTUNIDADE.LISTAR_TIPOS_ORIGENS,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  listarOportunidadesKanban: async (VEN_ID, FUN_ID, filterStatus) => {
    const api = createAPI(7778)
    try {
      const response = await api.get(
        `${ENDPOINTS.OPORTUNIDADE.LISTAR_OPORTUNIDADES_KANBAN}/${VEN_ID}/${FUN_ID}/${filterStatus}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  listarFunisOportunidade: async (VEN_ID) => {
    const api = createAPI(7778)

    try {
      const response = await api.get(
        `${ENDPOINTS.OPORTUNIDADE.LISTAR_FUNIS_OPORTUNIDADES}?VEN_ID=${VEN_ID}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  listarOportunidades: async (VEN_ID, FUN_ID) => {
    const api = createAPI(7778)

    try {
      const response = await api.get(
        `${ENDPOINTS.OPORTUNIDADE.LISTAR_OPORTUNIDADES}/${VEN_ID}/${FUN_ID}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  listarDashboardOportunidades: async (PER_ID, VEN_ID, FILHOS) => {
    const api = createAPI(7778)

    try {
      const response = await api.get(
        `${ENDPOINTS.OPORTUNIDADE.DASHBOARD_OPORTUNIDADES}?PER_ID=${PER_ID}&VEN_ID=${VEN_ID}&FILHOS=${FILHOS}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  listarOportunidadeWithOpoID: async (OPO_ID) => {
    const api = createAPI(7778)

    try {
      const response = await api.get(
        `${ENDPOINTS.OPORTUNIDADE.LISTAR_OPORTUNIDADES_OPOID}/${OPO_ID}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  verificarDescontoVendedor: async (VEN_ID, VEI_SEGMENTO) => {
    const api = createAPI(7778)

    try {
      const response = await api.get(
        `${ENDPOINTS.OPORTUNIDADE.VERIFICAR_DESCONTO_VENDEDOR}?ven_id=${VEN_ID}&tipo_veiculo=${VEI_SEGMENTO}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  validarValorFiliacaoDesconto: async (
    fil_ven_id,
    fil_tipo_veiculo,
    valor_proposta,
    valor_plano,
    VEN_ID,
  ) => {
    const api = createAPI(7778)

    try {
      const response = await api.get(
        `${ENDPOINTS.OPORTUNIDADE.VALIDAR_VALOR_FILIACAO_DESCONTO}?fil_ven_id=${fil_ven_id}&fil_tipo_veiculo=${fil_tipo_veiculo}&valor_proposta=${valor_proposta}&valor_plano=${valor_plano}`,
        {
          headers: {
            ven_id: VEN_ID,
          },
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  validarValorCotaParticipacaoDesconto: async (
    cot_venId,
    cot_tipo_veiculo,
    valor_acordado,
    porcentagem,
    VEN_ID,
  ) => {
    const api = createAPI(7778)

    try {
      const response = await api.get(
        `${ENDPOINTS.OPORTUNIDADE.VALIDAR_VALOR_COTA_PARTICIPACAO_DESCONTO}?cot_venId=${cot_venId}&cot_tipo_veiculo=${cot_tipo_veiculo}&valor_acordado=${valor_acordado}&porcentagem=${porcentagem}`,
        {
          headers: {
            ven_id: VEN_ID,
          },
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  validarValorMensalidadeDesconto: async (
    men_venid,
    men_tipo_veiculo,
    valor_proposta,
    valor_mensalidade,
    VEN_ID,
  ) => {
    const api = createAPI(7778)

    try {
      const response = await api.get(
        `${ENDPOINTS.OPORTUNIDADE.VALIDAR_VALOR_MENSALIDADE_DESCONTO}?men_venid=${men_venid}&men_tipo_veiculo=${men_tipo_veiculo}&valor_proposta=${valor_proposta}&valor_mensalidade=${valor_mensalidade}`,
        {
          headers: {
            ven_id: VEN_ID,
          },
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  converterOportunidade: async (OPO_ID) => {
    const api = createAPI(7778)

    try {
      const response = await api.post(
        `${ENDPOINTS.OPORTUNIDADE.CONVERTER_OPORTUNIDADE}`,
        {
          opo_id: OPO_ID,
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  novaTarefaOportunidade: async (
    OPO_ID,
    TAREFA_ID,
    DATA_MARCADA,
    TAREFA_DESCRICAO,
    TAREFA_OBSERVACAO,
  ) => {
    const api = createAPI(7778)

    try {
      const response = await api.post(
        `${ENDPOINTS.OPORTUNIDADE.CRIAR_TAREFA_OPORTUNIDADE}`,
        {
          OPO_ID: OPO_ID,
          TAREFA_ID: TAREFA_ID,
          DATA_MARCADA: DATA_MARCADA,
          TAREFA_DESCRICAO: TAREFA_DESCRICAO,
          TAREFA_OBSERVACAO: TAREFA_OBSERVACAO,
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  registrarTarefaRealizada: async (task, status) => {
    const api = createAPI(7778)

    try {
      const response = await api.post(
        `${ENDPOINTS.OPORTUNIDADE.REGISTRAR_TAREFA_REALIZADA}`,
        {
          TAREFAOPO_ID: task,
          STATUS: status,
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  atualizarOportunidadeStatus: async (destination, source, fun_id, opo_id) => {
    const api = createAPI(7778)

    try {
      const response = await api.post(
        `${ENDPOINTS.OPORTUNIDADE.ATUALIZAR_OPORTUNIDADE_STATUS}`,
        {
          destination: destination,
          source: source,
          fun_id: fun_id,
          opo_id: opo_id,
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  editarOportunidade: async (OPO_ID, OPO_STATUS) => {
    const api = createAPI(7778)

    try {
      const response = await api.post(
        `${ENDPOINTS.OPORTUNIDADE.UPDATE_OPORTUNIDADE}`,
        {
          OPO_ID: OPO_ID,
          OPO_STATUS: OPO_STATUS,
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  criarOportunidade: async (DADOS) => {
    const api = createAPI(7778)

    try {
      const response = await api.post(
        `${ENDPOINTS.OPORTUNIDADE.CRIAR_OPORTUNIDADE}`,
        DADOS,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}
