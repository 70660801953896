export const OrigemLeadSvg = (props) => (
  <svg
    width="15"
    height="19"
    viewBox="0 0 15 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.648 18.195c1.18 0 2.18-.765 2.532-1.828H10c2.64 0 4.32-1.383 4.32-3.57 0-2.235-1.64-3.688-4.804-4.016l-4.5-.469C2.648 8.07 1.523 7.211 1.523 5.93c0-1.266 1.04-2.04 2.782-2.04h4.804a2.66 2.66 0 0 0 2.555 1.946 2.656 2.656 0 0 0 2.656-2.664 2.656 2.656 0 0 0-5.18-.813H4.306c-2.633 0-4.313 1.383-4.313 3.57 0 2.235 1.64 3.696 4.79 4.016l4.507.469c2.367.242 3.5 1.102 3.5 2.383 0 1.265-1.047 2.039-2.789 2.039H5.211a2.662 2.662 0 0 0-2.563-1.945 2.652 2.652 0 0 0 0 5.305zm9.016-13.797c-.68 0-1.219-.539-1.219-1.226 0-.68.54-1.211 1.22-1.211.687 0 1.218.523 1.218 1.21 0 .688-.531 1.227-1.219 1.227zM2.648 16.766c-.68 0-1.218-.54-1.218-1.227s.539-1.21 1.218-1.21c.696 0 1.227.523 1.227 1.21a1.21 1.21 0 0 1-1.227 1.227z"
      fill="#353535"
      fillRule="nonzero"
    />
  </svg>
)

export const OrigemLeadSvgSelected = (props) => (
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.89 18.531a2.9 2.9 0 0 0 2.657-1.742h4.969c2.828 0 4.664-1.508 4.664-3.898 0-2.43-1.782-4.008-5.172-4.352l-4.57-.477c-2.399-.25-3.43-1.03-3.43-2.226 0-1.188.937-1.883 2.64-1.883h4.868a2.9 2.9 0 0 0 5.664-.867 2.897 2.897 0 0 0-5.555-1.149H4.648c-2.828 0-4.656 1.508-4.656 3.899 0 2.43 1.774 4.008 5.156 4.351l4.579.477c2.406.25 3.437 1.031 3.437 2.227 0 1.187-.937 1.882-2.648 1.882h-4.86a2.888 2.888 0 0 0-2.765-2.039A2.894 2.894 0 0 0 0 15.625a2.897 2.897 0 0 0 2.89 2.906z"
      fill="#0049C4"
      fillRule="nonzero"
    />
  </svg>
)
