export const MensagensSvg = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.023 17.063c.438 0 .758-.211 1.297-.68L9 14.008h4.828c2.344 0 3.664-1.344 3.664-3.664V4.28c0-2.32-1.32-3.664-3.664-3.664H3.664C1.313.617-.008 1.961-.008 4.281v6.063c0 2.32 1.352 3.664 3.617 3.664h.422v1.93c0 .695.367 1.125.992 1.125zm.375-1.72v-2.226c0-.46-.187-.64-.632-.64H3.703c-1.476 0-2.18-.743-2.18-2.18V4.328c0-1.437.704-2.18 2.18-2.18h10.078c1.469 0 2.18.743 2.18 2.18v5.969c0 1.437-.711 2.18-2.18 2.18H8.914c-.476 0-.703.078-1.039.421l-2.477 2.446zM4.766 5.259h7.875a.489.489 0 0 0 .484-.492.485.485 0 0 0-.484-.477H4.766a.478.478 0 0 0-.477.477c0 .273.211.492.477.492zm0 2.492h7.875a.494.494 0 0 0 .484-.492.487.487 0 0 0-.484-.485H4.766a.48.48 0 0 0-.477.485c0 .265.211.492.477.492zm0 2.484H9.89a.482.482 0 0 0 .484-.484.477.477 0 0 0-.484-.484H4.766a.475.475 0 0 0-.477.484c0 .266.211.484.477.484z"
      fill="#353535"
      fillRule="nonzero"
    />
  </svg>
)

export const MensagensSvgSelected = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.227 17.312c.351 0 .64-.156 1.07-.539l2.93-2.609h4.937c2.39 0 3.828-1.453 3.828-3.82V4.148c0-2.375-1.437-3.828-3.828-3.828H3.82C1.43.32-.008 1.773-.008 4.148v6.196c0 2.367 1.531 3.82 3.696 3.82h.703v2.227c0 .562.312.921.836.921zM4.89 5.266a.579.579 0 0 1-.563-.579c0-.312.258-.57.563-.57h8.148c.32 0 .578.258.578.57a.582.582 0 0 1-.578.579H4.891zm0 2.523c-.305 0-.563-.273-.563-.586 0-.305.258-.578.563-.578h8.148a.582.582 0 0 1 0 1.164H4.891zm0 2.516a.579.579 0 0 1 0-1.157h5.328c.32 0 .57.258.57.579 0 .312-.25.578-.57.578H4.89z"
      fill="#0049C4"
      fillRule="nonzero"
    />
  </svg>
)
