import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { KanbanContext } from '../../../../../../context/KanbanContext'
import { CadastroAssociado } from '../../../../../../context/CadastroASSContext'
import { CadastroVeiculo } from '../../../../../../context/CadastroVEIContext'

import {
  BackgroundResumo,
  BoxResumo,
  BoxResumoDiv,
  BoxResumoGrid,
  ButtonEncerrar,
} from './styledResumoTable'
import { ModalHeader, X } from '../../Modal/style/styledModal'
import { IoMdClose } from 'react-icons/io'
import { FiAlertTriangle } from 'react-icons/fi'
import Loading from '../../../../../Loading/Loading'
import { COLORS } from '../../../../../../colors'
import { BsCheck2 } from 'react-icons/bs'
import NovaTarefa from './NovaTarefa/NovaTarefa'

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import TabContent from 'react-bootstrap/TabContent'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Vistoria from '../Vistoria/Vistoria'

import './styles/tabsStyle.css'
import { AuthContext } from '../../../../../../context/auth'
import ModalConfirmarPerdida from '../../Kanban/ModalConfirmarPerdida/ModalConfirmarPerdida'
import { oportunidadeRepository } from '../../../../../../api/repositories/oportunidadesRepository'
import { planosRepository } from '../../../../../../api/repositories/planosRepository'

// import 'bootstrap/dist/css/bootstrap.min.css'

function ResumoTable({ closeModal, OPO_ID }) {
  const [resumo, setResumo] = useState([{}])
  const [planos, setPlanos] = useState([])
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [key, setKey] = useState('dados_gerais')
  const [vistoriaModal, setVistoriaModal] = useState(false)
  const [modalConfirmarPerdida, setModalConfirmarPerdida] = useState(false)

  const { setVeiculoExiste } = useContext(KanbanContext)
  const { setCLI_NOME, setCLI_CPF, setCLI_TELEFONE, setCLI_EMAIL } =
    useContext(CadastroAssociado)
  const {
    setVEI_MARCA,
    setVEI_MODELO,
    setVEI_ANO,
    setImplementos,
    setValorTotalImplementos,
  } = useContext(CadastroVeiculo)
  const { PER_ID } = useContext(AuthContext)

  const navigate = useNavigate()

  const MySwal = withReactContent(Swal)

  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')
  const VEN_ID = sessionStorage.getItem('VEN_ID')

  function somarValores(array, chave) {
    let soma = 0
    if (array?.length > 0) {
      for (const objeto of array) {
        let valor = objeto[chave]
        valor = valor.replace('R$', '').replace('.', '')
        if (valor.includes(',')) {
          valor = valor.replace(',', '.')
        }
        valor = Number(valor)

        soma += valor
      }
    } else {
      soma = 0
    }
    console.log(soma)
    setValorTotalImplementos(soma)
    return soma
  }

  useEffect(async () => {
    const listarOportunidadeWithOpoId = async () => {
      try {
        const data =
          await oportunidadeRepository.listarOportunidadeWithOpoID(OPO_ID)

        console.log('LISTAR OPORTUNIDADE WITH OPOID: ', data)
        setResumo(data)
        setPlanos(data[0].DESCRICAO_PLANOS_ID)
        setToken(data[0].TOKEN)
        setLoading(false)
        return data
      } catch (error) {
        console.log('ERROR AO LISTAR OPORTUNIDADE WITH OPOID: ', error)
        setLoading(false)
        closeModal(false)
      }
    }

    listarOportunidadeWithOpoId()

    /*
    await axios
      .get(
        `https://kaminocrm.com.br:7778/oportunidades/listar-oportunidade/${OPO_ID}`,
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        },
        { timeout: 5 * 1000 },
      )
      .then((res) => {
        console.log(res.data)
        setResumo(res.data)
        setPlanos(res.data[0].DESCRICAO_PLANOS_ID)
        setToken(res.data[0].TOKEN)
        setLoading(false)
        return res.data
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        closeModal(false)
      })
    */
  }, [])

  function corStatus(OPO_STATUS) {
    if (OPO_STATUS === 'SEM CONTATO') {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.semContato}`,
            padding: '2px 9px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '13px',
          }}
        >
          {OPO_STATUS}
        </span>
      )
    }
    if (OPO_STATUS === 'PERDIDA') {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.perdida}`,
            padding: '2px 9px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '13px',
          }}
        >
          {OPO_STATUS}
        </span>
      )
    }
    if (OPO_STATUS === 'PENDENTE') {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.pendente}`,
            padding: '2px 5px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '13px',
          }}
        >
          EM ANDAMENTO
        </span>
      )
    }
    if (OPO_STATUS === 'VISTORIAS') {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.vistoria}`,
            padding: '2px 5px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '13px',
          }}
        >
          VISTORIA
        </span>
      )
    }
    if (OPO_STATUS === 'CONVERTIDA') {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.convertida}`,
            padding: '2px 5px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '13px',
          }}
        >
          CONVERTIDA
        </span>
      )
    }
  }

  const continuarOportunidade = (element, type) => {
    setLoading(true)

    if (type === 'vistoria') {
      setVeiculoExiste({
        veiculoBoolean: true,
        opoId: element.OPO_ID,
      })
    }

    if (!element.VEI_PLACA) {
      setCLI_NOME(element.CLI_NOME)
      setCLI_CPF(element.CLI_CPF ? element.CLI_CPF : '')
      setCLI_TELEFONE(element.CLI_TELEFONE)
      setCLI_EMAIL(element.CLI_EMAIL ? element.CLI_EMAIL : '')
      sessionStorage.setItem('CLI_ID', element.CLI_ID)
      navigate('/home/cadastro-placa')
    } else if (element.VEI_PLACA && planos) {
      if (type === 'vistoria') {
        setVistoriaModal(true)
      }
    } else {
      var segmento = element.VEI_TIPO
      if (element.VEI_TIPO == 'CAMINHÃO') {
        segmento = 'CAMINHOES'
      }
      var valor = element.VEI_VALOR_FIPE.replace('R$', '')
      setVEI_MARCA(element.VEI_MARCA)
      setVEI_MODELO(element.VEI_MODELO)
      setVEI_ANO(element.VEI_ANO_MODELO)
      sessionStorage.setItem('VEI_PLACA', element.VEI_PLACA)
      sessionStorage.setItem('VEI_VALOR_FIPE', element.VEI_VALOR_FIPE)
      sessionStorage.setItem('VEI_SEGMENTO', segmento)
      sessionStorage.setItem('CLI_ID', element.CLI_ID)
      sessionStorage.setItem('VEI_ID', element.VEI_ID)

      const buscarPlanosSegmento = async () => {
        try {
          const data = await planosRepository.buscarPlanos(
            segmento,
            valor,
            VEN_ID,
          )

          console.log('BUSCANDO PLANOS SEGMENTO', data)

          if (data?.length == 0) {
            MySwal.fire({
              title: 'Atenção!',
              text: 'Não há planos disponíveis para esse veículo.',
              icon: 'warning',
              confirmButtonText: 'Ok',
            })
          } else {
            if (segmento === 'CAMINHOES') {
              if (element.IMPLEMENTOS === null) {
                navigate('/home/cadastro-implementos')
              } else {
                setImplementos(element.IMPLEMENTOS)
                somarValores(element.IMPLEMENTOS, 'IMP_MENSALIDADE')
                navigate('/home/planos-veiculo')
              }
            } else {
              navigate('/home/planos-veiculo')
            }
          }
          setLoading(false)
        } catch (error) {
          console.log(error)
          setLoading(false)
        }
      }

      buscarPlanosSegmento()

      /*
      axios
        .get(
          `https://kaminocrm.com.br:7778/planos/buscarPlanos?segmento=${segmento}&preco=${valor}`,
          {
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
              ven_id: VEN_ID,
            },
          },
        )
        .then((res) => {
          console.log('BUSCANDO PLANOS SEGMENTO', res)

          if (res.data?.length == 0) {
            MySwal.fire({
              title: 'Atenção!',
              text: 'Não há planos disponíveis para esse veículo.',
              icon: 'warning',
              confirmButtonText: 'Ok',
            })
          } else {
            if (segmento === 'CAMINHOES') {
              if (element.IMPLEMENTOS === null) {
                navigate('/home/cadastro-implementos')
              } else {
                setImplementos(element.IMPLEMENTOS)
                somarValores(element.IMPLEMENTOS, 'IMP_MENSALIDADE')
                navigate('/home/planos-veiculo')
              }
            } else {
              navigate('/home/planos-veiculo')
            }
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        })
      */
    }
  }

  const alterarStatusOportunidade = async (OPO_ID) => {
    try {
      await oportunidadeRepository.converterOportunidade(OPO_ID)

      console.log('ALTERANDO STATUS OPORTUNIDADE')
      closeModal(false)
      window.location.reload()
    } catch (error) {
      console.log(error)
    }

    /*
    axios
      .post(
        'https://kaminocrm.com.br:7778/oportunidades/converterOportunidade',
        {
          opo_id: OPO_ID,
        },
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        },
      )
      .then((res) => {
        closeModal(false)
        window.location.reload()
      })
      .catch((err) => console.log(err))
    */
  }

  return (
    <BackgroundResumo id="bg-resumo">
      {loading ? (
        <Loading />
      ) : (
        <BoxResumo>
          {vistoriaModal && (
            <Vistoria isVisible={setVistoriaModal} OPO_ID={OPO_ID} />
          )}

          {modalConfirmarPerdida && (
            <ModalConfirmarPerdida
              closeModal={setModalConfirmarPerdida}
              OPO_ID={OPO_ID}
              OPO_STATUS={'PERDIDA'}
            />
          )}

          <ModalHeader style={{ border: 'none', marginBottom: '0' }}>
            <h2>Resumo da Oportunidade</h2>
            <X
              onClick={() => {
                closeModal(false)
                setVeiculoExiste({
                  veiculoBoolean: false,
                  opoId: '',
                })
              }}
            >
              <IoMdClose size={17} />
            </X>
          </ModalHeader>

          <Tabs
            defaultActiveKey="dados_gerais"
            transition={false}
            activeKey={key}
            onSelect={(k) => setKey(k)}
            id="fill-tab-example"
            // fill
          >
            <Tab eventKey="dados_gerais" title="Dados Gerais">
              <TabContent>
                {resumo.map((i) => {
                  const valorFormatado = i.VEI_VALOR_ACORDADO
                    ? i.VEI_VALOR_ACORDADO.replace(',', '.')
                    : null
                  let VALOR = Number(valorFormatado)

                  return (
                    <div>
                      <BoxResumoGrid>
                        <ul>
                          <li>
                            <strong>Dados pessoais:</strong>
                          </li>
                          <li>Nome: {i.CLI_NOME.toUpperCase()}</li>
                          <li>
                            Telefone:{' '}
                            {i.CLI_TELEFONE
                              ? i.CLI_TELEFONE.replace(/\D/g, '')
                                  .replace(/^(\d{2})(\d)/g, '($1) $2')
                                  .replace(/(\d)(\d{4})$/, '$1-$2')
                              : ''}
                          </li>
                          <li>
                            Origem:{' '}
                            {i.ORG_DESCRICAO ? i.ORG_DESCRICAO : 'Não definido'}
                          </li>
                        </ul>

                        {i.VEI_PLACA && (
                          <ul>
                            <li>
                              <strong>Dados do veículo:</strong>
                            </li>
                            <li>
                              Placa:{' '}
                              <span style={{ textTransform: 'uppercase' }}>
                                {i.VEI_PLACA}
                              </span>
                            </li>
                            <li>Marca: {i.VEI_MARCA}</li>
                            <li>Modelo: {i.VEI_MODELO}</li>
                            <li>Ano: {i.VEI_ANO_MODELO}</li>

                            <li>
                              Valor:{' '}
                              {VALOR.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </li>
                          </ul>
                        )}
                      </BoxResumoGrid>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '.7rem',
                        }}
                      >
                        {i.IMPLEMENTOS?.length > 0 && (
                          <>
                            <strong>Implementos</strong>

                            <div className="implementos__container">
                              {i.IMPLEMENTOS?.map((el) => {
                                return (
                                  <div className="imp__item" key={el.IMP_ID}>
                                    <div className="imp__item--dados">
                                      <span id="IMP_TIPO">
                                        {el.IMP_TIPO.toUpperCase()}
                                      </span>{' '}
                                      {el.IMP_PLACA && `| ${el.IMP_PLACA}`}{' '}
                                      {el.IMP_CHASSI && `| ${el.IMP_CHASSI}`}
                                    </div>
                                    <div className="imp__item--valores">
                                      <span>
                                        <strong>Valor:</strong> R${el.IMP_VALOR}{' '}
                                        | <strong>Mensalidade:</strong> R$
                                        {el.IMP_MENSALIDADE}
                                      </span>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </>
                        )}
                      </div>

                      {PER_ID == 0 ? (
                        <div
                          style={{
                            marginTop: '30px',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                            }}
                          >
                            {!i.VEI_PLACA || !i.DESCRICAO_PLANOS_ID ? (
                              <>
                                <span
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'red',
                                    gap: '0.5rem',
                                  }}
                                >
                                  <FiAlertTriangle /> Dados da oportunidade
                                  incompletos.
                                </span>
                                <span
                                  className="button_continuar"
                                  onClick={() => {
                                    setLoading(true)
                                    continuarOportunidade(i, 'pendente')
                                  }}
                                >
                                  Concluir oportunidade.
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              gap: '0.5rem',
                            }}
                          >
                            <ButtonEncerrar
                              disabled={i.OPO_STATUS === 'PERDIDA'}
                              id="perdida"
                              onClick={() => {
                                // alterarStatusOportunidade(i.OPO_ID, "PERDIDA")
                                setModalConfirmarPerdida(true)
                              }}
                            >
                              Encerrar
                            </ButtonEncerrar>
                            {/* <ButtonEncerrar
                            disabled={
                              (i.VEI_PLACA && i.DESCRICAO_PLANOS_ID) ||
                              i.OPO_STATUS === "PERDIDA"
                            }
                            id="pendente"
                            onClick={() => continuarOportunidade(i, "pendente")}
                          >
                            Continuar cadastro
                          </ButtonEncerrar> */}
                            <ButtonEncerrar
                              disabled={
                                !i.VEI_PLACA ||
                                !i.DESCRICAO_PLAN ||
                                i.OPO_STATUS !== 'PENDENTE'
                              }
                              id="convertida"
                              onClick={() =>
                                alterarStatusOportunidade(i.OPO_ID)
                              }
                            >
                              Converter
                            </ButtonEncerrar>
                            {/* <ButtonEncerrar
                            disabled={i.OPO_STATUS === "PERDIDA" || i.OPO_STATUS === "VISTORIAS"}
                            id="vistoria"
                            onClick={() => {
                              setVeiculoExiste({
                                veiculoBoolean: true,
                                opoId: OPO_ID,
                              });
                              continuarOportunidade(i, "vistoria");
                            }}
                          >
                            Iniciar vistoria
                          </ButtonEncerrar> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  )
                })}
              </TabContent>
            </Tab>

            {planos && (
              <Tab eventKey="planos_escolhidos" title="Planos Escolhidos">
                <BoxResumoDiv>
                  <ul>
                    <li style={{ marginTop: '10px' }}>
                      <strong>Planos escolhidos:</strong>
                    </li>
                    {planos.map((i) => {
                      return (
                        <li
                          className="card_plano"
                          style={{
                            borderLeft:
                              i.PLAN_ID ==
                              resumo.map((i) => {
                                return i.PLANO_ESCOLHIDO
                              })
                                ? '4px solid green'
                                : '1px solid #ddd',
                          }}
                        >
                          {i.PLAN_ID ==
                            resumo.map((i) => {
                              return i.PLANO_ESCOLHIDO
                            }) && (
                            <span className="icon">
                              <BsCheck2 size={17} />
                            </span>
                          )}
                          {i ? i.PLAN_DESCRICAO : 'Carregando...'}
                        </li>
                      )
                    })}
                  </ul>
                </BoxResumoDiv>
              </Tab>
            )}

            {token && (
              <Tab eventKey="vistoria" title="Vistoria">
                <BoxResumoDiv>
                  <ul>
                    <li>
                      <strong>Token da Vistoria</strong>
                    </li>
                    <li>{token}</li>
                  </ul>
                </BoxResumoDiv>
              </Tab>
            )}

            <Tab eventKey="tarefa" title="Tarefas">
              <NovaTarefa OPO_ID={OPO_ID} />
            </Tab>

            <Tab eventKey="detalhes" title="Detalhes">
              {resumo.map((i) => {
                return (
                  <BoxResumoDiv>
                    <ul>
                      <li style={{ marginTop: '10px' }}>
                        <strong>Dados da oportunidade</strong>
                      </li>
                      <li>Data de criação: {i.OPO_DATA_CADASTRO}</li>
                      <li>Status: {corStatus(i.OPO_STATUS)}</li>
                      <li>Vendedor responsável: {i.VEN_NOME}</li>
                    </ul>
                  </BoxResumoDiv>
                )
              })}
            </Tab>
          </Tabs>

          {/* */}
        </BoxResumo>
      )}
    </BackgroundResumo>
  )
}

export default ResumoTable
