import styled from 'styled-components'
import iconArrowSelect from '../../../../Home/assets/icon-arrow-select.svg'

export const ListboxWrapper = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  .ListboxButton {
    width: 100%;
    font-size: 0.875rem;
    height: 2.1875rem;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 0 2rem 0 8px;
    background: white;
    background-image: url(${iconArrowSelect});
    background-position: calc(100% - 15px) center;
    background-repeat: no-repeat;
    background-size: 12px;
    outline: 0;
    transition: all 0.3s ease;
    cursor: default;
    text-align: left;

    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      border: solid 1.5px #0049c4;
    }
  }

  .ListboxOptions {
    box-sizing: border-box;
    position: absolute;
    z-index: 100;
    width: 100%;
    max-height: 90px;
    overflow-y: auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 0;
    top: 100%; /* Faz com que a lista de opções apareça abaixo do botão */
    left: 0;

    .ListboxOption {
      padding: 0.5rem 1rem;
      cursor: pointer;

      &:hover {
        background: #f0f0f0;
      }
    }
  }

  .combobox-options {
    position: absolute;
    z-index: 1;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }

  .combobox-option {
    cursor: pointer;

    &:hover {
      background: #f0f0f0;
    }
  }

  .combobox-search {
    padding: 0.5rem;
    border-bottom: 1px solid #ddd;
  }

  .combobox-search input {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0.5rem;
  }
`
