import { apiConsultaFipe, apiConsultaPlaca, apiGeral } from '../api'
import { ENDPOINTS } from '../endPoints'

export const fipeRepository = {
  consultarPlaca: async (placa) => {
    try {
      const response = await apiConsultaPlaca.get(
        `${ENDPOINTS.FIPE.CONSULTAR_PLACA}/${placa}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  consultarMarcas: async (referenciaFipe, seg) => {
    try {
      const response = await apiConsultaFipe.post(
        ENDPOINTS.FIPE.CONSULTAR_MARCAS,
        {
          codigoTabelaReferencia: referenciaFipe,
          codigoTipoVeiculo: seg,
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  consultarModelos: async (referenciaFipe, dataCarro, marcaSelecionada) => {
    try {
      const response = await apiConsultaFipe.post(
        ENDPOINTS.FIPE.CONSULTAR_MODELOS,
        {
          codigoTabelaReferencia: referenciaFipe,
          codigoTipoVeiculo: Number(dataCarro.VEI_TIPO),
          codigoMarca: marcaSelecionada,
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  consultarAnoModelo: async (referenciaFipe, dataCarro, modeloSelecionado) => {
    try {
      const response = await apiConsultaFipe.post(
        ENDPOINTS.FIPE.CONSULTAR_ANO_MODELO,
        {
          codigoTabelaReferencia: referenciaFipe,
          codigoTipoVeiculo: Number(dataCarro.VEI_TIPO),
          codigoMarca: Number(dataCarro.VEI_CODIGO_MARCA),
          codigoModelo: modeloSelecionado,
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  consultarValorTodosParametros: async (
    referenciaFipe,
    dataCarro,
    anoModeloSelecionado,
  ) => {
    try {
      const response = await apiConsultaFipe.post(
        ENDPOINTS.FIPE.CONSULTAR_VALOR_TODOS_PARAMETROS,
        {
          codigoTabelaReferencia: referenciaFipe,
          codigoTipoVeiculo: Number(dataCarro.VEI_TIPO),
          codigoMarca: dataCarro.VEI_CODIGO_MARCA,
          ano: anoModeloSelecionado[0],
          codigoTipoCombustivel: anoModeloSelecionado[1],
          anoModelo: anoModeloSelecionado[0],
          codigoModelo: dataCarro.VEI_CODIGO_MODELO,
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}
